import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, FormGroup, InputGroup, Alert, InputGroupAddon, InputGroupText, Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Badge, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Label, Spinner } from 'reactstrap';
import { MultiSelect, } from "react-multi-select-component";
import { Link, useHistory } from 'react-router-dom';
import { DateRangePicker } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck, faArrowDown, faChevronDown, faEye, faTimes,faChevronRight,faDownload,faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { ChevronLeft, ChevronRight, Copy } from 'react-feather';
import TrackOrder from "../Orders/TrackOrder";
import axios from 'axios';
import { useForm } from "react-hook-form";
import { delivery_type_id, movement_type, order_status, order_status_badge, dash_card_order_status, pending_order_status_list, pending_order_status, rto_order_status, delivered_order_status, baseString } from '../../config';
import { changeIntoDate, copyToClipBoard, removeObjData, changeIntoDateTime, moreText, errorHandling, remExtraSpace, checkAdminStatus, changeIntoDatedmY, urlencode, capitalize } from '../../utlis';
import Swal from 'sweetalert2';
import LoaderDefault from '../LoaderDefault';
import NodataFound from '../NodataFound';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import moment from 'moment';

const AllShipments = (props) => {
  let searchKeyword = props.searchKeyword;
  let ShipmentStartDate = props.ShipmentStartDate;
  let ShipmentEndDate = props.ShipmentEndDate;
  let status = props.status;
  let status_type = props.status_type;
  let zoneId = props.zoneId;
  let merchantId = props.merchantId;
  let courierId = props.courierId;
  let courierIds = props.courierIds.map((id) => (Number(id)));
  let Shipmenttotal = props.Shipmenttotal;

  const [sideAnim, setSideAnim] = useState(false);
  const [allShipment, setAllShipment] = useState({});
  const [loadertable, setLoadertable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [excelmodal, setExcelmodal] = useState(false);
  const [excelFile, setExcelFile] = useState();
  const [filterDate, setFilterDate] = useState([new Date(), new Date()]);
  const [eventDate, setEventDate] = useState([]);
  const [deliveryType, setDeliveryType] = useState([]);
  const [orderStatus, setOrderStatus] = useState([]);
  const [orderStatusCombo, setOrderStatusCombo] = useState([]);
  const [orderStatusLog, setOrderStatusLog] = useState({});
  const [orderTracking, setOrderTracking] = useState({});
  const [lastOrderStatus, setlastOrderStatus] = useState();
  const [orderLogLoader, setOrderLogLoader] = useState(false);
  const [shipmentinfo, setShipmentinfo] = useState('');
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [page, setPage] = useState(1);
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState({ "end_date": moment().format('YYYY-MM-DD'), "start_date": moment().format('YYYY-MM-DD'), });
  const [cancelmodal, setCancelmodal] = useState(false);
  const [searchValue, setSearchValue] = useState(' ');
  const [merchants, setMerchants] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [checked, setChecked] = useState(false);
  const [overallchecked, setOverallChecked] = useState(false);
  const [checkboxOrderId, setCheckboxOrderId] = useState([]);
  const [statusRecheckOrderId, setStatusRecheckOrderId] = useState([]);
  const [tempClass, setTempClass] = useState(null);
  const orderStatusObj = order_status;
  const dropFilterCount = 20;
  const history = useHistory();
  const selectAll = (value) => {
    setOverallChecked(false);
    let abc = checkData(allShipment.data) && allShipment.data.map((key) => (
      key === false
    ));
    if (value) {
      var opt_order_id = [];
      checkData(allShipment.data) && Object.keys(allShipment.data).map(function (key) {
        let tempObj = allShipment.data[key].id
        opt_order_id.push(tempObj)
        return setCheckboxOrderId(opt_order_id)
      });
      // ));
    } else {
      setCheckboxOrderId([]);
    }
    setChecked(abc);
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const utilityCheckbox in newState) {
        newState[utilityCheckbox] = value;
      }
      return newState;
    });
  };
  const selectOverAll = () => {
    setOverallChecked(true);
    // setCheckboxOrderId([]);
  }

  const toggleCheck = (utilityCheckbox, event, Id) => {
    setOverallChecked(false);
    var opt_order_id = checkboxOrderId;
    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[utilityCheckbox] = !prevState[utilityCheckbox];
      return newState;
    });
    if (event.target.checked) {
      opt_order_id.push(Id)
      setCheckboxOrderId(opt_order_id)
    } else {
      function arrayRemove(arr, value) {
        return arr.filter(function (ele) {
          return ele !== value;
        });
      }
      var result = arrayRemove(checkboxOrderId, Id);
      setCheckboxOrderId(result)
    };
  };
  const closeChangeStatus = (orderId) => {
    setChangeStatusModal(false);
    reset2()
  }
  const toggleChangeStatus = (orderId) => {
    removeObjData(orderStatusObj, [1, 3, 8, 9, 25]);
    setOrderLogLoader(true);
    setChangeStatusModal(!changeStatusModal);
    let data = { "order_id": orderId }
    // axios.post(process.env.REACT_APP_ORDER_STATUS_LOG, data)
    axios({
      method: "post",
      url: process.env.REACT_APP_ORDER_STATUS_LOG,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false)
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          setOrderStatusLog(res.data.data);
          setOrderLogLoader(false)
        }
      }
    }).catch((err) => {
      setLoader(false)
      errorHandling(err, "")
      setOrderLogLoader(false)
    });
  }
  var opt_merchants = [];
  if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)) {
    Object.keys(props.state.shipmentMerchantCourier.merchants).map(function (key) {
      let merchantName = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchantName) && props.state.shipmentMerchantCourier.merchants[key].merchantName
      let merchantId = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchant_id) && props.state.shipmentMerchantCourier.merchants[key].merchant_id
      let tempObj = {}
      if (merchantName && merchantId) {
        tempObj = { label: merchantName, value: merchantId }
        opt_merchants.push(tempObj)
      }
      return true
    });
  }
  const merchantsMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Merchant"
  }

  const selected_merchant = []
  if (selected_merchant.length > 0) {
    setMerchants(selected_merchant);
  }
  if (checkUndeNullBlank(ShipmentStartDate) && checkUndeNullBlank(ShipmentEndDate) && checkUndeNullBlank(merchantId) && checkUndeNullBlank(courierId) && checkUndeNullBlank(status) && parseInt(status) === 4) {
    if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)) {
      Object.keys(props.state.shipmentMerchantCourier.merchants).map(function (key) {
        let merchantName = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchantName) && props.state.shipmentMerchantCourier.merchants[key].merchantName
        let mId = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchant_id) && props.state.shipmentMerchantCourier.merchants[key].merchant_id
        let tempObj = {}
        if (mId === parseInt(merchantId)) {
          if (merchantName && mId) {
            tempObj = { label: merchantName, value: mId }
            selected_merchant.push(tempObj)
          }
        }
        return true
      });
    }
  }

  const OrderStatusComboMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Shipment Status"
  }
  const OrderStatusMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Shipment Sub Status"
  }

  const order_status_list = removeObjData(orderStatusObj, [1, 3]);
  if (!changeStatusModal) {
    orderStatusObj[8] = 'Order Cancelled'
    orderStatusObj[9] = 'Shipment Cancelled'
    orderStatusObj[25] = 'Order Processing'
  }


  const opt_orderStatusCombo = [
    { label: "Pending", value: "pending" },
    { label: "RTO", value: 'rto' },
    { label: "Delivered", value: 'delivery' },
  ];


  const opt_orderStatus = [];
  for (const property in order_status_list) {
    let tempObj = { label: `${order_status_list[property]}`, value: property };
    opt_orderStatus.push(tempObj);
  }

  const selected_order_status = [];
  const selected_order_status_cobmo = [];
  if (selected_order_status.length > 0) {
    setOrderStatus(selected_order_status);
  }
  if (selected_order_status_cobmo.length > 0) {
    setOrderStatusCombo(selected_order_status_cobmo);
  }
  if (checkUndeNullBlank(ShipmentStartDate) && checkUndeNullBlank(ShipmentEndDate) && checkUndeNullBlank(merchantId) && checkUndeNullBlank(courierId) && checkUndeNullBlank(status) && parseInt(status) === 4) {
    let tempObj = { label: `Pickup Pending`, value: '4' };
    let assignedObj = { label: `Assigned`, value: '2' };
    selected_order_status.push(tempObj);
    selected_order_status.push(assignedObj);
  } else if (checkUndeNullBlank(ShipmentStartDate) && checkUndeNullBlank(ShipmentEndDate) && (checkUndeNullBlank(courierId) || checkData(courierIds)) && checkUndeNullBlank(status) && parseInt(status) === 4) {
    let tempObj = { label: `Pickup Pending`, value: '4' };
    let assignedObj = { label: `Assigned`, value: '2' };
    selected_order_status.push(tempObj);
    selected_order_status.push(assignedObj);
  } else if (checkUndeNullBlank(ShipmentStartDate) && checkUndeNullBlank(ShipmentEndDate) && checkUndeNullBlank(status)) {
    for (const property in pending_order_status_list) {
      let tempObj = { label: `${pending_order_status_list[property]}`, value: property };
      selected_order_status.push(tempObj);
    }
  } else if (checkUndeNullBlank(ShipmentStartDate) && checkUndeNullBlank(ShipmentEndDate) && checkUndeNullBlank(courierId) && checkUndeNullBlank(status_type) && checkUndeNullBlank(zoneId)) {
    let tempObj = { label: capitalize(status_type, true), value: status_type.toLowerCase() };
    selected_order_status_cobmo.push(tempObj);
  } else if (checkUndeNullBlank(ShipmentStartDate) || checkUndeNullBlank(ShipmentEndDate) || checkUndeNullBlank(Shipmenttotal)) {
    for (const property in dash_card_order_status) {
      let tempObj = { label: `${dash_card_order_status[property]}`, value: property };
      selected_order_status.push(tempObj);
    }
  }
  // else{
  //   for (const property in order_status_list) {
  //     let tempObj = {label: `${order_status_list[property]}`, value: property };
  //     opt_orderStatus.push(tempObj);
  //   }
  // }

  const [allCourier, setAllCourier] = useState([]);
  const allCourierMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "All Couriers"
  }
  var opt_allCourier = [];
  if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers)) {
    Object.keys(props.state.shipmentMerchantCourier.couriers).map(function (key) {
      let tempObj = { label: props.state.shipmentMerchantCourier.couriers[key].courierName, value: props.state.shipmentMerchantCourier.couriers[key].courier_id }
      return opt_allCourier.push(tempObj)
    });
  }
  const selected_courier = []
  if (selected_courier.length > 0) {
    setAllCourier(selected_courier);
  }
  if (checkUndeNullBlank(ShipmentStartDate) && checkUndeNullBlank(ShipmentEndDate) && checkUndeNullBlank(merchantId) && checkUndeNullBlank(courierId) && checkUndeNullBlank(status) && parseInt(status) === 4) {
    if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers)) {
      Object.keys(props.state.shipmentMerchantCourier.couriers).map(function (key) {
        if (props.state.shipmentMerchantCourier.couriers[key].courier_id === parseInt(courierId)) {
          let tempObj = { label: props.state.shipmentMerchantCourier.couriers[key].courierName, value: props.state.shipmentMerchantCourier.couriers[key].courier_id }
          selected_courier.push(tempObj)
        }
        return true
      });
    }
  }


  if (checkUndeNullBlank(courierId)) {
    if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers)) {
      Object.keys(props.state.shipmentMerchantCourier.couriers).map(function (key) {
        if (props.state.shipmentMerchantCourier.couriers[key].courier_id === parseInt(courierId)) {
          let tempObj = { label: props.state.shipmentMerchantCourier.couriers[key].courierName, value: props.state.shipmentMerchantCourier.couriers[key].courier_id }
          selected_courier.push(tempObj);
        }
        return true
      });
    }
  }

  if (checkData(courierIds)) {
    if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers)) {
      Object.keys(props.state.shipmentMerchantCourier.couriers).map(function (key) {
        if (courierIds.includes(props.state.shipmentMerchantCourier.couriers[key].courier_id)) {
          let tempObj = { label: props.state.shipmentMerchantCourier.couriers[key].courierName, value: props.state.shipmentMerchantCourier.couriers[key].courier_id }
          selected_courier.push(tempObj);
        }
        return true
      });
    }
  }

  let dateRangeRef = dateRange => dateRangeRef = dateRange;

  const closeTracker = (data) => {
    setSideAnim(!sideAnim)
    setTempClass(null);
    setOrderTracking({});
  }

  useEffect(() => {
    allShipmentListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword, ShipmentStartDate, ShipmentEndDate, props.state])

  //All Shipment Listing
  const allShipmentListing = () => {
    setOrderStatus([]); setOrderStatusCombo([]); setAllCourier([]); setMerchants([]);
    let data = { "all_shipment": 1, "start_date": moment().format('YYYY-MM-DD'), "end_date": moment().format('YYYY-MM-DD') }
    if (checkUndeNullBlank(ShipmentStartDate) || checkUndeNullBlank(ShipmentEndDate)) {
      let tmpOrderStatus = [];
      let tmpOrderStatusCombo = [];
      selected_order_status.map((item) => (
        tmpOrderStatus.push(parseInt(item.value))
      ));
      selected_order_status_cobmo.map((item) => {
        if (item.value === "pending") {
          pending_order_status.forEach((odrId) => {
            tmpOrderStatusCombo.push(parseInt(odrId))
          })
        } else if (item.value === "rto") {
          rto_order_status.forEach((odrId) => {
            tmpOrderStatusCombo.push(parseInt(odrId))
          })
        } else if (item.value === "delivery") {
          delivered_order_status.forEach((odrId) => {
            tmpOrderStatusCombo.push(parseInt(odrId))
          })
        }
        return true;
      });
      let tmpCourierId = [];
      selected_courier.map((item) => (
        tmpCourierId.push(parseInt(item.value))
      ));
      if (checkUndeNullBlank(Shipmenttotal)) {
        setFilterDate([]);
      } else {
        setFilterDate([new Date(ShipmentStartDate), new Date(ShipmentEndDate)]);
      }
      setOrderStatus(selected_order_status)
      setOrderStatusCombo(selected_order_status_cobmo)
      setAllCourier(selected_courier);
      setMerchants(selected_merchant);
      data.start_date = checkUndeNullBlank(ShipmentStartDate) ? ShipmentStartDate : '';
      data.end_date = checkUndeNullBlank(ShipmentEndDate) ? ShipmentEndDate : '';
      data.order_status_ids = tmpOrderStatus;
      data.status_ids = tmpOrderStatusCombo;
      data.zone_category_id = checkUndeNullBlank(zoneId) ? parseInt(zoneId) : '';
      data.courier_id = checkUndeNullBlank(courierId) ? [parseInt(courierId)] : [];
    } else {
      setFilterDate([new Date(), new Date()]);
    }
    setEventDate([]); setStatusRecheckOrderId([]); setOverallChecked(false); setCheckboxOrderId([]); setCheckedAll(false); setChecked(false); setCurrentCount(1); setPage(1);
    if (checkUndeNullBlank(searchKeyword)) {
      setEventDate([]);
      setFilterDate([]);
      data.start_date = data.end_date = ''
      data.search_keyword = searchKeyword;
    }
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
      if (checkUndeNullBlank(merchantId)) {
        data.merchant_ids = [parseInt(merchantId)]
      }
    } else {
      data.merchant_id = props.user[0].id
    }
    if (searchKeyword === null || searchKeyword === undefined) {
      reset();
    }
    setLoadertable(true);
    setDeliveryType([]); setSearchValue(' '); setFilterInput({ "end_date": moment().format('YYYY-MM-DD'), "start_date": moment().format('YYYY-MM-DD'), })

    axios({
      method: "post",
      url: `${process.env.REACT_APP_ALL_SHIPMENTS}?page=${page}&per_page=${selectperpage}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoadertable(false)
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          setAllShipment(res.data);
        }
      }
    }).catch((err) => {
      setLoadertable(false)
      errorHandling(err, "reload")
    });
  }

  // filter Bulk Order
  const { register: changeStatus, handleSubmit: changeStatusSubmit, reset: reset2 } = useForm();
  const onChangeStatus = data => {
    data.awb_no = shipmentinfo.awb_no
    data.courier_id = shipmentinfo.courier_id
    data.old_order_status_id = shipmentinfo.old_order_status
    data.order_id = shipmentinfo.order_id
    data.merchant_id = shipmentinfo.merchant_id
    data.user_name = checkData(props.user) && props.user[0].name
    data.admin_id = checkData(props.user) && props.user[0].id
    // axios.post(`${process.env.REACT_APP_CHANGE_ORDER_STATUS}`,data)
    axios({
      method: "post",
      url: process.env.REACT_APP_CHANGE_ORDER_STATUS,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            Swal.fire({ icon: 'success', text: res.data.msg, title: 'Success' });
            allShipmentListing();
            setChangeStatusModal(false);
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "")
      setLoader(false);
    });
  }
  const orderTrack = (orderId, lastStatus) => {
    setlastOrderStatus(lastStatus)
    setStatusRecheckOrderId(orderId)
    let data = { "order_id": orderId }
    // axios.post(`${process.env.REACT_APP_TRACK_ORDER}`,data)
    axios({
      method: "post",
      url: process.env.REACT_APP_TRACK_ORDER,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setOrderTracking(res.data.data)
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "")
      setLoader(false);
    });
  }

  const exportShipment = () => {
    if (checkData(filterInput) || checkUndeNullBlank(searchKeyword)) {
      let data = {}
      if (checkUndeNullBlank(searchKeyword)) {
        data.search_value = searchKeyword
      } else {
        data = filterInput
      }
      if (props.user[0].user_type === "Super") {
        data.admin_id = props.user[0].id
      } else {
        data.merchant_id = props.user[0].id
      }
      data.is_export = 1
      setLoader(true);
      // axios.post(process.env.REACT_APP_ALL_SHIPMENTS, data)
      axios({
        method: "post",
        url: process.env.REACT_APP_ALL_SHIPMENTS,
        data: data,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
        if (res.data !== undefined && res.data.fatal !== true && res.data !== true) {
          if (parseInt(res.data.errno) > 0) {
            setLoader(false)
            if (res.data.sqlMessage !== undefined) {
              Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
            }
          } else {
            if (res.data.is_export === 1) {
              setLoader(false);
              props.downloadNotification('Export Shipments Started...', '/my-downloads');
              // setTimeout(function(){ 
              //   history.push("/my-downloads"); 
              // },download_delay);
            }
            // setAllShipment(res.data);
          }
        }
      }).catch((err) => {
        setLoader(false)
        errorHandling(err, "")
      });
    } else {
      Swal.fire({ icon: 'error', title: 'Error', text: 'Please Choose Filter' })
    }
  }

  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoadertable(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  function nextPageData() {
    if (checkData(allShipment.data) && checkUndeNullBlank(allShipment.totalPage)) {
      // &&checkUndeNullBlank(allShipment.totalPage).length>page.length
      if (allShipment.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(allShipment.data).length)
        setPage(page + 1)
        setLoadertable(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(allShipment.data)
      if (currentCount > Object.entries(allShipment.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }
  const { register: filter, handleSubmit: filterAllShipmentSubmit, watch: filterWatch, reset, } = useForm();

  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };
  const onFilterAllShipment = (data, pagePerpage) => {
    // console.log('data', data)
    pagePerpage.page = 1
    // if((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value))  || checkData(orderStatus)|| checkData(data.movement_type_id) || checkData(allCourier) || checkData(merchants) || checkData(data.delivery_type_id) || (checkData(filterDate.length) && filterDate.length > 0) || (checkData(eventDate.length) && eventDate.length > 0)){
    if ((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value)) || checkData(orderStatus) || checkData(orderStatusCombo) || checkData(data.movement_type_id) || checkData(allCourier) || checkData(merchants) || checkData(data.delivery_type_id) || checkData(filterDate) || checkData(eventDate)) {
      if (checkUndeNullBlank(searchKeyword)) {
        history.push(`/shipments?search_keyword=${urlencode(data.search_value)}`)
      }
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      Swal.fire({ title: 'info', text: 'Please Choose Filter', icon: 'info', })
    }
  };
  const resetFilter = () => {
    allShipmentListing();
    if (checkData(searchKeyword)) {
      history.push(`/shipments?s=all`)
    }
  }
  const onCommonFilter = (data, pagePerpage) => {
    setStatusRecheckOrderId([]);
    setOverallChecked(false);
    setCheckedAll(false);
    setChecked(false);
    setCheckboxOrderId([]);
    let byData = {}
    let tmpDeliveryType = []
    let tmpOrderStatus = [];
    let tmpOrderStatusCombo = [];
    let courierId = [];
    let merchantsId = [];
    if (checkData(allCourier)) {
      allCourier.map((item) => (
        courierId.push(item.value)
      ));
    }
    if (checkData(merchants)) {
      merchants.map((item) => (
        merchantsId.push(item.value)
      ));
    }
    deliveryType.map((item) => (
      tmpDeliveryType.push(item.value)
    ));

    orderStatus.map((item) => (
      tmpOrderStatus.push(parseInt(item.value))
    ));

    orderStatusCombo.map((item) => {
      if (item.value === "pending") {
        pending_order_status.forEach((odrId) => {
          tmpOrderStatusCombo.push(parseInt(odrId))
        })
      } else if (item.value === "rto") {
        rto_order_status.forEach((odrId) => {
          tmpOrderStatusCombo.push(parseInt(odrId))
        })
      } else if (item.value === "delivery") {
        delivered_order_status.forEach((odrId) => {
          tmpOrderStatusCombo.push(parseInt(odrId))
        })
      }
      return true;
    });

    if (checkUndeNullBlank(courierId)) {
      byData.courier_id = courierId
    }
    setLoader(true)
    if (data === undefined) {
      data = filterInput
    }

    let searchVal = checkUndeNullBlank(data.search_value) ? remExtraSpace(data.search_value) : '';
    let orderSid = checkUndeNullBlank(tmpOrderStatus) ? tmpOrderStatus : '';
    let orderSComboid = checkUndeNullBlank(tmpOrderStatusCombo) ? tmpOrderStatusCombo : '';
    let cId = checkUndeNullBlank(courierId) ? courierId : '';
    let mId = checkUndeNullBlank(merchantsId) ? merchantsId : '';
    let deliveryTid = checkUndeNullBlank(data.delivery_type_id) ? data.delivery_type_id : '';
    let movementTid = checkUndeNullBlank(data.movement_type_id) ? data.movement_type_id : '';
    let sDate = checkData(filterDate) ? moment(filterDate[0]).format('YYYY-MM-DD') : '';
    let eDate = checkData(filterDate) ? moment(filterDate[1]).format('YYYY-MM-DD') : '';
    let esDate = checkData(eventDate) ? moment(eventDate[0]).format('YYYY-MM-DD') : '';
    let eeDate = checkData(eventDate) ? moment(eventDate[1]).format('YYYY-MM-DD') : '';
    let pageNumber = checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page
    let perPageCount = checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage

    byData = { "all_shipment": 1, "search_field": checkUndeNullBlank(data.search_field) ? data.search_field : '', "search_value": searchVal, "order_status_ids": orderSid, "status_ids": orderSComboid, "courier_id": cId, "merchant_ids": mId, "delivery_type_id": deliveryTid, "movement_type_id": movementTid, "start_date": sDate, "end_date": eDate, "event_start_date": esDate, "event_end_date": eeDate }
    if (checkData(searchKeyword)) {
      byData.search_field = 'awb_number';
      byData.search_value = searchKeyword;
    }
    setFilterInput(byData);
    if (props.user[0].user_type === "Super") {
      byData.admin_id = props.user[0].id
    } else {
      byData.merchant_id = props.user[0].id
    }

    axios({
      method: "post",
      url: `${process.env.REACT_APP_ALL_SHIPMENTS}?page=${pageNumber}&per_page=${perPageCount}`,
      data: byData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setAllShipment(res.data);
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "")
      setLoader(false);
    });
  };

  const searchByChange = (e) => {
    setEventDate([]); setFilterDate([]);
    // if(checkUndeNullBlank(searchKeyword)){
    //   history.push(`/shipments?search_keyword=${urlencode(e.target.value)}`)
    // }
  }
  const dateRangeChange = (date) => {
    setFilterDate(date);
    let sDate = checkData(date) ? moment(date[0]).format('YYYY-MM-DD') : '';
    let eDate = checkData(date) ? moment(date[1]).format('YYYY-MM-DD') : '';
    if (checkData(date)) {
      if (checkUndeNullBlank(ShipmentStartDate) && checkUndeNullBlank(ShipmentEndDate) && checkUndeNullBlank(status)) {
        history.push(`/shipments?start_date=${changeIntoDatedmY(sDate)}&end_date=${changeIntoDatedmY(eDate)}&status=shipments`)
      } else if (checkUndeNullBlank(ShipmentStartDate) || checkUndeNullBlank(ShipmentEndDate) || checkUndeNullBlank(Shipmenttotal)) {
        history.push(`/shipments?start_date=${changeIntoDatedmY(eDate)}&end_date=${changeIntoDatedmY(eDate)}`)
      }
    }
  }

  // cancel shipment
  const cancelmodalToggleClose = () => {
    setCancelmodal(false);
    setOverallChecked(false); setCheckboxOrderId([]); setCheckedAll(false); setChecked(false);
  }

  //Update bulk order status
  const { register: uploadOrderStatus, handleSubmit: uploadOrderStatusSubmit, formState: { errors } } = useForm();

  const excelUpload = () => {
    setExcelmodal(!excelmodal);
    if (!excelmodal) { setExcelFile(""); }
  }
  const handleFileInput = (e) => {
    setExcelFile(e.target.files[0]);
  }

  const onStatusUpdateSubmit = (data) => {
    setLoader(true);
    let formData = new FormData();
    formData.append("file", excelFile);
    formData.append("admin_id", checkData(props.user) ? props.user[0].id : '');

    axios({
      method: "post",
      url: `${process.env.REACT_APP_STATUS_UPLOAD}`,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      let errorFile = '';
      if (res.data.errorfile !== undefined) {
        errorFile = checkUndeNullBlank(res.data.errorfile) ? process.env.REACT_APP_BASE_URL + res.data.errorfile : '';
      }
      setExcelFile();
      setExcelmodal(false);
      if (res.data.error === false) {
        Swal.fire({
          title: 'Success',
          html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td>${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' ? `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>` : ''}</tbody></Table>`,
          text: res.data.msg,
          icon: 'success',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(okay => {
          if (okay) {
            //updateLostListing();
          }
        });
      } else {
        Swal.fire({
          title: 'Error',
          text: res.data.msg,
          html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td style="padding: 0.25rem 0;">${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' && `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`}</tbody></Table>`,
          icon: 'error',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(okay => {
          if (okay) {
           // updateLostListing();
          }
        });
      }
    })
      .catch((err) => {
        setLoader(false);
        errorHandling(err, "")
      });
  }

  const cancelmodalToggle = () => {
    if (checkboxOrderId.length > 0) {
      setCancelmodal(!cancelmodal);
    } else {
      Swal.fire({ icon: 'warning', title: 'Warning', text: 'Please Select The Order First' })
    }
  }
  const cancelmodalToggleMerchant = (id) => {
        setCheckboxOrderId([id]);
      setCancelmodal(!cancelmodal);
    }
  
  const onStatusRecheck = () => {
    let data = {}
    if (checkboxOrderId.length > 0) {
      setLoader(true);
      if (checkboxOrderId.length > 0) {
        data.order_ids = checkboxOrderId
      }
      // axios.post(process.env.REACT_APP_STATUS_RECHECK, data)
      axios({
        method: "post",
        url: process.env.REACT_APP_STATUS_RECHECK,
        data: data,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
        setLoader(false)
        if (res.data.sqlMessage !== undefined) {
          Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
        }
        if (res.data.error === false) {
          Swal.fire({ icon: 'success', title: 'Success', text: res.data.msg }).then(okay => {
            if (okay) {
              setCancelmodal(false);
              resetCancelled();
              setCheckboxOrderId([]);
              setCheckedAll(false);
              setChecked(false);
              if (checkData(searchKeyword)) {
                allShipmentListing();
              } else {
                onCommonFilter();
              }
            }
          });
        } else {
          setLoader(false)
          Swal.fire({ icon: 'error', title: 'Error', text: res.data.msg })
        }
      }).catch((err) => {
        setLoader(false)
        errorHandling(err, "")
      });
    } else {
      Swal.fire({ icon: 'warning', title: 'Warning', text: 'Please Select The Order First' })
    }
  }
  console.log('checkboxOrderId', checkboxOrderId)
  const { register: cancelOrder, handleSubmit: cancelShipmentSubmit, formState: { errors: errors4 }, reset: resetCancelled } = useForm();
  const onCancelShipmentSubmit = data => {
    // console.log('data', data)
    setLoader(true);
    data.order_ids = checkboxOrderId
    // axios.post(process.env.REACT_APP_CANCEL_SHIPMENT, data)
    axios({
      method: "post",
      url: process.env.REACT_APP_CANCEL_SHIPMENT,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false)
      if (res.data.sqlMessage !== undefined) {
        Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
      }
      if (res.data.error === false) {
        Swal.fire({ icon: 'success', title: 'Success', text: res.data.msg })
        setCancelmodal(false);
        resetCancelled();
        setCheckboxOrderId([]);
        setCheckedAll(false);
        setChecked(false);
        if (checkData(searchKeyword)) {
          allShipmentListing();
        } else {
          onCommonFilter();
        }
      } else {
        setLoader(false)
        Swal.fire({ icon: 'error', title: 'Error', text: res.data.msg })
      }
    }).catch((err) => {
      setLoader(false)
      errorHandling(err, "")
    });
  }

  // copy text
  const copied = (text) => {
    let decision = copyToClipBoard(text);
    decision.then(function (result) {
      if (result === true) {
        toast("Copied!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
  }

  return (
    // <div className="app-content content overflow-hidden">
    <div>
      {loader && <div className="formLoader"><Spinner /></div>}
      {checkData(orderTracking) && <div className="overlay"></div>}
      <Row>
        <Col sm={12}>
          <Card className="p-0 allShipment shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex flex-wrap justify-content-between">
                <CardTitle className="page-title">All Shipments</CardTitle>
                <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                  {checkData(allShipment.data) &&
                    <>
                      <div className="btn-text btn-light" onClick={exportShipment}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Download</span></div>
                      {props.user[0].user_type === "Super" &&
                        <>
                          <div className="btn-text btn-light mx-2" onClick={cancelmodalToggle}><span className="icon mr-2"><FontAwesomeIcon icon={faTimes} /></span><span>Cancel Shipment</span></div>
                          <div className="btn-text btn-light mx-2" onClick={onStatusRecheck}><span className="icon mr-2"><FontAwesomeIcon icon={faEye} /></span><span>Status Recheck</span></div>
                          <div className="btn-text btn-light mx-2" onClick={excelUpload}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowUp} /></span><span>Upload Bulk Status</span></div>
             
                        </>}
                    </>}
                </div>
              </CardHeader>
              <Modal isOpen={cancelmodal} toggle={cancelmodal} scrollable={true} >
                <ModalHeader toggle={cancelmodalToggleClose}>Cancel Shipment</ModalHeader>
                <ModalBody>
                  <Form onSubmit={cancelShipmentSubmit(onCancelShipmentSubmit)}>
                    <Row className="m-0 pb-1">
                      <Col sm={12}>
                        <FormGroup>
                          <Label htmlFor="remaks">Remarks</Label>
                          <textarea {...cancelOrder("remarks", { required: 'Please Enter Remaks' })} rows="4" className="form-control-sm form-control" placeholder="Remarks" id="remarks" />
                          {errors4.remarks && <span className="text-danger d-block error">{errors4.remarks.message}</span>}
                        </FormGroup>
                      </Col>
                      <Col sm={12} className="text-right">
                        <Button className="btn ctm-btn btn-sm" color="primary">Submit</Button>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>

              <Modal isOpen={excelmodal} toggle={excelmodal} scrollable={true} >
                  <ModalHeader toggle={excelUpload}>Upload Bulk Status</ModalHeader>
                  <ModalBody>
                    <Form onSubmit={uploadOrderStatusSubmit(onStatusUpdateSubmit)}>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Upload all Your Update status via XLSX</p>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Please download below given template</p>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Remove sample data and upload your data</p>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Make sure you atleast fill mandatory fields.</p>

                      <Row className="m-0 pb-1">
                        <Col sm={12} className="p-0">
                          <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span> <Link target="_blank" download to="/format/update_order_status.xlsx" className=""><FontAwesomeIcon icon={faDownload} />  Download
                          </Link>
                            <span className="ml-2">Upload Bulk Order status Change Format File</span></p>
                        </Col>
                        <Col sm={12} className="m-auto pt-1 p-0">
                          <FormGroup>
                            <input {...uploadOrderStatus("excelUpload", {
                              required: 'Please Upload XLSX File',
                              validate: {
                                lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                                acceptedFormats: files =>
                                  ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                    files[0]?.type
                                  ) || 'Only .xslx file allowed',
                              },
                            })} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input" onChange={handleFileInput} />
                            <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name : 'Choose file'}</Label>
                            {errors.excelUpload && <span className="text-danger d-block error">{errors.excelUpload.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12} className="text-right">
                          <Button className="btn ctm-btn btn-sm" color="primary">Upload</Button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>

              <Form onSubmit={filterAllShipmentSubmit(onFilterAllShipment)} className="cardFilter">
                <Row className="mx-0 mb-3 mb-xl-0 justify-content-start justify-content-lg-center">
                  <Col sm={6} md={5} lg={5} xl={4}>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="position-relative p-0">
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select {...filter("search_field")} className="form-control-sm form-control" onChange={(e) => { if (e.target.selectedOptions[0].text !== "Search By") { setSearchValue("Enter " + e.target.selectedOptions[0].text); } else { setSearchValue('') } setEventDate([]); setFilterDate([]) }}>
                              <option value="Search By">Search By</option>
                              <option value="awb_number">AWB Number</option>
                              <option value="customer_name">Customer Name</option>
                              <option value="customer_mobile">Customer Mobile</option>
                              <option value="order_no">Order No</option>
                              <option value="system_order_no">System Order No</option>
                              <option value="product_name">Product Name</option>
                            </select>
                          </InputGroupText>
                        </InputGroupAddon>
                        <input {...filter("search_value")} className="form-control-sm form-control" placeholder={searchValue} 
                        // onChange={(e) => { searchByChange(e) }} 
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>

                  {props.user[0].user_type === "Super" &&
                    <Col sm={6} md={3} lg={3} xl={2}>
                      <FormGroup>
                        <MultiSelect
                          options={opt_merchants}
                          value={merchants}
                          onChange={(e) => { setMerchants(e); }}
                          labelledBy="Select Merchant"
                          overrideStrings={merchantsMultiSelectSettings}
                          className={checkData(merchants) && "active"}
                        />
                      </FormGroup>
                    </Col>}

                  <Col sm={6} md={3} lg={3} xl={2}>
                    <FormGroup>
                      <div className="position-relative">
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <MultiSelect
                          options={opt_orderStatus}
                          value={orderStatus}
                          onChange={(e) => { setOrderStatus(e); }}
                          labelledBy="Shipment Sub Status"
                          overrideStrings={OrderStatusMultiSelectSettings}
                          className={checkData(orderStatus) && "active"}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={2}>
                    <FormGroup>
                      <div className="position-relative">
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <MultiSelect
                          options={opt_orderStatusCombo}
                          value={orderStatusCombo}
                          onChange={(e) => { setOrderStatusCombo(e); }}
                          labelledBy="Shipment Status"
                          overrideStrings={OrderStatusComboMultiSelectSettings}
                          className={checkData(orderStatusCombo) && "active"}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <div className="position-relative">
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <MultiSelect
                          options={opt_allCourier}
                          value={allCourier}
                          onChange={(e) => { setAllCourier(e); }}
                          labelledBy="All Couriers"
                          overrideStrings={allCourierMultiSelectSettings}
                          className={checkData(allCourier) && "active"}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                      <select {...filter("delivery_type_id")} className={`form-control-sm form-control ${checkData(filterWatch("delivery_type_id")) ? 'valid' : ''}`}>
                        <option value="">Delivery Type</option>
                        {Object.entries(delivery_type_id).map(([key, value]) => (
                          <option value={key}>{value}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <DateRangePicker value={filterDate} onChange={date => { dateRangeChange(date) }} placeholder="Choose Upload Date" showOneCalendar={props.oneCalendar} format="dd-MM-yyyy" ref={dateRangeRef} />
                    </FormGroup>
                  </Col>
                  {checkData(props.user) && checkUndeNullBlank(props.user[0].user_type) && props.user[0].user_type === 'Super' &&
                    <Col sm={6} md={4} lg={3} xl={3}>
                      <FormGroup>
                        <DateRangePicker value={eventDate} onChange={date => { setEventDate(date); }} placeholder="Choose Event Date" showOneCalendar={props.oneCalendar} format="dd-MM-yyyy" ref={dateRangeRef} />
                      </FormGroup>
                    </Col>
                  }
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                      <select {...filter("movement_type_id")} className={`form-control-sm form-control ${checkData(filterWatch("movement_type_id")) ? 'valid' : ''}`}>
                        <option value="">Movement Type</option>
                        {Object.entries(movement_type).map(([key, value]) => (
                          <option value={key}>{value}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col sm={3} className="btn-container">
                    <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" outline onClick={resetFilter}>Reset</Button>
                  </Col>
                </Row>
              </Form>
              <CardBody className="p-0">
                <div className="showing-count">
                  {checkData(allShipment) && checkData(allShipment.data) && checkUndeNullBlank(allShipment.TotalRows) ?
                    <span className="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(allShipment.data).length)} of {allShipment.TotalRows}</span> : ''}
                </div>
                {checkboxOrderId.length > 0 && !overallchecked &&
                  <Alert color="secondary" className='small total-select' fade={false}>
                    <span className='mx-1'>Total {checkboxOrderId.length} items on this page are selected.</span>{checkData(allShipment.data) && checkUndeNullBlank(allShipment.TotalRows) && allShipment.TotalRows > selectperpage && checkedAll && <span onClick={selectOverAll} className='cursor-pointer text-primary d-none'>Select all <span className='font-weight-bold'>{checkData(allShipment.data) && checkUndeNullBlank(allShipment.TotalRows) && allShipment.TotalRows}</span> items across all pages</span>}
                  </Alert>
                }
                {overallchecked &&
                  <Alert color="secondary" className='small total-select' fade={false}>
                    <span className='mx-1'>Total {checkData(allShipment.data) && checkUndeNullBlank(allShipment.TotalRows) && allShipment.TotalRows} items are Selected.</span>
                  </Alert>
                }
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th><div className="form-check2"><input name="cust-type form-check-input" type="checkbox" id="select_all" checked={checkedAll} onChange={(event) => selectAll(event.target.checked)} value="checkedall" /><label className="form-check-label" htmlFor="select_all"></label></div></th>
                      {props.user[0].user_type === "Super" &&
                        <th className="text-nowrap">Merchant Name</th>
                      }
                      <th className="text-nowrap">Pickup Point</th>
                      <th className="text-nowrap">Order Details</th>
                      <th className="text-nowrap">Courier Details</th>
                      <th className="text-nowrap">Customer Details</th>
                      <th className="text-nowrap"><div className="w-product">Product</div></th>
                      <th className="text-nowrap">Uploaded</th>
                      {props.user[0].user_type === "Super" ?
                        <th className="text-nowrap">Updated</th> :
                        <th className="text-nowrap">Action</th>

                      }
                    </tr>
                  </thead>
                  <tbody>
                    {checkData(allShipment.data) ? allShipment.data.map((item, index) => (
                      <tr key={index} className={`${tempClass === item.id && "showTop"}`}>
                        <td><div className="form-check2"><input checked={checked !== undefined && checked[index]} name={'check' + index} type="checkbox" id={item.id} onChange={(event) => toggleCheck(index, event, item.id)} disabled={overallchecked} /><label className="form-check-label" htmlFor={item.id}></label></div></td>
                        {props.user[0].user_type === "Super" &&
                          <td className="align-middle">{item.merchantName}<br /> {item.merchantBrandName}</td>
                        }
                        <td className="align-middle">{item.MerchantPickupPointName}</td>
                        <td className="align-middle">
                          <div className="text-nowrap"><Link target="_blank" to={`/order-detail/${item.id}`}>{item.order_no}</Link>
                            <span onClick={() => copied(item.order_no)} className="ml-1 copy-btn" id={`copyOdr${item.id}`}>
                              <Copy />
                              <UncontrolledTooltip placement="bottom" target={`copyOdr${item.id}`}>
                                Copy
                              </UncontrolledTooltip>
                            </span>
                          </div>
                          {delivery_type_id[item.delivery_type_id]}<br />{item.product_cod_value}
                          </td>
                        <td className="align-middle text-left col-2">
                          <div className="list-group-item border-0 p-0 d-inline-flex">
                            <div className="list-icon">
                              <div className="avatar">
                                {item.logo !== null && item.logo !== undefined ?
                                  <img className="img-fluid" src={process.env.REACT_APP_IMAGE_COURIER + item.logo} alt="logo" width="60" />
                                  : <span className="no-img d-flex align-items-center justify-content-center rounded-circle border font-weight-500">M</span>}
                              </div>
                            </div>
                            <div className="list-content text-left">
                              <div className="">
                                <p className="mb-0">{(item.courierName)}</p>
                              </div>
                              <div className="d-flex align-items-center mb-1"><span className="d-block text-primary text-left text-nowrap" onClick={() => { setSideAnim(!sideAnim); orderTrack(item.id, item.order_status_id); setStatusRecheckOrderId([item.id]); setTempClass(item.id) }} style={{ cursor: 'pointer' }}>AWB - {item.awb_no}</span>
                                <span onClick={() => copied(item.awb_no)} className="ml-1 mr-1 copy-btn" id={`copy${item.id}`}>
                                  <Copy />
                                  <UncontrolledTooltip placement="bottom" target={`copy${item.id}`}>
                                    Copy
                                  </UncontrolledTooltip>
                                </span>
                                <a href={`https://www.logistify.in/tracking.php?search_type=awb_no&search_value=${item.awb_no}`} target="_blank" rel="noopener noreferrer">
                                  <FontAwesomeIcon icon={faTruck} />
                                </a>
                              </div>
                              
                              {props.user[0].user_type === "Super" && item.walkin_customer_id === -1 ? 
                                 <span  className='timeline-icon-danger customDot border border-danger'></span>:''}
                              <Badge color={order_status_badge[item.order_status_id]} pill>
                              {checkAdminStatus(props.user[0].user_type, item.order_status_id, item.is_lost)}
                              </Badge>
                              
                            </div>
                          </div>

                        </td>
                        <td className="align-middle">{item.customer_name}<br /> {item.customer_mobile}</td>
                        <td className="align-middle"> <div className="w-product" id={`addressTooltip${item.id}`}>{moreText(item.product_name)}</div>
                          <UncontrolledTooltip placement="top" target={`addressTooltip${item.id}`}>
                            {item.product_name}
                          </UncontrolledTooltip>
                        </td>
                        <td className="align-middle text-nowrap">{changeIntoDateTime(item.created_at)}</td>
                        {props.user[0].user_type === "Super" ?
                          <td className="align-middle">
                            <>
                              {changeIntoDateTime(item.updated_at)}
                              <br /> <Button className="btn btn-sm text-nowrap" color="primary" outline onClick={() => { toggleChangeStatus(item.id); setShipmentinfo({ "order_id": item.id, "old_order_status": item.order_status_id, "courier_id": item.courier_id, "awb_no": item.awb_no, "merchant_id": item.merchant_id }) }}> Change Status</Button>
                            </>
                          </td> :
                          <td>
                            {parseInt(item.order_status_id) === 2 || parseInt(item.order_status_id) === 4 ? <span><Button outline className="ctm-btn btn-sm ml-2 btn-edit" color="danger" onClick={() => cancelmodalToggleMerchant(item.id)}>Cancel</Button></span> : '-----'}
                          </td>
                        }
                      </tr>
                    )) :
                      loadertable ? <tr><td colSpan="9" className="text-center"><LoaderDefault /></td></tr>
                        : <tr><td colSpan="9" className="text-center"><NodataFound /></td></tr>}
                  </tbody>
                </Table>

                {props.user[0].user_type === "Super" &&
                  <Modal isOpen={changeStatusModal} toggle={changeStatusModal} scrollable={true} size="lg">
                    <ModalHeader toggle={closeChangeStatus}>Change Status</ModalHeader>
                    <ModalBody>
                      <Form onSubmit={changeStatusSubmit(onChangeStatus)} className="cardFilter">
                        <Row className="mx-0 pt-1 mb-3 mb-xl-0 justify-content-start justify-content-lg-center">
                          <Col sm={12}>
                            <FormGroup>
                              <Label>Select Status</Label>
                              <div className="position-relative">
                                <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                <select {...changeStatus("new_order_status_id")} className="form-control-sm form-control">
                                  {Object.entries(orderStatusObj).map(([key, value]) => (
                                    <option value={key}>{value}</option>
                                  ))}
                                </select>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col sm={12}>
                            <FormGroup>
                              <Label>Remarks</Label>
                              <textarea {...changeStatus("remarks")} className="form-control-sm form-control" placeholder="Enter Remarks" />
                            </FormGroup>
                          </Col>
                          <Col sm={12}>
                            <Table responsive className="text-center custom-table">
                              <thead>
                                <tr>
                                  <th>User</th>
                                  <th>Old Status</th>
                                  <th>New Status</th>
                                  <th>Remarks</th>
                                  <th>Date-Time</th>
                                </tr>
                              </thead>
                              <tbody>
                                {checkData(orderStatusLog) ? orderStatusLog.map((item, index) => (
                                  <tr>
                                    <td>{item.submit_by}</td>
                                    <td>{order_status[item.old_order_status_id]}</td>
                                    <td>{order_status[item.new_order_status_id]}</td>
                                    <td>{item.remarks}</td>
                                    <td>{changeIntoDate(item.created_at)}</td>
                                  </tr>
                                )) : orderLogLoader ? <tr><td colSpan="5"><LoaderDefault /></td></tr> : <tr><td colSpan="5"><NodataFound /></td></tr>}
                              </tbody>
                            </Table>
                          </Col>
                          <Col sm={12} className="text-right">
                            <Button className="btn ctm-btn btn-sm" color="primary">Save</Button>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                }
                {checkData(orderTracking) && <TrackOrder orderTracking={orderTracking} toggleTrack={sideAnim ? "show" : "hide"} closeTracker={(e) => { closeTracker(e) }} position="fixed" lastStatus={lastOrderStatus} statusRecheckOrderId={statusRecheckOrderId} user={props.user} />}

                {checkData(allShipment) && checkData(allShipment.data) && checkUndeNullBlank(allShipment.TotalRows) ?
                  <>
                    {allShipment.TotalRows > dropFilterCount ?
                      <Row className="m-0 table-footer">
                        <Col xs="6">
                          <Pagination aria-label="Page navigation example align-items-center">
                            <PaginationItem className="prev">
                              <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                            </PaginationItem>
                            <PaginationItem className="next ml-3">
                              <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </Col>
                        <Col xs="6">
                          <Form>
                            <FormGroup>
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="250">250</option>
                              </select>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row> : ''}
                  </>
                  : ''}
              </CardBody>
            </Card>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}
export default connect(mapDispatchToProps)(AllShipments);