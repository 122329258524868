import React, { useState, useEffect } from 'react';
import { Card, CardBody, Button, FormGroup, Table, Form, Label, Col, Spinner } from 'reactstrap';
import { MultiSelect, } from "react-multi-select-component";
import { DateRangePicker } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faDownload, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Swal from 'sweetalert2';
import { delivered_order_status, performance_overview_pending_order_status, rto_order_status, failed_order_status, download_delay, baseString } from '../../../../config';
import { arrayValueSum, changeIntoDate, changeIntoDateYmd, errorHandling } from '../../../../utlis';
import { checkData, checkUndeNullBlank } from '../../../../ObjectExist';
import NodataFound from '../../../NodataFound';
import LoaderDefault from '../../../LoaderDefault';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux'
import { performanceOverviewM, merchantBrandData,merchantPickupPointData } from '../../../../actions';

const PerformanceOverview = (props) => {
    const [allCourier, setAllCourier] = useState([]);
    const [performanceOverviews, setPerformanceOverviews] = useState({});
    const [merchantBrand, setMerchantBrand] = useState({});
    const [merchantPickupPoint, setMerchantPickupPoint] = useState({});
    const [loader, setLoader] = useState(false);
    const [loadertable, setLoadertable] = useState(false);
    const [filterDatePerf, setFilterDatePerf] = useState([new Date(moment().subtract(31, 'days')), new Date()]);
    const [pradioVal, setPradioVal] = useState(1);
    const [dateArrayState, setDateArrayState] = useState([]);
    const dispatch = useDispatch();
    const { allowedMaxDays } = DateRangePicker;

    const performanceRadio = (e) => {
        setPradioVal(e.target.value);
        listDateWise(filterDatePerf);
    }
    let dateRangeRef = dateRange => dateRangeRef = dateRange;
    const history = useHistory();

    const allCourierMultiSelectSettings = {
        "allItemsAreSelected": "All items are selected.",
        "clearSearch": "Clear Search",
        "noOptions": "No options",
        "search": "Search",
        "selectAll": "Select All",
        "selectSomeItems": "Select Couriers"
    }
    // const opt_allCourier = [
    //     { label: "Fedex (Express - 0.5KG)", value: "Fedex (Express - 0.5KG)" },
    // ];

    var opt_allCourier = [];
    if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers)) {
        Object.keys(props.state.shipmentMerchantCourier.couriers).map(function (key) {
            let tempObj = { label: props.state.shipmentMerchantCourier.couriers[key].courierName, value: props.state.shipmentMerchantCourier.couriers[key].courier_id }
            return opt_allCourier.push(tempObj)
        });
    }

    const { register: filter, handleSubmit: filterPerformanceOverview, reset: resetPerformance } = useForm();

    const listingPerformance = () => {
        setLoadertable(true);
        resetPerformance(); setAllCourier([]);
        // let data = {"type":checkUndeNullBlank(type) ?type: summaryType }

        var date = new Date();
        setFilterDatePerf([new Date(moment().subtract(31, 'days')), new Date()]);
        // console.log('date', date, '31days',moment())
        let mypost = { "merchant_id": props.user[0].id, "is_attempted": 1, "start_date": changeIntoDateYmd(date.setDate(date.getDate() - 31)), "end_date": changeIntoDateYmd(new Date()) }
        let myget = {}
        dispatch(performanceOverviewM(mypost, myget)).then((response) => {
            if (response.error === false) {
                setPerformanceOverviews(response);
            }
            setLoadertable(false)
        }).catch(function (response) {
            setLoadertable(false);
            errorHandling(response, "reload");
        });
    }
    const listingBrand = () => {
        let mypostbrand = { "merchant_id": props.user[0].id }
        let myget = {}

        dispatch(merchantBrandData(mypostbrand, myget)).then((response) => {
            if (response.error === false) {
                setMerchantBrand(response.data);
            }
            setLoadertable(false)
        }).catch(function (response) {
            setLoadertable(false);
            errorHandling(response, "reload");
        });
    }
    const listingPickupPoints = () => {
        let mypostpickupData = { "merchant_id": props.user[0].id }
        let myget = {}

        dispatch(merchantPickupPointData(mypostpickupData, myget)).then((response) => {
            if (response.error === false) {
                setMerchantPickupPoint(response.data);
            }
            setLoadertable(false)
        }).catch(function (response) {
            setLoadertable(false);
            errorHandling(response, "reload");
        });
    }
    // console.log('props', props);
    useEffect(() => {
        if (checkData(props.state) && checkData(props.state.viewPerformanceOverM) && checkData(props.state.viewPerformanceOverM.data)) {
            setPerformanceOverviews(props.state.viewPerformanceOverM);
        } else {
            listingBrand();
            listingPickupPoints();
        }
        if (checkData(props.state) && checkData(props.state.viewAllMerchantBrands) && checkData(props.state.viewAllMerchantBrands.data)) {
            setMerchantBrand(props.state.viewAllMerchantBrands.data);
        } else {
            listingPerformance();
        }
        listDateWise(filterDatePerf);
    }, []);
    function findPendingCount(count, performance_overview_pending_order_status) {
        const pendingDatavalue = performance_overview_pending_order_status.filter((item) => item === count)[0];
        return pendingDatavalue
    }
    function findPickupPendingCount(count) {
        let pickup_pending=[4];
        const pendingDatavalue = pickup_pending.filter((item) => item === count)[0];
        return pendingDatavalue
    }
    function findRtoCount(count) {
        const pendingDatavalue = rto_order_status.filter((item) => item === count)[0];
        return pendingDatavalue
    }
    function findDeliveredCount(count) {
        const pendingDatavalue = delivered_order_status.filter((item) => item === count)[0];
        return pendingDatavalue
    }
    function findFailedCount(count) {
        const failedDatavalue = failed_order_status.filter((item) => item === count)[0];
        return failedDatavalue
    }

    let totalBooked = 0
    let totalDelivered = 0
    let totalPending = 0
    let totalRtoPending = 0
    let totalFailed = 0
    let totalPickupPending = 0
    let dateArray = []
    const currentMoment = moment().subtract(31, 'days');
    const endMoment = moment().add(1, 'days');
    while (currentMoment.isBefore(endMoment, 'day')) {
        dateArray.push(currentMoment.format());
        currentMoment.add(1, 'days');
    }

    let performanceStatus = checkData(performanceOverviews) && checkData(performanceOverviews.data) && Object.values(performanceOverviews.data.reduce((osi, item) => {
       //console.log("item perofmrance",JSON.stringify(item));
        let shipmentDate = changeIntoDate(item.dated)
        if (!osi[shipmentDate])
            osi[shipmentDate] = { date: shipmentDate, shipmentDelivered: [], shipmentPending: [], shipmentRto: [], shipmentFailed: [], };
            let delivered = checkUndeNullBlank(findDeliveredCount(item.order_status_id)) ? item.total : 0;
            let pickup_pending = checkUndeNullBlank(findPickupPendingCount(item.order_status_id)) ? item.total : 0;
        let attemptCount = item.total_attempt!=undefined?item.total_attempt:0;

        let fOrder = checkUndeNullBlank(findFailedCount(item.order_status_id)) ? item.total : 0;
        let pending = 0;
        
        if (parseInt(pradioVal) === 1) {
            pending = checkUndeNullBlank(findPendingCount(item.order_status_id, performance_overview_pending_order_status)) ? item.total : 0;
            pending = Number(attemptCount)>0?0:(Number(pending) - Number(fOrder));

        } else {
            pending = checkUndeNullBlank(findPendingCount(item.order_status_id, performance_overview_pending_order_status)) ? item.total : 0;
            pending = Number(pending) - Number(fOrder);

        }


        let rto = checkUndeNullBlank(findRtoCount(item.order_status_id)) ? item.total : 0;
        osi[shipmentDate].shipmentDelivered.push(delivered);
        osi[shipmentDate].shipmentPending.push(pending);
        osi[shipmentDate].shipmentRto.push(rto);
        osi[shipmentDate].shipmentFailed.push(fOrder);

        totalDelivered += delivered;
        totalFailed += fOrder;
        totalPickupPending += pickup_pending;
        totalPending += pending;
        
        totalRtoPending += rto;
        totalBooked = totalDelivered + totalPending + totalRtoPending + totalFailed + totalPickupPending;
        return osi;
    }, {}));
    let performanceStatusGraph = checkData(performanceOverviews) && checkData(performanceOverviews.today) && Object.values(performanceOverviews.today.reduce((osi, item) => {
        let todayshipmentDate = changeIntoDate(item.dated)
        let tDelivered = checkUndeNullBlank(item.order_status_id) && parseInt(item.order_status_id) === 10 ? item.total : 0;
        let tRto = checkUndeNullBlank(item.order_status_id) && parseInt(item.order_status_id) === 13 ? item.total : 0;
        let tFailed = checkUndeNullBlank(item.order_status_id) && parseInt(item.order_status_id) === 11 ? item.total : 0;
        if (!osi[todayshipmentDate])
            osi[todayshipmentDate] = { date: todayshipmentDate, todayDelivered: [], todayRto: [], todayFailed: [], };

        osi[todayshipmentDate].todayDelivered.push(tDelivered)
        osi[todayshipmentDate].todayRto.push(tRto)
        osi[todayshipmentDate].todayFailed.push(tFailed)

        return osi;
    }, {}));

    let totalDeliveredPercent = 0;
    if (parseInt(pradioVal) === 1) {
        totalDeliveredPercent = totalDelivered > 0 ? (totalDelivered / (totalBooked - totalPending)) * 100 : 0
    } else {
        totalDeliveredPercent = totalDelivered > 0 ? (totalDelivered / totalBooked) * 100 : 0
    }

    let totalPendingPercent = totalPending > 0 ? (totalPending / totalBooked) * 100 : 0
    let totalRtoPendingPercent = totalRtoPending > 0 ? (totalRtoPending / totalBooked) * 100 : 0
    let totalFailedPercent = totalFailed > 0 ? (totalFailed / totalBooked) * 100 : 0

    const onFilterPerformanceOverview = (data) => {
        listDateWise(filterDatePerf);
        setPerformanceOverviews({});
        setLoadertable(true);
        let byData = {}
        let courierId = [];

        if (checkData(allCourier)) {
            allCourier.map((item) => (
                courierId.push(item.value)
            ));
        }

        if (checkUndeNullBlank(courierId)) {
            byData.courier_id = courierId
        }

        let isAttempted = checkUndeNullBlank(pradioVal) ? pradioVal : 1

        byData = { "is_attempted": isAttempted, "courier_id": checkUndeNullBlank(courierId) ? courierId : '', 
                    "brand_id": checkUndeNullBlank(data.brand_id) ? data.brand_id : '', 
                    "pickup_point_id": checkUndeNullBlank(data.pickup_point_id) ? data.pickup_point_id : '',
                    "start_date": checkData(filterDatePerf) && filterDatePerf.length > 0 ? changeIntoDateYmd(filterDatePerf[0]) : '', 
                    "end_date": checkData(filterDatePerf) && filterDatePerf.length > 0 ? changeIntoDateYmd(filterDatePerf[1]) : '' }
        if (props.user[0].user_type === "Super") {
            byData.admin_id = props.user[0].id
        } else {
            byData.merchant_id = props.user[0].id
        }
        axios({
            method: "post",
            url: `${process.env.REACT_APP_DASHBOARD_PERFORMANCE_OVERVIEW}`,
            data: byData,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            setLoadertable(false)
            if (res.data.error === false) {
                setPerformanceOverviews(res.data);
            }
        }).catch((err) => {
            setLoadertable(false)
            errorHandling(err, "");
        });
    };
    const resetFilter = () => {
        listingPerformance();
    }
    const exportShipment = (date, total, downloadFor) => {
        var orderstatusids = [];
        var datesOne = date.split("-");
        var dates = datesOne[2] + "-" + datesOne[1] + "-" + datesOne[0];

        var orderstatusids2 = orderstatusids.concat(delivered_order_status, failed_order_status, performance_overview_pending_order_status, rto_order_status);

        let data = { "all_shipment": 1, "start_date": checkUndeNullBlank(date) ? dates : '', "end_date": checkUndeNullBlank(date) ? dates : '' }

        if (downloadFor !== "") {
            if (downloadFor === "totalBooked") {
                data.order_status_ids = checkData(orderstatusids2) ? orderstatusids2 : '';
            } else if (downloadFor === "totalDelivered") {
                data.order_status_ids = checkData(delivered_order_status) ? delivered_order_status : '';
            } else if (downloadFor === "totalPending") {
                data.order_status_ids = checkData(performance_overview_pending_order_status) ? performance_overview_pending_order_status : '';
            } else if (downloadFor === "totalRtoPending") {
                data.order_status_ids = checkData(rto_order_status) ? rto_order_status : '';
            } else if (downloadFor === "totalFailed") {
                data.order_status_ids = checkData(failed_order_status) ? failed_order_status : '';
            }
        }

        data.total = checkUndeNullBlank(total) ? total : "";

        if (props.user[0].user_type === "Super") {
            data.admin_id = props.user[0].id
        } else {
            data.merchant_id = props.user[0].id
        }
        data.is_export = 1
        setLoader(true);
        axios({
            method: "post",
            url: `${process.env.REACT_APP_ALL_SHIPMENTS}`,
            data: data,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            if (res.data !== undefined && res.data.fatal !== true && res.data !== true) {
                if (parseInt(res.data.errno) > 0) {
                    setLoader(false);
                    if (res.data.sqlMessage !== undefined) {
                        Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
                    }
                } else {
                    if (res.data.is_export === 1) {
                        setTimeout(function () {
                            setLoader(false);
                            history.push("/my-downloads");
                        }, download_delay);
                    }
                }
            }
        }).catch((err) => {
            errorHandling(err, "")
        });

    }

    const listDateWise = (date) => {
        function getDates(startDate, stopDate) {
            let dateArray = [];
            let currentDate = startDate;
            while (currentDate <= stopDate) {
                dateArray.push(moment(new Date(currentDate)).format());
                currentDate = startDate.setDate(startDate.getDate() + 1);
            }
            return dateArray;
        }
        let list = getDates(new Date(date[0]), new Date(date[1]))
        list.sort(function (a, b) { return a - b });
        list.reverse();
        setDateArrayState(list);

    }

    return (
        <div className="PerformanceOverview">
            {loader && <div className="formLoader"><Spinner /></div>}
            <Card className="shadow border-0">
                <div className="page-header content-header d-flex flex-wrap justify-content-between align-items-center">
                    <Col sm={12}>
                        <div className="page-title mb-3 mb-xl-0">Performance Overview</div>
                    </Col>
                    <Col xl={12} className="pl-0">
                        <Form onSubmit={filterPerformanceOverview(onFilterPerformanceOverview)}>
                            <div className="d-flex flex-wrap align-items-center mt-2">
                                <Col md={3} lg={3} className="pl-0">
                                    <div className="d-flex flex-wrap">
                                        <FormGroup className="mb-md-0">
                                            <span className="form-check2 mr-2">
                                                <input {...filter("is_attempted")} type="radio" value='1' id="attempted" onChange={(e) => performanceRadio(e)} defaultChecked />
                                                <Label className="mx-2" for="attempted">Attempted</Label>
                                            </span>
                                        </FormGroup>
                                        <FormGroup className="mr-sm-4 mb-0">
                                            <span className="form-check2 mr-2">
                                                <input {...filter("is_attempted")} type="radio" value='0' id="overall" onChange={(e) => performanceRadio(e)} />
                                                <Label className="mx-2" for="overall">Overall</Label>
                                            </span>
                                        </FormGroup>
                                    </div>
                                </Col>
                                <Col md={3} lg={3}>
                                    <FormGroup className="mb-md-0">
                                        <MultiSelect
                                            options={opt_allCourier}
                                            value={allCourier}
                                            onChange={(e) => { setAllCourier(e); listDateWise(filterDatePerf); }}
                                            labelledBy="Select Couriers"
                                            overrideStrings={allCourierMultiSelectSettings}
                                            className={checkData(allCourier) && "active"}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3} lg={3}>
                                    <FormGroup className='position-relative mb-md-0'>
                                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                        <select {...filter("brand_id")} className="form-control-sm form-control">
                                            <option value="">Select Brand</option>
                                            {checkData(merchantBrand) && merchantBrand.map((item) => (
                                                item.status !== 0 &&
                                                <option value={item.id}>{item.brand_name}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>
                                 <Col md={3} lg={3}>
                                    <FormGroup className='position-relative mb-md-0'>
                                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                        <select {...filter("pickup_point_id")} className="form-control-sm form-control">
                                            <option value="">Select Pickup Points</option>
                                            {checkData(merchantPickupPoint) && merchantPickupPoint.map((item) => (
                                                item.status !== 0 &&
                                                <option value={item.id}>{item.contact_name}</option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md={3} lg={3}>
                                    <FormGroup className="mb-md-0">
                                        <DateRangePicker value={filterDatePerf} disabledDate={allowedMaxDays(60)} onChange={date => { setFilterDatePerf(date); }} placement="bottomEnd" placeholder="Choose Date Range" format="dd-MM-yyyy" ref={dateRangeRef} showOneCalendar={props.oneCalendar} />
                                    </FormGroup>
                                </Col>
                                <Col lg={3} className="pr-0 ml-auto">
                                    <FormGroup className="mb-0 mt-2 text-right pr-md-3">
                                        <Button className="ctm-btn btn-sm" color="primary">Search</Button>
                                        <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" outline onClick={resetFilter}>Reset</Button>
                                    </FormGroup>
                                </Col>
                            </div>
                        </Form>
                    </Col>
                </div>
                <CardBody className="p-0">
                    <div className="table-custom">
                        <Table responsive className="text-center custom-table mb-0">
                            <thead>
                                <tr>
                                    <th>{dateArrayState.length} Days</th>
                                    <th>Shipments Booked <br></br> <small>Handed over to Courier</small> </th>
                                    <th>Delivered <br></br> <small>Delivered Only</small></th>
                                    <th>Delivery Failed <br></br> <small>UnDelivered Only</small></th>
                                    <th>Pending <br></br> <small>Non Attempted Shipments</small></th>
                                    <th>Return To Origin <br></br> <small>RTO Pending & RTO Delivered</small></th>
                                </tr>
                                <tr>
                                    <th className="text-nowrap">Total</th>
                                    <th className="text-nowrap"><div className="">{totalBooked}{totalBooked > 0 && <span className="download-icon ml-2" onClick={() => { exportShipment("", totalBooked, "totalBooked") }}><FontAwesomeIcon icon={faDownload} /></span>}</div></th>
                                    <th className="text-nowrap"><div className=""><span>{totalDelivered} <span className="text-success ml-2">({totalDeliveredPercent.toFixed(2)}%)</span></span> {totalDelivered > 0 && <span className="download-icon ml-2" onClick={() => { exportShipment("", totalDelivered, "totalDelivered") }}><FontAwesomeIcon icon={faDownload} /></span>}</div></th>
                                    <th className="text-nowrap"><div className=""><span>{totalFailed} <span className="text-warning ml-2">({totalFailedPercent.toFixed(2)}%)</span></span> {totalFailed > 0 && <span className="download-icon ml-2" onClick={() => { exportShipment("", totalFailed, "totalFailed") }}><FontAwesomeIcon icon={faDownload} /></span>}</div></th>
                                    <th className="text-nowrap"><div className=""><span>{totalPending} <span className="ml-2">({totalPendingPercent.toFixed(2)}%)</span></span> {totalPending > 0 && <span className="download-icon ml-2" onClick={() => { exportShipment("", totalPending, "totalPending") }}><FontAwesomeIcon icon={faDownload} /></span>}</div></th>
                                    <th className="text-nowrap"><div className=""><span>{totalRtoPending} <span className="text-danger ml-2">({totalRtoPendingPercent.toFixed(2)}%)</span></span> {totalRtoPending > 0 && <span className="download-icon ml-2" onClick={() => { exportShipment("", totalRtoPending, "totalRtoPending") }}><FontAwesomeIcon icon={faDownload} /></span>}</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* {dateArrayState.length > 0 ? dateArrayState.reverse().map((item, index) => { */}
                                {dateArrayState.length > 0 ? dateArrayState.map((item, index) => {
                                    let obj = checkData(performanceStatus) && performanceStatus.find(o => o.date === changeIntoDate(item));
                                    let objtoday = checkData(performanceStatusGraph) && performanceStatusGraph.find(o => o.date === changeIntoDate(item));
                                    let objtodayCheck = checkUndeNullBlank(objtoday) ? objtoday : ''
                                    let todayDeliveredGraph = checkUndeNullBlank(objtodayCheck) && checkUndeNullBlank(objtodayCheck.todayDelivered) ? arrayValueSum(objtodayCheck.todayDelivered) : 0;
                                    let todayFailedGraph = checkUndeNullBlank(objtodayCheck) && checkUndeNullBlank(objtodayCheck.todayFailed) ? arrayValueSum(objtodayCheck.todayFailed) : 0;
                                    let todayRtoGraph = checkUndeNullBlank(objtodayCheck) && checkUndeNullBlank(objtodayCheck.todayRto) ? arrayValueSum(objtodayCheck.todayRto) : 0;
                                    let shipmentCheck = checkUndeNullBlank(obj) ? obj : ''
                                    let todayDeliveredship = shipmentCheck.shipmentDelivered;
                                    let todayFailedship = shipmentCheck.shipmentFailed;
                                    let todayPendingship = shipmentCheck.shipmentPending;
                                    let todayRtoship = shipmentCheck.shipmentRto;
                                    let todayDelivered = checkUndeNullBlank(todayDeliveredship) ? arrayValueSum(todayDeliveredship) : 0;
                                    let todayFailed = checkUndeNullBlank(todayFailedship) ? arrayValueSum(todayFailedship) : 0;
                                    let todayPending = checkUndeNullBlank(todayPendingship) ? arrayValueSum(todayPendingship) : 0;
                                    let todayRto = checkUndeNullBlank(todayRtoship) ? arrayValueSum(todayRtoship) : 0;
                                    let todayTotal = 0
                                    if (parseInt(pradioVal) !== 1) {
                                        let ship_pending = checkUndeNullBlank(shipmentCheck.pickup_pending) ? shipmentCheck.pickup_pending : 0
                                        todayTotal = todayDelivered + todayFailed + todayPending + todayRto + ship_pending;
                                    } else {
                                        todayTotal = todayDelivered + todayFailed + todayPending + todayRto;
                                    }
                                    let todayDeliveredPercent = todayDelivered > 0 ? (todayDelivered / todayTotal) * 100 : 0;
                                    let todayPendingPercent = todayPending > 0 ? (todayPending / todayTotal) * 100 : 0;
                                    let todayRtoPercent = todayRto > 0 ? (todayRto / todayTotal) * 100 : 0;
                                    let todayFailedPercent = todayFailed > 0 ? (todayFailed / todayTotal) * 100 : 0;

                                    return (
                                        <tr>
                                            <td>{changeIntoDate(item)}</td>
                                            <td><div className="">{todayTotal}</div></td>
                                            <td>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <span className="d-flex flex-column">
                                                        <span>{todayDelivered}</span>
                                                        <span className="text-success"><small className="font-weight-500">{todayDeliveredPercent.toFixed(2)}%</small></span>
                                                    </span>
                                                    <span className={`ml-2 ${todayDeliveredGraph <= 0 && 'opacity-0'}`}><small className="text-success mx-1"><FontAwesomeIcon icon={faArrowUp} /></small>({todayDeliveredGraph})</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <span className="d-flex flex-column">
                                                        <span>{todayFailed}</span>
                                                        <span className="text-warning"><small className="font-weight-500">{todayFailedPercent.toFixed(2)}%</small></span>
                                                    </span>
                                                    <span className={`ml-2 ${todayFailedGraph <= 0 && 'opacity-0'}`}><small className="text-warning mx-1"><FontAwesomeIcon icon={faArrowUp} /></small>({todayFailedGraph})</span>
                                                </div>
                                            </td>
                                            <td>{todayPending}<br /> <span><small className="font-weight-500">{todayPendingPercent.toFixed(2)}%</small></span></td>
                                            <td>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <span className="d-flex flex-column">
                                                        <span>{todayRto}</span>
                                                        <span className="text-danger"><small className="font-weight-500">{todayRtoPercent.toFixed(2)}%</small></span>
                                                    </span>
                                                    <span className={`ml-2 ${todayRtoGraph <= 0 && 'opacity-0'}`}><small className="text-danger mx-1"><FontAwesomeIcon icon={faArrowDown} /></small>({todayRtoGraph})</span>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }) :
                                    loadertable ? <tr>
                                        <td colSpan="5"><LoaderDefault /></td>
                                    </tr>
                                        : <tr><td colSpan="5" className="text-center"><NodataFound /></td></tr>
                                }
                                {/* {checkData(performanceStatus) ? performanceStatus.reverse().map((item, index) => {
                            let todayDelivered = eval(item.shipmentDelivered.join('+'));
                            let todayFailed = eval(item.shipmentFailed.join('+'));
                            let todayPending = eval(item.shipmentPending.join('+'));
                            let todayRto = eval(item.shipmentRto.join('+'));
                            let todayTotal = todayDelivered+todayFailed+todayPending+todayRto;
                            let todayDeliveredPercent = todayDelivered > 0 ? (todayDelivered / todayTotal ) * 100 : 0;
                            let todayPendingPercent = todayPending > 0 ? (todayPending / todayTotal ) * 100 : 0;
                            let todayRtoPercent = todayRto > 0 ? (todayRto / todayTotal ) * 100 : 0;
                            let todayFailedPercent = todayFailed > 0 ? (todayFailed / todayTotal ) * 100 : 0;
                            return(<tr>
                                <td>{item.date}</td>
                                <td><div className="text-left mx-auto dowbload-con d-flex justify-content-between">{todayTotal}<Link to="#" className="download-icon mx-1 d-none" ><FontAwesomeIcon icon={faDownload} /></Link></div></td>
                                <td>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <span className="d-flex flex-column">
                                            <span>{todayDelivered}</span>
                                            <span className="text-success"><small className="font-weight-500">{todayDeliveredPercent.toFixed(2)}%</small></span>
                                        </span>
                                        <span className="ml-2"><small className="text-success mx-1"><FontAwesomeIcon icon={faArrowUp} /></small>(0)</span>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <span className="d-flex flex-column">
                                            <span>{todayFailed}</span>
                                            <span className="text-warning"><small className="font-weight-500">{todayFailedPercent.toFixed(2)}%</small></span>
                                        </span>
                                        <span className="ml-2"><small className="text-warning mx-1"><FontAwesomeIcon icon={faArrowUp} /></small>(0)</span>
                                    </div>
                                </td>
                                <td>{todayPending}<br /> <span><small className="font-weight-500">{todayPendingPercent.toFixed(2)}%</small></span></td>
                                <td>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <span className="d-flex flex-column">
                                            <span>{todayRto}</span>
                                            <span className="text-danger"><small className="font-weight-500">{todayRtoPercent.toFixed(2)}%</small></span>
                                        </span>
                                        <span className="ml-2"><small className="text-danger mx-1"><FontAwesomeIcon icon={faArrowDown} /></small>(0)</span>
                                    </div>
                                </td>
                            </tr>)
                            }) :
                            loadertable ? <tr>
                            <td colSpan="5"><LoaderDefault /></td>
                            </tr>
                            :<tr><td colSpan="5" className="text-center"><NodataFound /></td></tr>
                        } */}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}

function mapDispatchToProps(state) {
    return {
        state: state.DataReducer
    }
}
export default connect(mapDispatchToProps)(PerformanceOverview);