import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  Label,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Form,
  Nav,
  CardHeader,
  Table,
  Pagination,
  Spinner,
  PaginationItem,
  PaginationLink,
  NavItem,
  NavLink,
  Badge,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  Input,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Alert,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import blurImage from "../../assets/img/blur-image.png";
import { Bar } from "react-chartjs-2";
import {
  faArrowDown,
  faChevronDown,
  faFilter,
  faSpinner,
  faTimes,
  faTruck,
  faAngleDown,
  faChartBar,
  faChartPie,
  faComment,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { faDropbox } from "@fortawesome/free-brands-svg-icons";
import { DateRangePicker } from "rsuite";
import {
  changeIntoDateTime,
  copyToClipBoard,
  moreText,
  errorHandling,
  remExtraSpace,
  arrayValueSum,
} from "../../utlis";
import {
  delivery_type_id,
  channel_order_status,
  download_delay,
  block_merchant_id,
  service,
  baseString,
} from "../../config";
import NodataFound from "../NodataFound";
import { ChevronLeft, ChevronRight, Copy } from "react-feather";
import classnames from "classnames";
import axios from "axios";
import Swal from "sweetalert2";
import LoaderDefault from "../LoaderDefault";
import { Link, useHistory } from "react-router-dom";
import { checkData, checkUndeNullBlank } from "../../ObjectExist";
import { useForm } from "react-hook-form";
import { MultiSelect } from "react-multi-select-component";
import { connect, useDispatch } from "react-redux";
import { AllChannelBrand, mergeAccountList } from "../../actions";
import EditOrder from "./EditOrder";
import { toast } from "react-toastify";
import moment from "moment";
import StarRatings from "react-star-ratings";
import { getFirstLetter } from "../../utlis";
import TrackOrder from "../Orders/TrackOrder";
import EditSidebar from "./EditSidebar";
import { set } from "date-fns";

const ChannelOrder = (props) => {
  const [orderDetailDate, setOrderDetailDate] = useState("");
  // console.log(props,'prps')
  const [loader, setLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [filterDate, setFilterDate] = useState([]);
  const [filterDateOverview, setFilterDateOverview] = useState([
    new Date(),
    new Date(),
  ]);
  const [channelOrderTags, setChannelOrderTags] = useState([]);
  const [showOverlay, setShowOverlay] = useState(false);
  const [activeTab, setActiveTab] = useState("in-process");
  const [channelOrder, setChannelOrder] = useState([]);
  const [utmType, setutmType] = useState("utm_campaign");
  const [channelSummary, setChannelSummary] = useState({});
  const [channelMarketingSummary, setChannelMarketingSummary] = useState({});
  // console.log(channelMarketingSummary,"bheelo");
    let labels = channelMarketingSummary?.data?.map(entry => entry.utm_campaign)
    let totalCounts = channelMarketingSummary?.data?.map(entry => entry.total)
    // const [labels , setLabels] = useState();
    // const [totalCounts , settotalCounts] = useState();
  
  
  // console.log({labels,totalCounts},"Hii")
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState({
    end_date: moment().format("YYYY-MM-DD"),
    start_date: moment().format("YYYY-MM-DD"),
  });
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [page, setPage] = useState(1);
  const [dataList, setDataList] = useState([]);
  const [allTagsChecked, setAllTagsChecked] = useState(false);
  const [tagData, setTagData] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [checked, setChecked] = useState(false);
  const [overallchecked, setOverallChecked] = useState(false);
  const [checkboxOrderId, setCheckboxOrderId] = useState([]);
  const [editmodal, setEditmodal] = useState(false);
  const [editmodalOrder, setEditmodalOrder] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});

  const [orderUpdate, setOrderUpdate] = useState({});
  const [merchantId, setMerchantId] = useState("");
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [shipOrdersModal, setShipOrdersModal] = useState(false);
  const [wghtDmnModal, setWghtDmnModal] = useState(false);
  const [analDmnModal, setAnalDmnModal] = useState(false);
  const [anotherActModal, setAnotherActModal] = useState(false);
  const [linkedMerchant, setLinkedMerchant] = useState({});
  const [overviewModal, setOverviewModal] = useState(false);
  const [overviewMarketModal, setOverviewMarketModal] = useState(false);
  const [pickupLocation, setPickupLocation] = useState([]);
  const [searchValue, setSearchValue] = useState(" ");
  const [blockfeatures, setBlockfeatures] = useState(false);
  const [courierList, setCourierList] = useState({});
  const [changeCourierModal, setChangeCourierModal] = useState(false);
  const [togglePriceModal, settogglePriceModal] = useState(false);
  const [sideAnim, setSideAnim] = useState(false);
  const [orderTracking, setOrderTracking] = useState({});
  const [lastOrderStatus, setlastOrderStatus] = useState();
  const [statusRecheckOrderId, setStatusRecheckOrderId] = useState([]);
  const [tempClass, setTempClass] = useState(null);
  const [pickupKey, setPickupKey] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [pickupDefault, setPickupDefault] = useState([]);

  const [invoiceVal, setInvoiceVal] = useState("");

  const [states, setStates] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [productsList, setProductList] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [productIdName, setProductIdName] = useState([]);

  // Handle state selection change
  const handleStateSelectionChange = (selected) => {
    setSelectedStates(selected);
    // console.log('Selected States:', selected);
  };

  const handleProductSelectionChange = (selected) => {
    setSelectedProducts(selected);
    // console.log('Selected States:', selected);
  };

  const [formDataN, setFormDataN] = useState({
    height: "",
    breadth: "",
    weight: "",
    length: "",
  });

  const [showForm, setShowForm] = useState(false);

  //ananlytics state
  // const [tags,settags] = useState([]);
  const [merchantTagsKey, setmerchantTagskeys] = useState([]);
  const [merchantTagsValue, setmerchantTagsValue] = useState([]);
  const [filterDateOverviewNew, setFilterDateOverviewNew] = useState([
    new Date(),
  ]);

  let dataNew = {
    start_dateNew: filterDateOverviewNew
      ? moment(filterDateOverviewNew[0]).format("YYYY-MM-DD")
      : "",
    end_dateNew: filterDateOverviewNew
      ? moment(filterDateOverviewNew[1]).format("YYYY-MM-DD")
      : "",
  };
// console.log(props, "bittu");
  const payloaddata = {
    merchant_id: props.user[0].id,
    start_dateNew: dataNew.start_dateNew,
    end_dateNew: dataNew.end_dateNew,
  };

  useEffect(() => {
    const fetchData = () =>
      axios({
        method: "post",
        url: `${process.env.REACT_APP_MERCHANT_CHANNEL_ORDER_OVERVIEW}`,
        data: payloaddata,
      })
        .then((res) => {
          // console.log(res,'restag')
          const merchantKeys = res?.data?.merchant_tags;
          if (merchantKeys) {
            setmerchantTagskeys(Object.keys(merchantKeys));
            setmerchantTagsValue(Object.values(merchantKeys));
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    fetchData();
    return () => {
      fetchData();
    };
  }, [filterDateOverviewNew]);

  //analytics state
  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const toggleEditOrderClose = () => {
    // alert(2)
    setEditmodalOrder(false);
    setFormDataN({
      height: "",
      breadth: "",
      weight: "",
      length: "",
    });
    // setPickupLocation({});
    setZoneAndPrice({});
    setCourierwithPrice({});
    // setSelectedPickupPoint("");
    setSelectedPickupPoint2("");
    // alert(3)
  };

  // const toggleEditOrderOpen = () => {
  //   alert(3)
  //   setEditmodalOrder(false);
  //   setTimeout(() => {
  //     // Call the parent's callback function to reopen the modal
  //     alert(3)
  //     setEditmodalOrder(true);
  //   }, 1000);

  //   // alert(2)
  // };

  const totalAmount = channelOrder?.data?.reduce((accumulator, item) => {
    // Parsing the product_invoice_value as a float to handle cases where it's a string
    const value = parseFloat(item?.product_invoice_value);

    // Adding the parsed value to the accumulator
    accumulator += value;

    return accumulator;
  }, 0);

  const formattedTotalAmount = totalAmount?.toFixed(3)?.replace(/\.00$/, "");

  let ShipmentStartDate = new URLSearchParams(window.location.search).get(
    "start_date"
  );
  let ShipmentEndDate = new URLSearchParams(window.location.search).get(
    "end_date"
  );
  let orderTab = new URLSearchParams(window.location.search).get("o");
  const closeTracker = (data) => {
    setSideAnim(!sideAnim);
    setOrderTracking({});
    setTempClass(null);
  };

  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
  };
  // const { register,  formState: { errors }, setValue } = useForm();

  const orderTrack = (awbNo, lastStatus) => {
    setLoader(true);
    setlastOrderStatus(lastStatus);
    setStatusRecheckOrderId(awbNo);
    let data = { awb_no: awbNo };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_TRACK_ORDER}`,
      data: data,
      headers: { token: window.btoa(baseString) },
    })
      .then((res) => {
        setLoader(false);
        if (res.data !== undefined && res.data.fatal !== true) {
          if (parseInt(res.data.errno) > 0) {
            if (res.data.sqlMessage !== undefined) {
              Swal.fire({
                icon: "error",
                text: res.data.sqlMessage,
                title: "Error in SQL",
              });
            }
          } else {
            if (res.fatal !== true && res.error !== true) {
              setOrderTracking(res.data.data);
            } else {
              Swal.fire({ title: "Error", text: res.msg, icon: "error" });
            }
          }
        }
      })
      .catch((err) => {
        errorHandling(err, "");
        setLoader(false);
      });
  };
  const changeCourierToggle = () => {
    if (checkboxOrderId.length > 0) {
      setChangeCourierModal(!changeCourierModal);
    } else {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: "Please Select The Order First",
      });
    }
  };
  const togglePriceOrder = () => {
    if (checkboxOrderId.length > 0) {
      settogglePriceModal(!togglePriceModal);
    } else {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: "Please Select The Order First",
      });
    }
  };
  const orderData = [
    { id: 1, value: "Customer Name", isChecked: false },
    { id: 2, value: "Brand Name", isChecked: false },
    { id: 3, value: "Pickup Point", isChecked: false },
    { id: 4, value: "Upload Date", isChecked: false },
  ];
  const [tagChecked, setTagChecked] = useState(
    orderData.map((index, key) => key === false)
  );
  const dropFilterCount = 20;
  const history = useHistory();
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    if (props.user[0].user_type === "Merchant") {
      // channelOrderListing(tab);
      history.push(`/channel-orders?o=${tab}`);
    }
  };

  const clearAll = (value) => {
    setAllTagsChecked(false);
    setTagChecked(false);
  };
  const ApplyTagFIlter = () => {
    // console.log('checked',checked)
  };
  const selectAll = (value) => {
    setOverallChecked(false);
    let abc =
      checkData(channelOrder.data) &&
      channelOrder.data.map((key) => key === false);
    if (value) {
      var opt_order_id = [];
      checkData(channelOrder.data) &&
        Object.keys(channelOrder.data).map(function (key) {
          let tempObj = channelOrder.data[key].id;
          opt_order_id.push(tempObj);
          return setCheckboxOrderId(opt_order_id);
        });
      // ));
    } else {
      setCheckboxOrderId([]);
    }
    setChecked(abc);
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const utilityCheckbox in newState) {
        newState[utilityCheckbox] = value;
      }
      return newState;
    });
  };
  const selectOverAll = () => {
    setOverallChecked(true);
    // setCheckboxOrderId([]);
  };

  const toggleCheck = (utilityCheckbox, event, Id) => {
    setOverallChecked(false);
    var opt_order_id = checkboxOrderId;
    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[utilityCheckbox] = !prevState[utilityCheckbox];
      return newState;
    });
    if (event.target.checked) {
      opt_order_id.push(Id);
      setCheckboxOrderId(opt_order_id);
    } else {
      function arrayRemove(arr, value) {
        return arr.filter(function (ele) {
          return ele !== value;
        });
      }
      var result = arrayRemove(checkboxOrderId, Id);
      setCheckboxOrderId(result);
    }
  };
  const toggleTagsCheck = (event, utilityCheckbox, dataId, dataValue) => {
    var opt_tag_id = tagData;
    setTagChecked((prevState) => {
      const newState = { ...prevState };
      newState[utilityCheckbox] = !prevState[utilityCheckbox];
      return newState;
    });
    if (event.target.checked) {
      opt_tag_id.push(dataId);
      setTagData(opt_tag_id);
    } else {
      function arrayRemove(arr, dataId) {
        return arr.filter(function (ele) {
          return ele !== dataId;
        });
      }
      var result = arrayRemove(tagData, dataId);
      setTagData(result);
    }
  };
  const tagToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const resetForm = () => {
    channelOrderListing(activeTab);
  };
  const onDateChange = (e) => {
    setFilterDate(e.text);
  };
  const [allBrand, setAllBrand] = useState([]);
  const allBrandMultiSelectSettings = {
    allItemsAreSelected: "All items are selected.",
    clearSearch: "Clear Search",
    noOptions: "No options",
    search: "Search",
    selectAll: "Select All",
    selectSomeItems: "Select Brand",
  };
  const dispatch = useDispatch();

  var opt_allBrand = [];
  if (
    checkData(props.state) &&
    checkData(props.state.channelBrandData) &&
    checkData(props.state.channelBrandData.brands)
  ) {
    Object.keys(props.state.channelBrandData.brands).map(function (key) {
      let tempObj = {
        label: props.state.channelBrandData.brands[key].brandName,
        value: props.state.channelBrandData.brands[key].brand_id,
      };
      return opt_allBrand.push(tempObj);
    });
  }

  //  Object.entries(opt_allBrand).map(([key, label]) => (
  //  console.log("Jey",key,"label",label)

  //))
  //console.log("opt_allBrand",JSON.stringify(opt_allBrand));

  const [leadStatus, setLeadStatus] = useState([]);
  const allLeadStatusMultiSelectSettings = {
    allItemsAreSelected: "All items are selected.",
    clearSearch: "Clear Search",
    noOptions: "No options",
    search: "Search",
    selectAll: "Select All",
    selectSomeItems: "Lead Status",
  };
  var opt_allLeadStatus = [];

  for (const property in channel_order_status) {
    let tempObj = {
      label: `${channel_order_status[property]}`,
      value: property,
    };
    opt_allLeadStatus.push(tempObj);
  }

  const [allTags, setAllTags] = useState([]);
  const allTagsMultiSelectSettings = {
    allItemsAreSelected: "All items are selected.",
    clearSearch: "Clear Search",
    noOptions: "No options",
    search: "Search",
    selectAll: "Select All",
    selectSomeItems: "System Tags",
  };
  var opt_allTags = [
    { label: "Duplicate Mobile", value: "Duplicate Mobile" },
    { label: "Short Address", value: "Short Address" },
    { label: "Duplicate Email", value: "Duplicate Email" },
    { label: "Duplicate IP", value: "Duplicate IP" },
    { label: "Cancellation Requested", value: "Cancellation Requested" },
    { label: "Invalid Mobile", value: "Invalid Mobile" },
    { label: "Multiple Order", value: "Multiple Order" },
    { label: "Address Corrected", value: "Address Corrected" },
    { label: "Address Updated", value: "Address Updated" },
  ];

  const [custRating, setCustRating] = useState([]);
  const custRatingMultiSelectSettings = {
    allItemsAreSelected: "All items are selected.",
    clearSearch: "Clear Search",
    noOptions: "No options",
    search: "Search",
    selectAll: "Select All",
    selectSomeItems: "Customer Rating",
  };
  var opt_custRaing = [
    { label: "1 ★", value: 1 },
    { label: "2 ★", value: 2 },
    { label: "3 ★", value: 3 },
    { label: "4 ★", value: 4 },
    { label: "5 ★", value: 5 },
  ];

  const toggleEditClose = () => setEditmodal(false);
  const toggleEdit = (productId, merchantId) => {
    setOrderDetail({});
    setLoader(true);
    setMerchantId(merchantId);
    let data = {};
    data = { order_id: productId, merchant_id: merchantId, is_edit: 1 };
    setEditmodal(!editmodal);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_CHANNEL_ORDER_DETAIL}`,
      data: data,
      headers: { token: window.btoa(baseString) },
    })
      .then((res) => {
        setLoader(false);
        if (res.data !== undefined && res.data.fatal !== true) {
          if (parseInt(res.data.errno) > 0) {
            if (res.data.sqlMessage !== undefined) {
              Swal.fire({
                icon: "error",
                text: res.data.sqlMessage,
                title: "Error in SQL",
              });
            }
          } else {
            // console.log(res.data.data,'okbs')
            setOrderDetail(res.data.data);
          }
        }
      })
      .catch((err) => {
        setLoader(false);
        errorHandling(err, "");
      });
  };

  const toggleEditOrder = (productId, merchantId) => {
    // console.log(productId,merchantId,'okk')
    setOrderDetail({});
    setLoader(true);
    setMerchantId(merchantId);
    let data = {};
    data = { order_id: productId, merchant_id: merchantId, is_edit: 1 };
    setEditmodalOrder(!editmodalOrder);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_CHANNEL_ORDER_DETAIL}`,
      data: data,
      headers: { token: window.btoa(baseString) },
    })
      .then((res) => {
        setLoader(false);
        if (res.data !== undefined && res.data.fatal !== true) {
          if (parseInt(res.data.errno) > 0) {
            if (res.data.sqlMessage !== undefined) {
              Swal.fire({
                icon: "error",
                text: res.data.sqlMessage,
                title: "Error in SQL",
              });
            }
          } else {
          
            setOrderDetail(res.data.data);
            setOrderUpdate(res.data.data);
            setOrderDetailDate(res?.data?.data?.orderDetail?.updated_at);
            // console.log(orderDetail,orderDetailss,'jkn')
          }
        }
      })
      .catch((err) => {
        setLoader(false);
        errorHandling(err, "");
      });
  };

  let dateRangeRef = (dateRange) => (dateRangeRef = dateRange);
  const channelOrderSummary = (type) => {
    setLoader(true);
    // let data = {}
    let data = {
      start_date: checkData(filterDateOverview)
        ? moment(filterDateOverview[0]).format("YYYY-MM-DD")
        : "",
      end_date: checkData(filterDateOverview)
        ? moment(filterDateOverview[1]).format("YYYY-MM-DD")
        : "",
    };
    if (type === "reset") {
      data.start_date = moment().format("YYYY-MM-DD");
      data.end_date = moment().format("YYYY-MM-DD");
      setFilterDateOverview([new Date(), new Date()]);
    }
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id;
    } else {
      data.merchant_id = props.user[0].id;
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_CHANNEL_ORDER_SUMMARY}`,
      data: data,
      headers: { token: window.btoa(baseString) },
    })
      .then((res) => {
        setLoader(false);
        if (res.data.error === false) {
          setChannelSummary(res.data);
        } else {
          Swal.fire({ icon: "error", text: res.data.msg, title: "Error" });
        }
      })
      .catch((err) => {
        setLoader(false);
        errorHandling(err, "reload");
      });
  };
  const channelOrderMarketingSummary = (type) => {
    setLoader(true);

    //setAllBrand([]);
    var tmpBrand = [];
    if (checkData(allBrand)) {
      allBrand.map((item) => tmpBrand.push(item.value));
    }

    // let data = {}
    let data = {
      column_name: utmType,
      brand_id: checkUndeNullBlank(tmpBrand) ? tmpBrand : "",
      start_date: checkData(filterDateOverview)
        ? moment(filterDateOverview[0]).format("YYYY-MM-DD")
        : "",
      end_date: checkData(filterDateOverview)
        ? moment(filterDateOverview[1]).format("YYYY-MM-DD")
        : "",
    };
    if (type === "reset") {
      data.start_date = moment().format("YYYY-MM-DD");
      data.end_date = moment().format("YYYY-MM-DD");
      setFilterDateOverview([new Date(), new Date()]);
    }
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id;
    } else {
      data.merchant_id = props.user[0].id;
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_CHANNEL_MARKET_ORDER_SUMMARY}`,
      data: data,
      headers: { token: window.btoa(baseString) },
    })
      .then((res) => {
        setLoader(false);
        if (res.data.error === false) {
          setChannelMarketingSummary(res.data);
          // console.log("channelMarketingSummary",JSON.stringify(res.data));
        } else {
          Swal.fire({ icon: "error", text: res.data.msg, title: "Error" });
        }
      })
      .catch((err) => {
        setLoader(false);
        errorHandling(err, "reload");
      });
  };

  const channelOrderListing = (order_status_id) => {
    setFilterInput({
      end_date: moment().format("YYYY-MM-DD"),
      start_date: moment().format("YYYY-MM-DD"),
    });
    let odr_status_id = "";

    if (order_status_id === "in-process") {
      odr_status_id = "1";
    } else if (order_status_id === "cancelled") {
      odr_status_id = "2";
    } else if (order_status_id === "shipped") {
      odr_status_id = "3";
    } else if (order_status_id === "all") {
      odr_status_id = "";
    }
    resetFilter();
    setAllBrand([]);
    setAllTags([]);
    setCustRating([]);

    if (
      checkUndeNullBlank(ShipmentStartDate) &&
      checkUndeNullBlank(ShipmentEndDate)
    ) {
      setFilterDate([ShipmentStartDate, ShipmentEndDate]);
    } else {
      setFilterDate([new Date(), new Date()]);
    }
    setOverallChecked(false);
    setCheckboxOrderId([]);
    setCheckedAll(false);
    setChecked(false);
    setCurrentCount(1);
    setPage(1);
    setTableLoader(true);
    // let data = {"order_status_id": order_status_id !== undefined && order_status_id !== '4' ? order_status_id :  order_status_id !== undefined && order_status_id === '4' ? '' : '1',"start_date":changeIntoDateYmd(firstDayOftheMonth()),"end_date":changeIntoDateYmd(new Date())}
    // let data = {"order_status_id": order_status_id !== undefined && order_status_id !== '4' ? order_status_id :  order_status_id !== undefined && order_status_id === '4' ? '' : '1'}
    let data = {
      order_status_id: order_status_id !== undefined && odr_status_id,
    };

    if (
      checkUndeNullBlank(ShipmentStartDate) &&
      checkUndeNullBlank(ShipmentEndDate)
    ) {
      data.start_date = ShipmentStartDate;
      data.end_date = ShipmentEndDate;
    } else {
      data.start_date = moment().format("YYYY-MM-DD");
      data.end_date = moment().format("YYYY-MM-DD");
    }

    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id;
    } else {
      data.merchant_id = props.user[0].id;
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_CHANNEL_ORDER}`,
      data: data,
      headers: { token: window.btoa(baseString) },
    })
      .then((res) => {
        setTableLoader(false);
        if (res.data !== undefined && res.data.fatal !== true) {
          if (parseInt(res.data.errno) > 0) {
            if (res.data.sqlMessage !== undefined) {
              Swal.fire({
                icon: "error",
                text: res.data.sqlMessage,
                title: "Error in SQL",
              });
            }
          } else {
            console.log('data-channel',res.data)
            setChannelOrder(res.data);
            // console.log(res.data.data,'cj')
            if (res.data?.channelSettingsArr) {
              // console.log(res.data?.channelSettingsArr[0])
              const channelSettings = JSON.parse(
                res.data &&
                  res.data.channelSettingsArr[0] &&
                  res.data.channelSettingsArr[0].channel_order_settings
              );
              setChannelOrderTags(channelSettings);
            } else {
            }
            // console.log(res?.data?.data,'ress')
            if (
              res?.data &&
              res?.data?.data?.some((item) => item?.channel_setup_block === 0)
            ) {
              setShowOverlay(true);
            }
          }
        }
      })
      .catch((err) => {
        setTableLoader(false);
        errorHandling(err, "reload");
      });
  };
  const allChannelFilter = () => {
    let mypost = {};
    let myget = {};
    if (checkData(props.user)) {
      if (props.user[0].user_type === "Super") {
        mypost = { admin_id: props.user[0].id };
      } else {
        mypost = { merchant_id: props.user[0].id };
      }
    }
    dispatch(AllChannelBrand(mypost, myget))
      .then((response) => {
        if (response && response?.states) {
          const options = response.states.map((state) => ({
            label: state.customer_state, // Assuming 'customer_state' is the property containing the state name
            value: state.customer_state, // You can use the same value for both label and value if needed
          }));
          setStates(options);
        }
        if (response && response?.products) {
          const options2 = response.products.map((product) => ({
            label: product.product_name, // Assuming 'customer_state' is the property containing the state name
            value: product.product_name, // You can use the same value for both label and value if needed
          }));
          setProductList(options2);
        }
      })
      .catch(function (response) {
        errorHandling(response, "");
      });
  };
  function checkMerchant(mid) {
    if (mid === parseInt(props.user[0].id)) {
      setBlockfeatures(true);
    }
    return;
  }
  const couurierListing = () => {
    if (checkData(props.user) && props.user[0].user_type === "Merchant") {
      setLoader(true);
      axios({
        method: "post",
        url: process.env.REACT_APP_MERCHANT_COURIER_LIST,
        data: { merchant_id: checkData(props.user) && props.user[0].id },
        headers: { token: window.btoa(baseString) },
      })
        .then(function (response) {
          setLoader(false);
          if (response.data.error === false) {
            // console.log(response.data.data.courierList)
            setCourierList(response.data.data.courierList);
          } else {
            Swal.fire({
              title: "Error",
              text: response.data.msg
                ? response.data.msg
                : "Something went wrong",
              icon: "error",
            });
          }
        })
        .catch(function (err) {
          // alert(32)
          setLoader(false);
          errorHandling(err, "reload");
        });
    }
  };
  useEffect(() => {
    block_merchant_id.filter(checkMerchant);
    if (orderTab === "all") {
      setActiveTab("all");
    }
    couurierListing();
    setDataList(orderData);
    let allChecked = true;
    for (const utilityCheckbox in tagChecked) {
      if (tagChecked[utilityCheckbox] === false) {
        allChecked = false;
      }
    }
    if (allChecked) {
      setAllTagsChecked(true);
    } else {
      setAllTagsChecked(false);
    }
    if (checkData(props.state) && checkData(props.state.channelBrandData)) {
    } else {
      if (checkData(props.user)) {
        allChannelFilter();
      }
    }
    setSelectperpage(20);
    setLoader(false);
    if (checkData(props.user) && props.user[0].user_type === "Merchant") {
      pickupLocationList();
      if (orderTab !== null) {
        history.push(`/channel-orders?o=${orderTab}`);
        channelOrderListing(orderTab);
        setActiveTab(orderTab);
      } else {
        history.push(`/channel-orders?o=${activeTab}`);
        channelOrderListing(activeTab);
      }
    } else {
      setActiveTab("all");
    }

    if (checkData(props.user) && props.user[0].user_type === "Merchant") {
      LinkedMerchantL();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagChecked, orderTab]);

  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setTableLoader(true);
    setPage(1);
    setCurrentCount(1);
    let pagePerpage = { page: 1, per_page: event.target.value };
    onpaginationFilter(filterInput, pagePerpage);
  }
  function nextPageData() {
    if (
      checkData(channelOrder.data) &&
      checkUndeNullBlank(channelOrder.totalPage)
    ) {
      // &&checkUndeNullBlank(channelOrder.totalPage).length>page.length
      if (channelOrder.totalPage > page) {
        setCurrentCount(
          currentCount + Object.entries(channelOrder.data).length
        );
        setPage(page + 1);
        setTableLoader(true);
        let pagePerpage = { page: page + 1, per_page: selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1);
      checkData(channelOrder.data);
      if (currentCount > Object.entries(channelOrder.data).length) {
        setCurrentCount(currentCount - selectperpage);
      } else {
        setCurrentCount(1);
      }
      let pagePerpage = { page: page > 1 && page - 1, per_page: selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }
  const {
    register: filter,
    handleSubmit: filterChannelOrderSubmit,
    watch: filterWatch,
    reset: resetFilter,
  } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage);
  };
  const [selectedStateVal, setSelectedStateVal] = useState([]);

  useEffect(() => {
    const stateValues = selectedStates.map((state) => state.value);
    setSelectedStateVal(stateValues);
  }, [selectedStates]);
  const onFilterchannelOrder = (data, pagePerpage) => {
    // console.log(data,'dataa')

    data.customer_state = selectedStateVal;

    // console.log(data,'da')
    // setIsExport(true);
    pagePerpage.page = 1;
    if (
      (checkUndeNullBlank(data.search_field) &&
        checkUndeNullBlank(data.search_value)) ||
      checkUndeNullBlank(data.weight) ||
      checkData(data.is_verify) ||
      checkData(data.is_user_tag) ||
      checkData(data.delivery_type_id) ||
      checkData(leadStatus) ||
      checkData(data.merchant_tags) ||
      checkData(allBrand) ||
      checkData(allTags) ||
      checkData(filterDate) ||
      checkData(custRating)
    ) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage);
    } else {
      Swal.fire({ title: "info", text: "Please Choose Filter", icon: "info" });
    }
  };
  const onCommonFilter = (data, pagePerpage) => {
    setOverallChecked(false);
    setCheckedAll(false);
    setChecked(false);
    setCheckboxOrderId([]);
    let byData = {};
    let tmpLeadStatus = [];
    let tmpBrand = [];
    let tmpAllTags = [];
    let tmpCustRating = [];
    let odr_status_id = "";

    if (checkData(leadStatus)) {
      leadStatus.map((item) => tmpLeadStatus.push(item.value));
    }
    if (checkData(allBrand)) {
      allBrand.map((item) => tmpBrand.push(item.value));
    }
    allTags.map((item) => tmpAllTags.push(item.value));
    custRating.map((item) => tmpCustRating.push(item.value));

    setLoader(true);
    if (data === undefined) {
      data = filterInput;
    }

    if (activeTab === "in-process") {
      odr_status_id = "1";
    } else if (activeTab === "cancelled") {
      odr_status_id = "2";
    } else if (activeTab === "shipped") {
      odr_status_id = "3";
    } else if (activeTab === "all") {
      odr_status_id = "";
    }
    let weightAray = [];
    if (checkUndeNullBlank(data.weight)) {
      weightAray = data.weight.split(",");
    }
    let sWeight = "";
    let eWeight = "";
    if (weightAray.length > 0) {
      sWeight = weightAray[0];
      eWeight = weightAray[1];
    }

    // byData={"order_status_id":activeTab !== '4' ? activeTab:'',"search_field":checkUndeNullBlank(data.search_field)?data.search_field:'',"search_value":checkUndeNullBlank(data.search_value)?remExtraSpace(data.search_value):'',"is_verify":checkUndeNullBlank(data.is_verify)?data.is_verify:'',"merchant_tags":checkUndeNullBlank(data.merchant_tags)?data.merchant_tags:'',"is_user_tag":checkUndeNullBlank(data.is_user_tag)?data.is_user_tag:'',"system_tags":checkUndeNullBlank(tmpAllTags)?tmpAllTags:'',"delivery_type_id":checkUndeNullBlank(data.delivery_type_id)?data.delivery_type_id:'',"brand_id":checkUndeNullBlank(tmpBrand)?tmpBrand:'',"start_date":filterDate.length > 0 ? moment(filterDate[0]).format('YYYY-MM-DD') : '',"end_date":filterDate.length > 0 ? moment(filterDate[1]).format('YYYY-MM-DD') : ''}
    byData = {
      order_status_id: odr_status_id,
      search_field: checkUndeNullBlank(data.search_field)
        ? data.search_field
        : "",
      search_value: checkUndeNullBlank(data.search_value)
        ? remExtraSpace(data.search_value)
        : "",
      start_weight: sWeight,
      end_weight: eWeight,
      is_verify: checkUndeNullBlank(data.is_verify) ? data.is_verify : "",
      merchant_tags: checkUndeNullBlank(data.merchant_tags)
        ? data.merchant_tags
        : "",
      is_user_tag: checkUndeNullBlank(data.is_user_tag) ? data.is_user_tag : "",
      system_tags: checkUndeNullBlank(tmpAllTags) ? tmpAllTags : "",
      customer_rating: checkUndeNullBlank(tmpCustRating) ? tmpCustRating : "",
      delivery_type_id: checkUndeNullBlank(data.delivery_type_id)
        ? data.delivery_type_id
        : "",
      brand_id: checkUndeNullBlank(tmpBrand) ? tmpBrand : "",
      start_date: checkData(filterDate)
        ? moment(filterDate[0]).format("YYYY-MM-DD")
        : "",
      end_date: checkData(filterDate)
        ? moment(filterDate[1]).format("YYYY-MM-DD")
        : "",
      customer_state: selectedStateVal,
    };

    setFilterInput(byData);
    if (props.user[0].user_type === "Super") {
      byData.admin_id = props.user[0].id;
    } else {
      byData.merchant_id = props.user[0].id;
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_CHANNEL_ORDER}?page=${
        checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.page)
          ? pagePerpage.page
          : page
      }&per_page=${
        checkUndeNullBlank(pagePerpage) &&
        checkUndeNullBlank(pagePerpage.per_page)
          ? pagePerpage.per_page
          : selectperpage
      }`,
      data: byData,
      headers: { token: window.btoa(baseString) },
    })
      .then((res) => {
        setLoader(false);
        if (res.data !== undefined && res.data.fatal !== true) {
          if (parseInt(res.data.errno) > 0) {
            if (res.data.sqlMessage !== undefined) {
              Swal.fire({
                icon: "error",
                text: res.data.sqlMessage,
                title: "Error in SQL",
              });
            }
          } else {
            if (res.fatal !== true && res.error !== true) {
              setChannelOrder(res.data);
            } else {
              Swal.fire({ title: "Error", text: res.msg, icon: "error" });
            }
          }
        }
      })
      .catch((err) => {
        errorHandling(err, "");
        setLoader(false);
      });
  };
  const exportOrders = () => {
    if (checkData(filterInput)) {
      let data = filterInput;
      if (props.user[0].user_type === "Super") {
        data.admin_id = props.user[0].id;
      } else {
        data.merchant_id = props.user[0].id;
      }
      data.is_export = 1;
      setLoader(true);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_CHANNEL_ORDER}`,
        data: data,
        headers: { token: window.btoa(baseString) },
      })
        .then((res) => {
          if (
            res.data !== undefined &&
            res.data.fatal !== true &&
            res.data !== true
          ) {
            if (parseInt(res.data.errno) > 0) {
              setLoader(false);
              if (res.data.sqlMessage !== undefined) {
                Swal.fire({
                  icon: "error",
                  text: res.data.sqlMessage,
                  title: "Error in SQL",
                });
              }
            } else {
              if (res.data.is_export === 1) {
                setTimeout(function () {
                  setLoader(false);
                  history.push("/my-downloads");
                }, download_delay);
              }
            }
          }
        })
        .catch((err) => {
          setLoader(false);
          errorHandling(err, "");
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please Choose Filter",
      });
    }
  };
  const toggleChangeStatus = () => {
    if (checkboxOrderId.length > 0) {
      setChangeStatusModal(!changeStatusModal);
    } else {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: "Please Select The Order First",
      });
    }
  };
  const {
    register: changeStatus,
    handleSubmit: changeStatusSubmit,
    reset: reset2,
  } = useForm();
  const onChangeStatus = (data) => {
    data.order_ids = checkboxOrderId;
    data.merchant_name = checkData(props.user) && props.user[0].name;
    data.merchant_id = checkData(props.user) && props.user[0].id;
    axios({
      method: "post",
      url: `${process.env.REACT_APP_CHANNEL_ORDER_UPDATE_STATUS}`,
      data: data,
      headers: { token: window.btoa(baseString) },
    })
      .then((res) => {
        setLoader(false);
        if (res.data.error === false) {
          Swal.fire({
            icon: "success",
            text: res.data.msg,
            title: "Success",
          }).then((okay) => {
            if (okay) {
              reset2();
              setChangeStatusModal(false);
              setCheckboxOrderId([]);
              setCheckedAll(false);
              setChecked(false);
              if (checkData(filterInput)) {
                onCommonFilter();
              } else {
                channelOrderListing();
              }
            }
          });
        } else {
          Swal.fire({ title: "Error", text: res.msg, icon: "error" });
        }
      })
      .catch((err) => {
        errorHandling(err, "");
        setLoader(false);
      });
  };
  const closeChangeStatus = (orderId) => {
    setChangeStatusModal(false);
    reset2();
  };

  const pickupLocationList = () => {
    let mydata = {};
    if (
      props.user !== undefined &&
      props.user !== null &&
      props.user[0].user_type === "Merchant"
    ) {
      mydata = { merchant_id: props.user[0].id };
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_ACTIVE_PICKUPPOINT}`,
      data: mydata,
      headers: { token: window.btoa(baseString) },
    })
      .then((res) => {
        if (res.data !== undefined && res.data.fatal !== true) {
          if (parseInt(res.data.errno) > 0) {
            if (res.data.sqlMessage !== undefined) {
              Swal.fire({
                icon: "error",
                text: res.data.sqlMessage,
                title: "Error in SQL",
              });
            }
          } else {
            if (res.data.error === false) {
              // console.log(res.data.data,typeof(res.data.data))
              setPickupLocation(res.data.data);

              var filteredData = res.data.data.filter(
                (item) => item.is_default
              );
              // console.log(filteredData,'filteredData');
              setPickupDefault(filteredData);

              // Now you can use filteredObject outside the loop
              // console.log(filteredObject , 'filterrr');
              // setPickupDefault(filteredObject);
            }
          }
        }
      })
      .catch((err) => {
        errorHandling(err, "");
      });
  };
  const {
    register: shipOrders,
    handleSubmit: shipOrdersSubmit,
    formState: { errors: errors1 },
    setValue,
  } = useForm();

  const toggleShipOrdersClose = () => {
    setShipOrdersModal(false);
  };
  const toggleShipOrders = () => {
    [{ name: "service", value: "Surface" }].forEach(({ name, value }) =>
      setValue(name, value)
    );

    if (checkboxOrderId.length > 0) {
      setShipOrdersModal(!shipOrdersModal);
    } else {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: "Please Select The Order First",
      });
    }
  };

  const onShipOrders = (data) => {
    // let data = {"order_ids":checkboxOrderId}
    data.order_ids = checkboxOrderId;
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id;
    } else {
      data.merchant_id = props.user[0].id;
      data.merchant_wallet_balance = props.user[0].wallet_balance;
    }
    if (checkboxOrderId.length > 0) {
      setLoader(true);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_CHANNEL_ORDER_SAVE_SHIP}`,
        data: data,
        headers: { token: window.btoa(baseString) },
      })
        .then((res) => {
          setLoader(false);
          if (res.data.error === false) {
            // setProductIdName(res?.data?.data)
            Swal.fire({
              icon: "success",
              text: res.data.msg,
              title: "Success",
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((okay) => {
              if (okay) {
                setShipOrdersModal(false);
                if (checkData(filterInput)) {
                  onCommonFilter();
                } else {
                  channelOrderListing();
                }
                // if(checkData(failedOrder.data)){
                //   channelOrderListing();
                // }else{
                // history.push("/shipments");
                // }
              }
            });
          } else {
            toggleWghtDmn();

            Swal.fire({
              icon: "error",
              html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th style="padding: 0.25rem 0;">Order No</th><th style="padding: 0.25rem 0;">Response</th></tr>
              ${res.data.data.map(
                (item, index) =>
                  '<tr style="font-size:0.8rem;"><td>' +
                  item.order_no +
                  "</td><td>" +
                  item.msg.map((data) => {
                    return data;
                  }) +
                  "</td></tr>"
              )}
              </tbody></Table>`,
              title: "Error",
            });
          }
        })
        .catch((err) => {
          setLoader(false);
          errorHandling(err, "");
        });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: "Please Select The Order First",
      });
    }
  };
  const toggleWghtDmn = () => {
    // console.log(checkboxOrderId, "checkboxOrderId");
    // if (checkboxOrderId.length > 0) {
    setWghtDmnModal(!wghtDmnModal);
    // } else {
    //   Swal.fire({
    //     icon: "warning",
    //     title: "Warning",
    //     text: "Please Select The Order First",
    //   });
    // }
  };
  const toggleAnalWghtDmn = () => {
    // console.log(checkboxOrderId, "checkboxOrderId");
    // if (checkboxOrderId.length > 0) {
    setAnalDmnModal(!analDmnModal);
    // } else {
    //   Swal.fire({
    //     icon: "warning",
    //     title: "Warning",
    //     text: "Please Select The Order First",
    //   });
    // }
  };
  const UTMChange = (e) => {
    setutmType(e.target.value);
    //channelOrderMarketingSummary()
  };
  const toggleMoveOrder = () => {
    if (checkboxOrderId.length > 0) {
      setAnotherActModal(!anotherActModal);
    } else {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: "Please Select The Order First",
      });
    }
  };
  const LinkedMerchantL = () => {
    setLoader(true);
    let mypost = {};
    let myget = {};
    mypost.admin_id = process.env.REACT_APP_ADMIN_ID;
    mypost.login_merchant_id = props.user[0].id;

    dispatch(mergeAccountList(mypost, myget))
      .then((response) => {
        setLoader(false);
        if (response.error === false) {
          setLinkedMerchant(response.data);
        }
      })
      .catch(function (response) {
        setLoader(false);
        errorHandling(response, "reload");
      });
  };
  const loginAccount = (id, comp_name, email) => {
    let data = { merchant_id: id };
    data.admin_id = process.env.REACT_APP_ADMIN_ID;
    axios
      .post("", data)
      .then((res) => {
        setLoader(false);
        if (res.data.error === false) {
        }
      })
      .catch((err) => {
        setLoader(false);
        errorHandling(err, "");
      });
  };
  const {
    register: wghtDmn,
    handleSubmit: WghtDmnSubmit,
    formState: { errors: errors2 },
    reset: resetWeight,
  } = useForm();
  const onWghtDmn = (data) => {
    // console.log(data,'dwe');
    setLoader(true);
    data.order_ids = checkboxOrderId;
    data.selectedProducts = selectedProducts;
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id;
    } else {
      data.merchant_id = props.user[0].id;
    }
    // console.log(data, "datsa");
    axios({
      method: "post",
      url: `${process.env.REACT_APP_CHANNEL_ORDER_WEIGHT_DIM_UPDATE}`,
      data: data,
      headers: { token: window.btoa(baseString) },
    })
      .then((res) => {
        setLoader(false);
        if (res.data.error === false) {
          Swal.fire({ icon: "success", text: res.data.msg, title: "Success" });
          resetWeight();
          setWghtDmnModal(false);
          setAnalDmnModal(false);
          setCheckboxOrderId([]);
          setCheckedAll(false);
          setChecked(false);
          setOverallChecked(false);
        } else {
          Swal.fire({ icon: "error", text: res.data.msg, title: "Error" });
        }
      })
      .catch((err) => {
        setLoader(false);
        // console.error(err);
      });
  };
  const toggleOverview = () => {
    setOverviewModal(!overviewModal);
  };
  const toggleMarketOverview = () => {
    setOverviewMarketModal(!overviewMarketModal);
  };
  // copy text
  const copied = (text) => {
    let decision = copyToClipBoard(text);
    decision.then(function (result) {
      if (result === true) {
        toast("Copied!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };
  let totalCod = 0;
  let totalPrepaid = 0;
  let totalCodPrepaid = 0;
  let brandorderSummary =
    checkData(channelSummary) &&
    checkData(channelSummary.data) &&
    Object.values(
      channelSummary.data.reduce((osi, item) => {
        let brandId = item.brand_id;
        if (!osi[brandId])
          osi[brandId] = {
            brand_id: brandId,
            email: item.email,
            brand_name: item.brand_name,
            cod: [],
            prepaid: [],
          };
        let codcount =
          checkUndeNullBlank(item.delivery_type_id) &&
          parseInt(item.delivery_type_id) === 1
            ? item.total
            : 0;
        let prepaidcount =
          checkUndeNullBlank(item.delivery_type_id) &&
          parseInt(item.delivery_type_id) === 3
            ? item.total
            : 0;
        osi[brandId].cod.push(codcount);
        osi[brandId].prepaid.push(prepaidcount);
        totalCod += codcount;
        totalPrepaid += prepaidcount;
        totalCodPrepaid = totalCod + totalPrepaid;
        return osi;
      }, {})
    );
  let incmarket = 0;
  let totalMarketOrders = 0;
  let MarketBrandorderSummary =
    checkData(channelMarketingSummary) &&
    checkData(channelMarketingSummary.data) &&
    Object.values(
      channelMarketingSummary.data.reduce((osi, item) => {
        let totalCount = item.total;
        let column_name = channelMarketingSummary.column_name;
        let value = item[column_name];

        if (Number(totalCount) > 0 && column_name != "") {
          totalMarketOrders += Number(totalCount) > 0 ? totalCount : 0;
          osi[incmarket] = { column_name: value, total: totalCount };
        }
        incmarket++;
        return osi;
      }, {})
    );
    

  const getRatingColor = (val) => {
    if (val < 3) return "red";
    else if (val < 4) return "#fd7e14";
    else if (val <= 5) return "#198754";
  };

  const {
    register: changeCourier,
    handleSubmit: changeCourierSubmit,
    formState: { errors: errors4 },
    reset: resetChangeCourier,
  } = useForm();
  const onChangeCourierSubmit = (data) => {
    setLoader(true);
    data.order_ids = checkboxOrderId;
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id;
    } else {
      data.merchant_id = props.user[0].id;
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_CHANNEL_COURIER_CHANGE}`,
      data: data,
      headers: { token: window.btoa(baseString) },
    })
      .then((res) => {
        setLoader(false);
        if (res.data.error === false) {
          Swal.fire({ icon: "success", text: res.data.msg, title: "Success" });
          setChangeCourierModal(false);
          if (checkData(filterInput)) {
            onCommonFilter();
          } else {
            channelOrderListing();
          }
          resetChangeCourier();
        } else {
          Swal.fire({ title: "Error", text: res.data.msg, icon: "error" });
        }
      })
      .catch((err) => {
        errorHandling(err, "");
        setLoader(false);
      });
  };

  const merchantTagDel = (cnl_id, tag, tagType) => {
    Swal.fire({
      html: '<h5 class="mb-0">Do you want to delete this tag?</h5>',
      showCancelButton: true,
      confirmButtonText: "Save",
      customClass: "confirm-swal-fortag-con",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoader(true);
        let data = { channel_order_id: cnl_id, tag: tag, tag_type: tagType };
        if (props.user[0].user_type === "Merchant") {
          data.merchant_id = props.user[0].id;
        }
        axios({
          method: "post",
          url: `${process.env.REACT_APP_CHANNEL_REMOVE_TAG}`,
          data: data,
          headers: { token: window.btoa(baseString) },
        })
          .then((res) => {
            setLoader(false);
            onCommonFilter(filterInput, { page: "1" });
          })
          .catch((err) => {
            setLoader(false);
            errorHandling(err, "");
          });
      }
    });
  };

  const handlePrice = () => {
    // if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
    //     formData.append("merchant_id", props.user[0].id);
    //     formData.append("admin_id", process.env.REACT_APP_ADMIN_ID);
    // }
    // formData.append("delivery_type_id", data.delivery_type_id);
    // formData.append("cod_value", checkUndeNullBlank(data.cod_value) && parseInt(data.delivery_type_id) === 1 ? data.cod_value : '');
    // formData.append("source_pincode", pickupLocation[pickupKey].pickup_pincode);
    // formData.append("destination_pincode", data.destination_pincode);
    //         let formData = new FormData();
    //         if (checkData(channelOrder.data)) {
    //           channelOrder.data.forEach((item, index) => {
    //             console.log('itemm',item)
    //              Assuming item.height_in_cms, item.breadth_in_cms, item.length_in_cms are individual values
    //             formData.append("weight_in_kgs", item.product_weight_in_kgs);
    //             formData.append("length_in_cms", item.length_in_cms);
    //             formData.append("breadth_in_cms", item.breadth_in_cms);
    //             formData.append("height_in_cms", item.height_in_cms);
    //           });
    //   console.log('formdata', formData);
    // }
    // formData.append("weight_in_kgs", data.weight_in_kgs);
    // formData.append("length_in_cms", data.length_in_cms);
    // formData.append("breadth_in_cms", data.breadth_in_cms);
    // formData.append("height_in_cms", data.height_in_cms);
    // formData.append("service_id", data.service_id);
    // axios({
    //     method: "post",
    //     url: `${process.env.REACT_APP_PRICE_CALCULATOR}`,
    //     data: formData,
    //     headers: { "token": window.btoa(baseString) }
    // })
    //     .then(function (response) {
    //         setLoader(false);
    //         if (response.data.error === false) {
    //             setCourierwithPrice(response.data.data)
    //             scrollTo();
    //             setNoData(false);
    //             if (response.data.data.length < 1) {
    //                 setNoData(true);
    //             }
    //         }
    //         if (response.data.error === true) {
    //             Swal.fire({
    //                 title: 'Error',
    //                 text: response.data.msg,
    //                 icon: 'error',
    //             })
    //         }
    //     })
    //     .catch(function (response) {
    //         setLoader(false);
    //         errorHandling(response, "")
    //     });
    //   useEffect(() => {
    //     setLoader(true);
    //     let data = {}
    //     data = { "order_id": orderId }
    //     axios({
    //         method: "post",
    //         url: `${process.env.REACT_APP_CHANNEL_ORDER_DETAIL}`,
    //         data: data,
    //         headers: { "token": window.btoa(baseString) }
    //     }).then((res) => {
    //         setLoader(false);
    //         if (res.data.error === false) {
    //             setOrderDetail(res.data.data.orderDetail);
    //             let multipleProducts=(res.data.data.orderDetail!==undefined && res.data.data.orderDetail.product_category!==undefined)?res.data.data.orderDetail.product_category:"";
    //             //console.log("multipleProducts",multipleProducts);
    //             if(multipleProducts!=="" && multipleProducts!=="NA" && multipleProducts!=="-"){
    //                 setmultipleProductDetails(JSON.parse(multipleProducts));
    //             }
    //         } else {
    //             Swal.fire({ icon: 'error', text: res.data.msg, title: 'Error' });
    //         }
    //     }).catch((err) => {
    //         setLoader(false);
    //         errorHandling(err, "")
    //     });
    //     statusLog();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])
    // };
    // calc api
    //   axios({
    //     method: "post",
    //     url: `${process.env.REACT_APP_PRICE_CALCULATOR}`,
    //     data: formData,
    //     headers: { "token": window.btoa(baseString) }
    // })
    //     .then(function (response) {
    //         setLoader(false);
    // if (response.data.error === false) {
    //     setCourierwithPrice(response.data.data)
    //     scrollTo();
    //     setNoData(false);
    //     if (response.data.data.length < 1) {
    //         setNoData(true);
    //     }
    // }
    // if (response.data.error === true) {
    //     Swal.fire({
    //         title: 'Error',
    //         text: response.data.msg,
    //         icon: 'error',
    //     })
  };
  // })
  // .catch(function (response) {
  //     // setLoader(false);
  //     // errorHandling(response, "")
  // });

  const [orderDetailss, setOrderDetailss] = useState([]);

  const orderDatafunction = (item) => {
    // console.log(item,'orderData')
    setOrderDetailss(item);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setZoneAndPrice({});
    setCourierwithPrice({});
    setSelectedPickupPoint("");
    setSelectedPickupPoint2("");
  };

  const handleInputChange = (e) => {
    setFormDataN((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSelectChange2 = (event) => {
    const selectedValue = event.target.value;
    setSelectedPickupPoint(selectedValue);
    // console.log('Selected Pickup Point:', selectedValue);
    // You can use the selectedValue as needed in your component logic
  };

  const handleSelectChange3 = (event) => {
    const selectedValue2 = event.target.value;
    setSelectedPickupPoint2(selectedValue2);

    // console.log('Selected Pickup Point2:', selectedValue2);
    // You can use the selectedValue as needed in your component logic
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedPickupPoint, setSelectedPickupPoint] = useState("");
  const [selectedPickupPoint2, setSelectedPickupPoint2] = useState("");
  const [zoneAndPrice, setZoneAndPrice] = useState({});
  const [courierwithPrice, setCourierwithPrice] = useState([]);
  const [noData, setNoData] = useState(false);
  const [selectedCourier, setSelectedCourier] = useState(null);
  const [selectedCourierPrice, setSelectedCourierPrice] = useState(null);
  const [aggregatedFilteredObjectData, setaggregatedFilteredObject] = useState(
    {}
  );
  const [zoneIdPrice, setZoneIdPrice] = useState({});

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const propertiesToInclude = [
      "height_in_cms",
      "breadth_in_cms",
      "length_in_cms",
      "pickup_pincode",
      "customer_pincode",
      "merchant_id",
      "admin_id",
      "delivery_type_id",
      "product_cod_value",
    ];

    // Create a single filtered object outside the loop
    const aggregatedFilteredObject = {
      admin_id: 1, // You may set the appropriate default value
      source_pincode: Number(selectedPickupPoint),
      service_id: Number(selectedPickupPoint2),
      weight_in_kgs: 0, // Initialize with a default value
      cod_value: 0, // Initialize with a default value
      destination_pincode: 0, // Initialize with a default value
    };

    // Iterate through channelOrder.data and update the aggregatedFilteredObject
    channelOrder?.data.forEach((item) => {
      console.log(item?.product_invoice_value,'itemmm')
      propertiesToInclude.forEach((key) => {
        if (item.hasOwnProperty(key)) {
          // Update the aggregatedFilteredObject
          aggregatedFilteredObject[key] = item[key];
        }
      });

      // You may want to adjust the logic for aggregating other properties
      aggregatedFilteredObject.weight_in_kgs += Number(
        item.product_weight_in_kgs
      );
      aggregatedFilteredObject.cod_value = Number(orderDetailss?.product_invoice_value);
      aggregatedFilteredObject.destination_pincode = Number(
        item.customer_pincode
      );
      aggregatedFilteredObject.breadth_in_cms = formDataN.breadth;
      aggregatedFilteredObject.height_in_cms = formDataN.height;
      aggregatedFilteredObject.length_in_cms = formDataN.length;
      aggregatedFilteredObject.weight_in_kgs = formDataN.weight;
    });

    // console.log('aggregatedFilteredObject', aggregatedFilteredObject);
    setaggregatedFilteredObject(aggregatedFilteredObject);
    // console.log(aggregatedFilteredObject,'agrrrWWW')

    // Make the API call with the aggregatedFilteredObject
    axios({
      method: "post",
      url: `${process.env.REACT_APP_PRICE_CALCULATOR}`,
      data: aggregatedFilteredObject,
      headers: { token: window.btoa(baseString) },
    })
      .then(function (res) {
        // alert(2);
        setLoader(true);
        if (res.data.error === false) {
          setLoader(false);
          // console.log(res.data.data, 'hehe');
          setCourierwithPrice(res.data.data);
          setNoData(false);
          if (res.data.data.length < 1) {
            // alert(3)
            setNoData(true);
          }
        }
        if (res.data.error === true) {
          Swal.fire({
            title: "Info",
            text: "Courier Not Found",
            icon: "info",
          });
        }
      })
      .catch(function (res) {
        setLoader(false);
        // errorHandling(res, 'hekj');
        Swal.fire({
          title: "Error",
          text: `Please Choose all Fields`,
          icon: "error",
        });
      });
  };

  // useEffect(()=>{
  //   handleFormSubmit()
  // },[])

  const onSubmitOrder = async () => {
    //console.log('Values:', formDataN.height, formDataN.breadth, formDataN.weight, formDataN.length);
    const data = orderDetailss;

    //console.log(data, 'dataa');
    if (data.service_id === "6") {
      data.movement_type_id = "2";
    } else {
      data.movement_type_id = "1";
    }
    if (
      props.user !== undefined &&
      props.user !== null &&
      props.user[0].user_type === "Merchant"
    ) {
      data.merchant_id = props.user[0].id;
      data.admin_id = process.env.REACT_APP_ADMIN_ID;
    } else {
      data.admin_id = props.user[0].id;
    }
    // console.log(data, 'dataas');
    //  console.log(data.product_invoice_value,data.product_qty,'jhdsmn')
    let inValue = data.product_qty * Number(data.product_invoice_value);
    // console.log('hidear',inValue)
    setLoader(true);

    if (checkData(selectedCourier)) {
      // console.log(courierwithPrice,'zonee');
      const serviceIdToAdd = aggregatedFilteredObjectData.service_id;

      // courierwithPrice.map((item)=>setZoneIdPrice(item.zone_category_id));
      //  const zoneIdToAdd=  zoneIdPrice;
      data.invoice_value = inValue;
      data.customer_emai = "";
      data.zone_category_id = selectedCourierPrice.zone_category_id;
      data.service_id = serviceIdToAdd;
      data.all_charges = selectedCourierPrice.all_charges;
      data.freight = selectedCourierPrice.all_prices;
      data.merchant_wallet_balance = parseFloat(props.user[0].wallet_balance);
      data.courier_id = selectedCourier;
      data.customer_type = 2;
      data.destination_pincode =
        aggregatedFilteredObjectData.destination_pincode;
      data.weight_in_kgs = aggregatedFilteredObjectData.weight_in_kgs;
      data.product_unit_price = data.product_invoice_value;
      data.breadth_in_cms = formDataN.breadth;
      data.height_in_cms = formDataN.height;
      data.length_in_cms = formDataN.length;
      data.weight_in_kgs = formDataN.weight;
      // data.zone_category_id=CourierwithPrice.zone_category_id;
      // console.log('hideard',data)
      if (parseInt(data.selectedCourier) === 47 && parseInt(inValue) > 20000) {
        setLoader(false);
        Swal.fire({
          title: "Error",
          text: `Invoice Value will be less than 20000`,
          icon: "error",
        });
      } else {
        // let diffrence_balance = (parseInt(zoneAndPrice.total_price) > 0 && parseInt(props.user[0].wallet_balance) > 0) ? (parseInt(zoneAndPrice.total_price) - parseInt(props.user[0].wallet_balance)) : 0;
        // if (checkData(props.user) && props.user[0].wallet_balance >= zoneAndPrice.total_price) {
        // data.invoice_value = inValue
        // data.merchant_wallet_balance = parseFloat(props.user[0].wallet_balance);
        // data.all_charges = zoneAndPrice.all_prices
        // data.freight = zoneAndPrice.total_price
        // data.zone_category_id = zoneAndPrice.zone_id
        // data.pickup_pincode = pickupLocation[pickupKey].pickup_pincode !== undefined ? pickupLocation[pickupKey].pickup_pincode : '';

        axios({
          method: "post",
          url: `${process.env.REACT_APP_CREATE_SINGEL_ORDER}`,
          data: data,
          headers: { token: window.btoa(baseString) },
        })
          .then(function (response) {
            setLoader(false);
            if (response.data.error === false) {
              // console.log('order-conf',data)
              Swal.fire({
                title: "Success",
                text: response.data.msg,
                icon: "success",
              });
              if (response.data.status === "assigned") {
                history.push(`/order-detail/${response.data.order_id}`);
              }
              if (response.data.status === "failed") {
                history.push(`/orders?o=failed`);
              }
              if (response.data.status === "processing") {
                history.push(`/orders?o=processing}`);
              }
            }
            if (response.data.error === true) {
              Swal.fire({
                title: "Error",
                text: response.data.msg,
                icon: "error",
                allowOutsideClick: false,
                allowEscapeKey: false,
              });
            }
          })
          .catch(function (response) {
            setLoader(false);
            errorHandling(response, "");
          });
        // }
        // else{
        //     setLoader(false);
        //     Swal.fire({title:'Error', text:`Please Recharge Your Wallet With Amount ${diffrence_balance.toFixed(2)}. Your Current Balance is  ${props.user[0].wallet_balance} `, icon:'error'})
        // }
      }
    } else {
      setLoader(false);
      Swal.fire({
        title: "Error",
        text: `Please Choose Couriers`,
        icon: "error",
      });
    }
  };

  // console.log(props, "propsww");

  // const allChannelOrders = props?.permission?.ChannelOrders && props?.permission?.ChannelOrders.includes('AllchannelOrders');
  // const allowAction = props?.permission?.ChannelOrders && props?.permission?.ChannelOrders.includes('Action');
  // const allowOverview = props?.permission?.ChannelOrders && props?.permission?.ChannelOrders.includes('Overview');

  // const getLsChannel=JSON.parse(localStorage.getItem('channel-settings'));

  // console.log(getLsChannel,'ho')

  // const IsTagFilter_active = getLsChannel[0] === "tag-filter-1"? true:false;
  // const Ismultiple_orders_active = getLsChannel[1] === "multiple-order-1"? true:false;
  // const Issuspicious_order_active = getLsChannel[2] === "suspicious-order-1"? true:false;
  const IsUser_Tags_Filterr_active =
    channelOrderTags?.userTags === 1 ? true : false;
  const IsSystem_Tags_Filter_active =
    channelOrderTags?.systemTags === 1 ? true : false;

    const download_active = 
    channelOrderTags?.download === 1 ? true : false;

  // const IsUser_Tags_Filterr_active=true;
  // const IsSystem_Tags_Filter_active=true;

  return (
    <div className="app-content content overflow-hidden">
      {showOverlay && (
        <div className="overlay-n">
          <div className="overlay-content">
            <h3 style={{ color: "#118deg", fontSize: "30px" }}>
              Contact Logistify Admin
            </h3>
            <img src={blurImage} className="blur-image" />
          </div>
        </div>
      )}
      {/* {loader && <div className="formLoader"><Spinner /></div>} */}
      {checkData(orderTracking) && <div className="overlay"></div>}
      <Row>
        <Col sm={12}>
          <div className={`${blockfeatures ? "overlay-0" : ""}`}></div>
          <Card className="p-0 my-3 channelOrder shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex justify-content-between flex-wrap">
                <CardTitle className="page-title">Channel Orders</CardTitle>
                <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                <div className="btn-text btn-light">
                    <span
                      onClick={toggleAnalWghtDmn}
                    >
                      Order Analytics
                    </span>
                  </div>
                  <div
                    className="btn-text btn-light"
                    onClick={() => {
                      toggleOverview();
                      channelOrderSummary();
                    }}
                  >
                    <span className="icon mr-2">
                      <FontAwesomeIcon icon={faChartBar} />
                    </span>
                    <span>Overview</span>
                  </div>
                  <div
                    className="btn-text btn-light"
                    onClick={() => {
                      toggleMarketOverview();
                      channelOrderMarketingSummary();
                    }}
                  >
                    <span className="icon mr-2">
                      <FontAwesomeIcon icon={faChartPie} />
                    </span>
                    <span>Marketing Overview</span>
                  </div>
                  <Link to="/sms-log" className="btn-text btn-light">
                    <span className="icon mr-2">
                      <FontAwesomeIcon icon={faComment} />
                    </span>
                    <span>SMS Log</span>
                  </Link>
                  {!blockfeatures && (
                    <>
                    {download_active && (
                      <>
                      {!showOverlay && (
                        <div
                          className="btn-text btn-light"
                          onClick={exportOrders}
                        >
                          <span className="icon mr-2">
                            <FontAwesomeIcon icon={faArrowDown} />
                          </span>
                          <span>Download</span>
                        </div>
                      )}
                      </>
                    )}
                    </>
                  )}
                  <UncontrolledDropdown className="d-inline-block">
                    <DropdownToggle caret className="btn-text btn-light">
                      Choose Action
                      <span className="ml-1">
                        <FontAwesomeIcon icon={faAngleDown} />
                      </span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <div
                        className="dropdown-item"
                        onClick={toggleChangeStatus}
                      >
                        Change Status
                      </div>
                      <div
                        className="dropdown-item"
                        onClick={changeCourierToggle}
                      >
                        Change Courier
                      </div>
                      <div className="dropdown-item" onClick={toggleShipOrders}>
                        Move to Upload Orders
                      </div>
                      <div className="dropdown-item" onClick={toggleWghtDmn}>
                        Upload Weight/Dimension
                      </div>
                      <div className="dropdown-item" onClick={toggleMoveOrder}>
                        Move to Another Account
                      </div>
                      {/* <div className="dropdown-item">price Check</div> */}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  {/* <div className="btn-text btn-light" onClick={toggleChangeStatus}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Choose Action</span></div> */}
                </div>
              </CardHeader>
              <Modal isOpen={changeCourierModal} toggle={changeCourierModal}>
                <ModalHeader toggle={changeCourierToggle}>
                  Change Courier
                </ModalHeader>
                <ModalBody>
                  <Form onSubmit={changeCourierSubmit(onChangeCourierSubmit)}>
                    <Row className="m-0 pb-1">
                      <Col sm={12}>
                        <FormGroup className="position-relative">
                          <span className="arrowdown">
                            <FontAwesomeIcon icon={faChevronDown} />
                          </span>
                          <select
                            {...changeCourier("courier_id", {
                              required: "Please Choose Courier",
                            })}
                            className="form-control-sm form-control border-right-0"
                          >
                            <option value="">Select Courier</option>
                            {/* {checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers) && props.state.shipmentMerchantCourier.couriers.map((item) => ( */}
                            {checkData(courierList) &&
                              courierList.map(
                                (item) =>
                                  item.status !== 0 && (
                                    <option value={item.courier_id}>
                                      {item.courierName}
                                    </option>
                                  )
                              )}
                          </select>
                          {errors4.courier_id && (
                            <span className="text-danger d-block error">
                              {errors4.courier_id.message}
                            </span>
                          )}
                        </FormGroup>
                      </Col>
                      <Col sm={12} className="text-right">
                        <Button className="btn ctm-btn btn-sm" color="primary">
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>
              <Modal isOpen={togglePriceModal} toggle={togglePriceModal}>
                <ModalHeader toggle={togglePriceOrder}>Check Price</ModalHeader>
                <ModalBody>
                  <div className="courier-card">
                    {checkData(channelOrder.data) ? (
                      channelOrder.data.map((item, index) => {
                        // const propertiesToInclude = [
                        //   'product_vol_weight_in_kgs',
                        //   'height_in_cms',
                        //   'breadth_in_cms',
                        //   'length_in_cms',
                        //   'pickup_pincode',
                        //   'customer_pincode',
                        //   'merchant_id',
                        //   'admin_id'
                        // ];

                        // const filteredObject = propertiesToInclude.reduce((result, key) => {
                        //   if (item.hasOwnProperty(key)) {
                        //     result[key] = item[key];
                        //   }
                        //   return result;
                        // }, {});

                        // console.log(filteredObject,'filterr');

                        //   axios({
                        //     method: "post",
                        //     url: `${process.env.REACT_APP_PRICE_CALCULATOR}`,
                        //     data: filteredObject,
                        //     headers: { "token": window.btoa(baseString) }
                        // })
                        //     .then(function (response) {
                        //         setLoader(false);
                        //         console.log(response);
                        //     })
                        //     .catch(function (response) {
                        //         setLoader(false);
                        //         errorHandling(response, "")
                        //     });

                        return (
                          <>
                            <p>
                              {item.length_in_cms}*{item.breadth_in_cms}*
                              {item.height_in_cms} |{" "}
                              {item.product_weight_in_kgs}
                            </p>
                          </>
                        );
                      })
                    ) : (
                      <p>not found</p>
                    )}
                  </div>
                  <Form>
                    <Row className="m-0 pb-1">
                      <Col sm={12}>
                        <FormGroup>
                          {/* <Label>Select Service<span className="text-danger">*</span></Label> */}
                          <div className="position-relative">
                            <span className="arrowdown">
                              <FontAwesomeIcon icon={faChevronDown} />
                            </span>
                            <Badge
                              color="success"
                              pill
                              className={`badge-70-text selected-option-badge }`}
                            >
                              <span className="small-check-ctm">
                                <FontAwesomeIcon icon={faCheck} />
                              </span>
                            </Badge>
                            <select
                              {...shipOrders("service", {
                                required: "Please Select Service",
                              })}
                              className="form-control-sm form-control choose-option"
                            >
                              <option value="">Select Service</option>
                              {Object.entries(service).map(([key, value]) => (
                                <option value={value}>{value}</option>
                              ))}
                            </select>
                          </div>
                          {errors1.service && (
                            <span className="text-danger d-block error">
                              {errors1.service.message}
                            </span>
                          )}
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <Label>
                            Select Pickup Point
                            <span className="text-danger">*</span>
                          </Label>
                          <div className="position-relative">
                            <span className="arrowdown">
                              <FontAwesomeIcon icon={faChevronDown} />
                            </span>
                            <Badge
                              color="success"
                              pill
                              className={`badge-70-text selected-option-badge }`}
                            >
                              <span className="small-check-ctm">
                                <FontAwesomeIcon icon={faCheck} />
                              </span>
                            </Badge>
                            <select
                              {...shipOrders("pickup_point_id", {
                                required: "Please Select Pickup Point",
                              })}
                              className="form-control-sm form-control choose-option"
                            >
                              {/* <option value="">Select Pickup Point</option> */}
                              {pickupLocation !== undefined &&
                              pickupLocation !== null &&
                              Object.entries(pickupLocation).length > 0
                                ? pickupLocation.map((item, index) => {
                                    [
                                      {
                                        name: "pickup_point_id",
                                        value: item.is_default === 1 && item.id,
                                      },
                                    ].forEach(({ name, value }) =>
                                      setValue(name, value)
                                    );
                                    return (
                                      <option data-id={index} value={item.id}>
                                        {item.contact_name}
                                      </option>
                                    );
                                  })
                                : ""}
                            </select>
                          </div>
                          {errors1.pickup_point_id && (
                            <span className="text-danger d-block error">
                              {errors1.pickup_point_id.message}
                            </span>
                          )}
                        </FormGroup>
                      </Col>

                      <Col sm={12} className="text-right">
                        <Button
                          onClick={handlePrice}
                          className="btn ctm-btn btn-sm"
                          color="primary"
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>
              <div className="mx-3">
                <Nav
                  tabs
                  className="mb-4 pt-2 border-bottom text-center flex-nowrap"
                >
                  {props.user[0].user_type === "Merchant" && (
                    <>
                      <NavItem className="">
                        <NavLink
                          className={`warning-ctm ${classnames({
                            active: activeTab === "in-process",
                          })}`}
                          onClick={() => {
                            toggle("in-process");
                          }}
                        >
                          <div className="">
                            <span className="tab-icon">
                              <FontAwesomeIcon icon={faSpinner} />
                            </span>
                            <span className="tab-text text-nowrap">
                              In Process
                            </span>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem className="">
                        <NavLink
                          className={`danger-ctm ${classnames({
                            active: activeTab === "cancelled",
                          })}`}
                          onClick={() => {
                            toggle("cancelled");
                          }}
                        >
                          <div>
                            <span className="tab-icon">
                              <FontAwesomeIcon icon={faTimes} />
                            </span>
                            <span className="tab-text text-nowrap">
                              Cancelled
                            </span>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem className="">
                        <NavLink
                          className={`success-ctm ${classnames({
                            active: activeTab === "shipped",
                          })}`}
                          onClick={() => {
                            toggle("shipped");
                          }}
                        >
                          <div>
                            <span className="tab-icon">
                              <FontAwesomeIcon icon={faTruck} />
                            </span>
                            <span className="tab-text text-nowrap">
                              Shipped
                            </span>
                          </div>
                        </NavLink>
                      </NavItem>
                    </>
                  )}
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "all" })}
                      onClick={() => {
                        toggle("all");
                      }}
                    >
                      <div>
                        <span className="tab-icon">
                          <FontAwesomeIcon icon={faDropbox} />
                        </span>
                        <span className="tab-text text-nowrap">All</span>
                      </div>
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
              <Form
                onSubmit={filterChannelOrderSubmit(onFilterchannelOrder)}
                className="cardFilter"
              >
                <Row className="mx-0 justify-content-start justify-content-lg-center mb-3 mb-lg-0">
                  <Col sm={6} md={5} lg={5} xl={4}>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="position-relative p-0">
                            <span className="arrowdown">
                              <FontAwesomeIcon icon={faChevronDown} />
                            </span>
                            <select
                              className="form-control-sm form-control rounded-right-0"
                              {...filter("search_field")}
                              onChange={(e) => {
                                if (
                                  e.target.selectedOptions[0].text !==
                                  "Search By"
                                ) {
                                  setSearchValue(
                                    "Enter " + e.target.selectedOptions[0].text
                                  );
                                } else {
                                  setSearchValue("");
                                }
                              }}
                              disabled={showOverlay}
                            >
                              <option value="">Search By</option>
                              <option value="customer_name">
                                Customer Name
                              </option>
                              <option value="customer_name">
                                Customer Pincode
                              </option>
                              <option value="customer_mobile">
                                Customer Mobile
                              </option>
                              <option value="customer_ip">Customer IP</option>
                              <option value="order_no">Order No</option>
                              <option value="product_name">Product Name</option>
                              <option value="ref_url">Ref URL or Tags</option>
                            </select>
                          </InputGroupText>
                        </InputGroupAddon>
                        <input
                          type="text"
                          {...filter("search_value")}
                          className="form-control-sm form-control"
                          placeholder={searchValue}
                          disabled={showOverlay}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={2}>
                    <FormGroup>
                      <span className="arrowdown">
                        <FontAwesomeIcon icon={faChevronDown} />
                      </span>
                      <select
                        {...filter("delivery_type_id")}
                        className={`form-control-sm form-control ${
                          checkData(filterWatch("delivery_type_id"))
                            ? "valid"
                            : ""
                        }`}
                        disabled={showOverlay}
                      >
                        <option value="">Delivery Type</option>
                        {Object.entries(delivery_type_id).map(
                          ([key, value]) => (
                            <option value={key}>{value}</option>
                          )
                        )}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={2} className="d-none">
                    <FormGroup>
                      <MultiSelect
                        options={opt_allLeadStatus}
                        value={leadStatus}
                        onChange={(e) => setLeadStatus(e)}
                        labelledBy="All Lead Status"
                        overrideStrings={allLeadStatusMultiSelectSettings}
                        disabled={showOverlay}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={2}>
                    <FormGroup>
                      <MultiSelect
                        options={opt_allBrand}
                        value={allBrand}
                        onChange={(e) => setAllBrand(e)}
                        labelledBy="All Brand"
                        overrideStrings={allBrandMultiSelectSettings}
                        className={checkData(allBrand) && "active"}
                        disabled={showOverlay}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={2}>
                    <FormGroup>
                      <MultiSelect
                        disabled={showOverlay}
                        options={opt_allTags}
                        value={allTags}
                        onChange={(e) => setAllTags(e)}
                        labelledBy="System Tags"
                        overrideStrings={allTagsMultiSelectSettings}
                        className={checkData(allTags) && "active"}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={2}>
                    <FormGroup>
                      <span className="arrowdown">
                        <FontAwesomeIcon icon={faChevronDown} />
                      </span>
                      <select
                        {...filter("is_user_tag")}
                        className={`form-control-sm form-control ${
                          checkData(filterWatch("is_user_tag")) ? "valid" : ""
                        }`}
                        disabled={showOverlay}
                      >
                        <option value="">User Tag</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col
                    sm={6}
                    md={3}
                    lg={3}
                    xl={2}
                    className="verify-dropDown verify-newChange"
                    style={{ width: "11%" }}
                  >
                    <FormGroup>
                      <span className="arrowdown">
                        <FontAwesomeIcon icon={faChevronDown} />
                      </span>
                      <select
                        {...filter("is_verify")}
                        className={`form-control-sm form-control ${
                          checkData(filterWatch("is_verify")) ? "valid" : ""
                        }`}
                        disabled={showOverlay}
                      >
                        <option value="">Mobile Verify</option>
                        <option value="1">Is Verified</option>
                        <option value="0">Not Verified</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col
                    sm={6}
                    md={3}
                    lg={3}
                    xl={2}
                    className="verify-newChange"
                    style={{ width: "12%" }}
                  >
                    <FormGroup>
                      <span className="arrowdown">
                        <FontAwesomeIcon icon={faChevronDown} />
                      </span>
                      <select
                        {...filter("weight")}
                        className={`form-control-sm form-control ${
                          checkData(filterWatch("weight")) ? "valid" : ""
                        }`}
                        disabled={showOverlay}
                      >
                        <option value="">Weight</option>
                        <option value="0,0.5">Below 0.5Kg</option>
                        <option value="0.5,1">0.5 Kg to 1Kg</option>
                        <option value="1,2">1Kg to 2Kg</option>
                        <option value="2,5">2Kg to 5Kg</option>
                        <option value="5,15">Above 5Kg</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col
                    sm={6}
                    md={3}
                    lg={3}
                    xl={2}
                    className="verify-newChange"
                    style={{ width: "12%" }}
                  >
                    <FormGroup>
                      <MultiSelect
                        options={opt_custRaing}
                        value={custRating}
                        onChange={(e) => setCustRating(e)}
                        labelledBy="Customer Rating"
                        overrideStrings={custRatingMultiSelectSettings}
                        className={checkData(custRating) && "active"}
                        disabled={showOverlay}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={2}>
                    <FormGroup>
                      <MultiSelect
                        options={states}
                        value={selectedStates}
                        onChange={handleStateSelectionChange}
                        labelledBy="Select States"
                        overrideStrings={{
                          selectSomeItems: "Select States",
                          allItemsAreSelected: "All states are selected",
                        }}
                        className={selectedStates.length > 0 ? "active" : ""}
                        disabled={showOverlay}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={3} className="d-none">
                    <FormGroup>
                      <input
                        {...filter("merchant_tags")}
                        type="text"
                        className="form-control-sm form-control"
                        placeholder="Filter your own tags comma seperated"
                        disabled={showOverlay}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <DateRangePicker
                        value={filterDate}
                        onChange={(date) => setFilterDate(date)}
                        format="dd-MM-yyyy"
                        showOneCalendar={props.oneCalendar}
                        placement="bottomStart"
                        ref={dateRangeRef}
                        change={onDateChange}
                        disabled={showOverlay}
                      />
                    </FormGroup>
                  </Col>
                  {/* <Col sm={6} md={3} lg={3} xl={2}>
                    <FormGroup>
                    <MultiSelect
                      options={productsList}
                      value={selectedProducts}
                      onChange={handleProductSelectionChange}
                      labelledBy="Select Products"
                      overrideStrings={{ selectSomeItems: 'Select Products', allItemsAreSelected: 'All products are selected' }}
                      className={selectedProducts.length > 0 ? 'active' : ''}
                    />
                    </FormGroup>
                  </Col> */}
                  <Col sm={6} md={3} lg={3} xl={2} className="marginscreen">
                    <Button
                      disabled={showOverlay}
                      style={{ whiteSpace: "nowrap" }}
                      onClick={toggleWghtDmn}
                    >
                      Update Weight/Dimension
                    </Button>
                  </Col>
                  <Col sm={3} className="btn-container">
                    <Button className="ctm-btn btn-sm" color="primary">
                      Search
                    </Button>
                    <Button
                      type="reset"
                      className="ctm-btn btn-sm mx-2"
                      color="primary"
                      outline
                      onClick={resetForm}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Form>
              <CardBody className="p-0" style={{ marginTop: "8px" }}>
                <div className="showing-count">
                  {checkData(channelOrder) &&
                  checkData(channelOrder.data) &&
                  checkUndeNullBlank(channelOrder.TotalRows) ? (
                    <>
                      <span className="small">
                        Showing {currentCount} to{" "}
                        {currentCount -
                          1 +
                          Object.entries(channelOrder.data).length}{" "}
                        of {channelOrder.TotalRows}
                      </span>
                      <span className="ml-2">
                        Total Values: {formattedTotalAmount}
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <Table
                  responsive
                  className="text-left custom-table channel-table"
                >
                  <thead>
                    <tr>
                      <th>
                        <div className="form-check2">
                          <input
                            name="cust-type form-check-input"
                            type="checkbox"
                            id="select_all"
                            checked={checkedAll}
                            onChange={(event) =>
                              selectAll(event.target.checked)
                            }
                            value="checkedall"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="select_all"
                          ></label>
                        </div>
                      </th>
                      <th className="text-nowrap">ORDER DETAILS</th>
                      <th className="text-nowrap">PRODUCT DETAILS</th>
                      <th className="text-nowrap">Customer Details </th>
                      <th className="text-nowrap">
                        STATUS/TAGS
                        <span className="d-none">
                          <Dropdown isOpen={dropdownOpen} toggle={tagToggle}>
                            <DropdownToggle caret>
                              <FontAwesomeIcon icon={faFilter} />
                            </DropdownToggle>
                            <DropdownMenu>
                              {dataList.map((data, key) => (
                                <FormGroup>
                                  <div className="form-check2 d-flex flex-wrap align-items-center">
                                    <input
                                      checked={tagChecked[key]}
                                      name={"check" + key}
                                      type="checkbox"
                                      id={data.id}
                                      onChange={(event) =>
                                        toggleTagsCheck(
                                          event,
                                          key,
                                          data.id,
                                          data.value
                                        )
                                      }
                                    />
                                    <Label className="mx-2 my-0" for={data.id}>
                                      {data.value}
                                    </Label>
                                  </div>
                                </FormGroup>
                              ))}
                              <DropdownItem divider />
                              <FormGroup>
                                <DropdownItem>
                                  <span className="mr-2">
                                    <Button
                                      onClick={clearAll}
                                      className="ctm-btn"
                                      color="primary"
                                    >
                                      Clear All
                                    </Button>
                                  </span>
                                  <span>
                                    <Button
                                      onClick={ApplyTagFIlter}
                                      className="ctm-btn"
                                      color="primary"
                                    >
                                      Apply
                                    </Button>
                                  </span>
                                </DropdownItem>
                              </FormGroup>
                            </DropdownMenu>
                          </Dropdown>
                        </span>
                      </th>
                      <th className="text-nowrap">UPLOADED/Ref. URL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkData(channelOrder.data) ? (
                      channelOrder.data.map((item, index) => {
                        if (item.system_tags[0] === ",") {
                          item.system_tags = item.system_tags.substring(1);
                        }
                        return (
                          <tr
                            key={index}
                            className={`${tempClass === item.id && "showTop"}`}
                          >
                            <td>
                              <div className="form-check2">
                                <input
                                  checked={
                                    checked !== undefined && checked[index]
                                  }
                                  name={"check" + index}
                                  type="checkbox"
                                  id={item.id}
                                  onChange={(event) =>
                                    toggleCheck(index, event, item.id)
                                  }
                                  disabled={overallchecked}
                                />
                                <label
                                  className="form-check-label"
                                  for={item.id}
                                ></label>
                              </div>
                            </td>
                            <td className="text-left">
                              <div
                                className={`text-nowrap ${
                                  blockfeatures ? "filter-blr-3" : ""
                                }`}
                              >
                                <span className="font-weight-500 fsc-4">
                                  ORDER NO. - <br />
                                </span>
                                <Link
                                  target="_balnk"
                                  to={`/channel-order-detail/${item.id}`}
                                >
                                  {item.order_no || "NA"}
                                </Link>
                                <span
                                  onClick={() => copied(item.order_no)}
                                  className="ml-1 copy-btn"
                                  id={`copyOdr${item.id}`}
                                >
                                  <Copy />
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`copyOdr${item.id}`}
                                  >
                                    Copy
                                  </UncontrolledTooltip>
                                </span>
                              </div>
                              <span className="font-weight-500 fsc-4">
                                BRAND - <br />
                              </span>
                              <span className="text-uppercase text-nowrap">
                                {item.merchantBrandName || "NA"}
                              </span>
                              <br />
                              <span className="font-weight-500 fsc-4">
                                PICKUP - <br />
                              </span>
                              <span className="text-uppercase text-nowrap">
                                {item.MerchantPickupPointName || "NA"}
                              </span>
                            </td>
                            <td>
                              <div
                                className="w-product"
                                id={`productTooltip${item.id}`}
                              >
                                {moreText(item.product_name)}
                              </div>
                              <UncontrolledTooltip
                                placement="top"
                                target={`productTooltip${item.id}`}
                              >
                                {item.product_name}
                              </UncontrolledTooltip>
                              <span className="font-weight-500 fsc-2">
                                MODE -
                              </span>{" "}
                              {delivery_type_id[item.delivery_type_id]}
                              {/* {item.product_cod_value > 0 &&
                                " ( " + item.product_cod_value + " ) "} */}
                              {item.product_invoice_value > 0 &&
                                " ( " + item.product_invoice_value + " ) "}
                              {/* {setInvoiceVal(item?.product_invoice_value)} */}
                              <br />
                              <span className="font-weight-500 fsc-2">
                                DIM | WEIGHT -
                              </span>{" "}
                              {item.length_in_cms}*{item.breadth_in_cms}*
                              {item.height_in_cms} |{" "}
                              {item.product_weight_in_kgs}
                            </td>
                            <td className="align-middle">
                              <span
                                className={`${
                                  blockfeatures ? "filter-blr-3" : ""
                                }`}
                              >
                                {item.customer_name}
                              </span>
                              <br />
                              <span
                                className={`${
                                  blockfeatures ? "filter-blr-3" : ""
                                }`}
                              >
                                {item.customer_mobile}{" "}
                              </span>
                              <div className="d-flex align-items-center">
                                <div>
                                  <StarRatings
                                    rating={item.customer_rating}
                                    starDimension="12px"
                                    starSpacing="1px"
                                    starRatedColor={getRatingColor(
                                      item.customer_rating
                                    )}
                                    numberOfStars={5}
                                  />
                                </div>
                                <Badge
                                  className="ml-2 cust-badge"
                                  color={`${
                                    item.customer_rating < 3
                                      ? "danger"
                                      : item.customer_rating < 4
                                      ? "warning"
                                      : item.customer_rating <= 5
                                      ? "success"
                                      : ""
                                  }`}
                                >
                                  {item.customer_rating}
                                </Badge>
                              </div>
                              {checkUndeNullBlank(item.is_verify) &&
                              parseInt(item.is_verify) === 1 ? (
                                <Badge
                                  color="success"
                                  className="bg-transparent badge-65-text"
                                >
                                  <span className="text-secondary">
                                    <FontAwesomeIcon icon={faComment} />{" "}
                                  </span>
                                  Verified
                                </Badge>
                              ) : parseInt(item.is_verify) === 0 ? (
                                <Badge
                                  color="danger"
                                  className="bg-transparent badge-65-text"
                                >
                                  <span className="text-secondary">
                                    <FontAwesomeIcon icon={faComment} />{" "}
                                  </span>
                                  Not Verified
                                </Badge>
                              ) : (
                                ""
                              )}
                              {checkUndeNullBlank(item.merchant_tags) && (
                                <>
                                  {" "}
                                  <br />{" "}
                                  <span>
                                    {[
                                      ...new Set(item.merchant_tags.split(",")),
                                    ].map((step, idx) => (
                                      <span
                                        id={`merchTag${item.id + idx + step}`}
                                        key={idx}
                                      >
                                        {/* {console.log(item.merchant_tags)} */}
                                        {IsUser_Tags_Filterr_active && (
                                          <Badge
                                            color="warning"
                                            pill
                                            className={`mt-2 mr-1 badge-70-text position-relative cursor-pointer ${
                                              step.trim() === "IVR Verified" ||
                                              step.trim() === "IVR Cancelled"
                                                ? "newTags"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              merchantTagDel(
                                                item.id,
                                                step,
                                                "merchant_tags"
                                              )
                                            }
                                            style={{
                                              backgroundColor:
                                                step.trim() === "IVR Verified"
                                                  ? "#2f8153"
                                                  : step.trim() ===
                                                    "IVR Cancelled"
                                                  ? "#d31e1e"
                                                  : undefined,
                                              color:
                                                step.trim() ===
                                                  "IVR Verified" ||
                                                step.trim() === "IVR Cancelled"
                                                  ? "red"
                                                  : undefined,
                                            }}
                                          >
                                            {step}
                                            {step !== "" && (
                                              <span className="close-Tag">
                                                <FontAwesomeIcon
                                                  icon={faTimes}
                                                />
                                              </span>
                                            )}
                                          </Badge>
                                        )}
                                      </span>
                                    ))}
                                  </span>
                                </>
                              )}
                              {item.is_verify === 2 ? (
                                <>
                                  <p className="mt-2 mb-0 d-flex align-items-center">
                                    <i
                                      style={{
                                        color: "green",
                                        fontSize: "19px",
                                        fontWeight: "bold",
                                        marginRight: "5px",
                                      }}
                                      className="fab fa-whatsapp"
                                    ></i>
                                    <Badge
                                      className="cust-badge"
                                      color="success"
                                    >
                                      <span style={{ fontSize: "12px" }}>
                                        Verified
                                      </span>
                                    </Badge>
                                  </p>
                                </>
                              ) : null}
                            </td>
                            <td>
                              <Badge
                                color={`${
                                  item.order_status_id === 2
                                    ? "danger"
                                    : item.order_status_id === 3
                                    ? "success"
                                    : "primary"
                                }`}
                                pill
                                onClick={() => {
                                  if (item.order_status_id === 3) {
                                    setSideAnim(true);
                                    orderTrack(
                                      item.awb_no,
                                      item.order_status_id
                                    );
                                    setStatusRecheckOrderId([item.id]);
                                    setTempClass(item.id);
                                  }
                                }}
                              >
                                {channel_order_status[item.order_status_id]}
                              </Badge>
                              <br />
                              {item.order_status_id === 3 && (
                                <div className="text-nowrap">
                                  <span className="font-weight-500 fsc-4">
                                    AWB No.-
                                  </span>
                                  <br />
                                  <Link
                                    target="_blank"
                                    to={`/order-detail/${item.awb_no}`}
                                  >
                                    {item.awb_no}
                                  </Link>
                                  <span
                                    onClick={() => copied(item.awb_no)}
                                    className="ml-1 copy-btn"
                                    id={`copyAwb${item.awb_no}`}
                                  >
                                    <Copy />
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      target={`copyAwb${item.awb_no}`}
                                    >
                                      Copy
                                    </UncontrolledTooltip>
                                  </span>
                                </div>
                              )}
                              {checkUndeNullBlank(item.system_tags) &&
                                item.system_tags.split(",").map((step) => (
                                  <>
                                    {IsSystem_Tags_Filter_active && (
                                      <Badge
                                        color={`${
                                          step === "Address Corrected" ||
                                          step === "Address Updated" ||
                                          step === "Need Urgent Delivery"
                                            ? "success"
                                            : "warning"
                                        }`}
                                        pill
                                        className={`mt-2 mr-1 badge-70-text ${
                                          blockfeatures ? "filter-blr-3" : ""
                                        }`}
                                      >
                                        {step}{" "}
                                        {(step === "Address Corrected" ||
                                          step === "Address Updated") && (
                                          <span className="small-check-ctm">
                                            <FontAwesomeIcon icon={faCheck} />
                                          </span>
                                        )}
                                        {"\n"}
                                      </Badge>
                                    )}
                                    <br />
                                  </>
                                ))}
                            </td>
                            <td>
                              <span className="text-nowrap mr-2">
                                {changeIntoDateTime(item.created_at)}
                              </span>
                              <hr className="p-0 m-0"></hr>
                              <div
                                className="w-product text-small"
                                id={`refTooltip${item.id}`}
                              >
                                {moreText(item.ref_url)}
                              </div>
                              <UncontrolledTooltip
                                placement="top"
                                target={`refTooltip${item.id}`}
                              >
                                {item.ref_url}
                              </UncontrolledTooltip>

                              {checkData(props.user) &&
                                props.user[0].user_type === "Merchant" && (
                                  <span>
                                    <Button
                                      className="ctm-btn btn-sm"
                                      color="primary"
                                      outline
                                      onClick={() =>
                                        toggleEdit(item.id, item.merchant_id)
                                      }
                                    >
                                      Edit
                                    </Button>
                                  </span>
                                )}
                              {checkData(props.user) &&
                                props.user[0].user_type === "Merchant" && (
                                  <>
                                    <span>
                                      <Button
                                        className="ml-2 ctm-btn btn-sm"
                                        color="primary"
                                        outline
                                        onClick={() => {
                                          // openModal();
                                          orderDatafunction(item);
                                          toggleEditOrder(
                                            item.id,
                                            item.merchant_id
                                          );
                                        }}
                                      >
                                        Ship Order
                                      </Button>
                                    </span>
                                  </>
                                )}
                            </td>
                          </tr>
                        );
                      })
                    ) : tableLoader ? (
                      <tr>
                        <td colSpan="6" className="text-center">
                          <LoaderDefault />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">
                          <NodataFound />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>

                <Modal
                  className="modal-courier"
                  isOpen={isModalOpen}
                  toggle={closeModal}
                >
                  <ModalHeader toggle={closeModal}>Price Check</ModalHeader>
                  <ModalBody>
                    <Form>
                      <FormGroup>
                        <Label>
                          Select Pickup Point
                          <span className="text-danger">*</span>
                        </Label>
                        <select
                          className="form-control-sm form-control choose-option"
                          value={selectedPickupPoint}
                          onChange={handleSelectChange2}
                        >
                          <option value="">Select Pickup Point</option>
                          {pickupLocation !== undefined &&
                          pickupLocation !== null &&
                          Object.entries(pickupLocation).length > 0
                            ? pickupLocation.map((item, index) => {
                                [
                                  {
                                    name: "pickup_point_id",
                                    value: item.is_default === 1 && item.id,
                                  },
                                ].forEach(({ name, value }) =>
                                  setValue(name, value)
                                );
                                return (
                                  <option
                                    data-id={index}
                                    value={item.pickup_pincode}
                                  >
                                    {item.contact_name}
                                  </option>
                                );
                              })
                            : ""}
                        </select>
                      </FormGroup>
                      <FormGroup>
                        <Label>Select Service</Label>
                        <select
                          className="form-control-sm form-control choose-option"
                          value={selectedPickupPoint2}
                          onChange={handleSelectChange3}
                        >
                          <option value="">Select Service</option>
                          {Object.entries(service).map(([key, value]) => (
                            <option value={key}>{value}</option>
                          ))}
                        </select>
                      </FormGroup>
                      <div className="courier-list" name="scroll-to-element">
                        {
                          <Row>
                            <div>
                              {checkData(courierwithPrice) &&
                              courierwithPrice ? (
                                <>
                                  <Label>Search Courier</Label>
                                  <input
                                    className="form-control mb-4"
                                    type="text"
                                    placeholder="Search courier"
                                    value={searchQuery}
                                    onChange={(e) =>
                                      setSearchQuery(e.target.value)
                                    }
                                  />

                                  <Label className="mr-2">Sort By Price</Label>
                                  <select
                                    value={sortOrder}
                                    onChange={handleSortOrderChange}
                                    className="form-select w-20 mb-4"
                                  >
                                    <option selected value="">
                                      Select
                                    </option>
                                    <option value="asc">Low to High</option>
                                    <option value="desc">High to Low</option>
                                  </select>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                            {loader ? (
                              <Col
                                md={12}
                                className="d-flex justify-content-center"
                              >
                                <p>no result found</p>
                              </Col>
                            ) : (
                              checkData(courierwithPrice) &&
                              courierwithPrice
                                .filter((item) => {
                                  return item.courier_name
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase());
                                })
                                .sort((a, b) => {
                                  if (sortOrder === "asc") {
                                    return a?.price?.total - b?.price?.total;
                                  }
                                  if (sortOrder === "desc") {
                                    return b?.price?.total - a?.price?.total;
                                  } else {
                                    return 0;
                                  }
                                })
                                ?.map((item, index) => (
                                  <Col md={6} lg={6} xl={6}>
                                    <div className="courier-box mb-4">
                                      <div>
                                        <input
                                          type="radio"
                                          disabled={
                                            checkData(item.price) &&
                                            checkUndeNullBlank(
                                              item.price.total
                                            ) &&
                                            item.price.total >= 0
                                              ? false
                                              : true
                                          }
                                          value={item.courier_id}
                                          id={item.courier_id}
                                          checked={
                                            selectedCourier === item.courier_id
                                          }
                                          onChange={() => {
                                            setSelectedCourier(item.courier_id);
                                            setSelectedCourierPrice({
                                              all_charges: item?.price,
                                              all_prices: item?.price?.total,
                                              zone_category_id:
                                                item?.zone_category_id,
                                            });
                                          }}
                                        />
                                        <label
                                          className="form-check-label d-flex align-items-center"
                                          htmlFor={item?.courier_id}
                                        >
                                          <div className="icon-con">
                                            <span className="icon d-flex align-items-center justify-content-center mr-3">
                                              <FontAwesomeIcon icon={faCheck} />
                                            </span>
                                          </div>
                                          <Card className="mb-0 shadow">
                                            <CardBody className="d-flex align-items-center">
                                              <div className="courier-img">
                                                <img
                                                  src={
                                                    process.env
                                                      .REACT_APP_IMAGE_COURIER +
                                                    item?.courier_img
                                                  }
                                                  alt="courier img"
                                                  className="courier_img img-fluid"
                                                  width="90"
                                                />
                                              </div>
                                              <div className="mx-3">
                                                <CardTitle tag="h6">
                                                  {item.courier_name}
                                                </CardTitle>
                                                <div>
                                                  <span>Charged Weight :</span>
                                                  <span>
                                                    {item?.charged_weight_in_kgs ||
                                                      "NA"}
                                                  </span>
                                                </div>
                                                <div>
                                                  <span>TAT :</span>
                                                  <span>
                                                    {item?.tat_in_days || "NA"}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="courier_price">
                                                <CardTitle tag="h6">
                                                  ₹
                                                  {checkData(item?.price) &&
                                                  checkUndeNullBlank(
                                                    item?.price?.total
                                                  )
                                                    ? item?.price?.total?.toFixed(
                                                        2
                                                      )
                                                    : "NA"}
                                                </CardTitle>
                                              </div>
                                            </CardBody>
                                          </Card>
                                        </label>
                                      </div>
                                    </div>
                                  </Col>
                                ))
                            )}
                          </Row>
                        }
                      </div>
                      {courierwithPrice.length > 0 ? null : (
                        <Button
                          onClick={handleFormSubmit}
                          type="submit"
                          color="primary"
                        >
                          Check Price
                        </Button>
                      )}
                      {courierwithPrice.length > 0 && (
                        <p
                          className="btn bg-success mb-0 ml-4 text-light"
                          onClick={onSubmitOrder}
                          type="submit"
                          color="primary"
                        >
                          Place Order
                        </p>
                      )}
                    </Form>
                  </ModalBody>
                </Modal>

                {checkData(channelOrder) &&
                checkData(channelOrder.data) &&
                checkUndeNullBlank(channelOrder.TotalRows) ? (
                  <>
                    {channelOrder.TotalRows > dropFilterCount ? (
                      <Row className="m-0 table-footer">
                        <Col xs="6">
                          <Pagination aria-label="Page navigation example align-items-center">
                            <PaginationItem className="prev">
                              <PaginationLink
                                previous
                                to="#"
                                onClick={previousPageData}
                                className="rounded-circle d-flex justify-content-center align-items-center"
                              >
                                <ChevronLeft />
                              </PaginationLink>
                            </PaginationItem>
                            <PaginationItem className="next ml-3">
                              <PaginationLink
                                next
                                to="#"
                                onClick={nextPageData}
                                className="rounded-circle d-flex justify-content-center align-items-center"
                              >
                                <ChevronRight />
                              </PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </Col>
                        <Col xs="6">
                          <Form>
                            <FormGroup>
                              <span className="arrowdown">
                                <FontAwesomeIcon icon={faChevronDown} />
                              </span>
                              <select
                                type="select"
                                name="select"
                                value={selectperpage}
                                onChange={handleSelectChange}
                                id="exampleSelect"
                                className="form-control-sm form-control"
                              >
                                <option
                                  value={selectperpage}
                                  disabled
                                  className="border-bottom"
                                >
                                  {selectperpage}
                                </option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                              </select>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          </Card>
        </Col>
      </Row>
      {checkData(orderDetail) && (
        <Modal
          isOpen={editmodal}
          toggle={editmodal}
          scrollable={true}
          size="xl"
        >
          <ModalHeader toggle={toggleEditClose}>Edit Order</ModalHeader>
          <ModalBody>
            <EditOrder
              orderDetails={orderDetail}
              merchantId={merchantId}
              setEditmodal={setEditmodal}
              user={props.user}
              channelOrderListing={channelOrderListing}
            />
          </ModalBody>
        </Modal>
      )}
      {checkData(orderDetail) && (
        <Modal
          isOpen={editmodalOrder}
          toggle={toggleEditOrderClose}
          scrollable={true}
          size="xl"
        >
          <ModalHeader toggle={toggleEditOrderClose}>
            Ship Single Order
          </ModalHeader>
          <ModalBody>
            <EditSidebar
              setOrderDetailDate={setOrderDetailDate}
              orderDetailDate={orderDetailDate}
              setFormDataN={setFormDataN}
              formDataN={formDataN}
              handleInputChange={handleInputChange}
              setOrderUpdate={setOrderUpdate}
              ChannelOrder={channelOrder}
              // orderDetails={orderDetail}
              merchantId={merchantId}
              setEditOrdermodal={setEditmodalOrder}
              user={props.user}
              channelOrderListing={channelOrderListing}
              selectedPickupPoint={selectedPickupPoint}
              handleSelectChange2={handleSelectChange2}
              pickupLocation={pickupLocation}
              setValue={setValue}
              service={service}
              handleSelectChange3={handleSelectChange3}
              selectedPickupPoint2={selectedPickupPoint2}
              courierwithPrice={courierwithPrice}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              sortOrder={sortOrder}
              handleSortOrderChange={handleSortOrderChange}
              selectedCourier={selectedCourier}
              setSelectedCourier={setSelectedCourier}
              setSelectedCourierPrice={setSelectedCourierPrice}
              faCheck={faCheck}
              onSubmitOrder={onSubmitOrder}
              handleFormSubmit={handleFormSubmit}
              pickupDefault={pickupDefault}
              setChannelOrder={setChannelOrder}
              setTableLoader={setTableLoader}
              // toggleEditOrderOpen={toggleEditOrderOpen}
            />
          </ModalBody>
        </Modal>
      )}
      <Modal
        isOpen={changeStatusModal}
        toggle={changeStatusModal}
        scrollable={true}
        size="md"
      >
        <ModalHeader toggle={closeChangeStatus}>Change Status</ModalHeader>
        <ModalBody>
          <Form onSubmit={changeStatusSubmit(onChangeStatus)} className="">
            <Row className="mx-0 pt-1 mb-3 mb-xl-0 justify-content-start justify-content-lg-center">
              <Col sm={12}>
                <FormGroup>
                  <Label>Select Status</Label>
                  <div className="position-relative">
                    <span className="arrowdown">
                      <FontAwesomeIcon icon={faChevronDown} />
                    </span>
                    <select
                      {...changeStatus("order_status_id")}
                      className="form-control-sm form-control"
                    >
                      <option value="">Select Status</option>
                      {Object.entries(channel_order_status).map(
                        ([key, value]) => (
                          <option value={key}>
                            {value} {value === "Cancel" && "Order"}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </FormGroup>
              </Col>
              <Col sm={12}>
                <FormGroup>
                  <Label>Remarks</Label>
                  <textarea
                    {...changeStatus("remarks")}
                    className="form-control-sm form-control"
                    placeholder="Enter Remarks"
                  />
                </FormGroup>
              </Col>
              <Col sm={12} className="text-right">
                <Button className="ctm-btn btn-sm" color="primary">
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={shipOrdersModal}
        toggle={toggleShipOrders}
        scrollable={true}
        size="md"
      >
        <ModalHeader toggle={toggleShipOrdersClose}>
          Select Courier/Pickup Point
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={shipOrdersSubmit(onShipOrders)}
            className="cardFilter"
          >
            <Row className="mx-0 pt-1 mb-3 mb-xl-0 justify-content-start justify-content-lg-center">
              <Col sm={12}>
                <FormGroup>
                  {/* <Label>Select Service<span className="text-danger">*</span></Label> */}
                  <div className="position-relative">
                    <span className="arrowdown">
                      <FontAwesomeIcon icon={faChevronDown} />
                    </span>
                    <Badge
                      color="success"
                      pill
                      className={`badge-70-text selected-option-badge }`}
                    >
                      <span className="small-check-ctm">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    </Badge>
                    <select
                      {...shipOrders("service", {
                        required: "Please Select Service",
                      })}
                      className="form-control-sm form-control choose-option"
                    >
                      <option value="">Select Service</option>
                      {Object.entries(service).map(([key, value]) => (
                        <option value={value}>{value}</option>
                      ))}
                    </select>
                  </div>
                  {errors1.service && (
                    <span className="text-danger d-block error">
                      {errors1.service.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>Select Courier </Label>
                  <div className="position-relative">
                    <span className="arrowdown">
                      <FontAwesomeIcon icon={faChevronDown} />
                    </span>
                    <select
                      {...shipOrders("courier_id")}
                      className="form-control-sm form-control"
                    >
                      <option value="">Select Courier</option>
                      {checkData(courierList) &&
                        courierList.map(
                          (item) =>
                            item.status !== 0 && (
                              <option value={item.courier_id}>
                                {item.courierName}
                              </option>
                            )
                        )}
                    </select>
                  </div>
                  {errors1.courier_id && (
                    <span className="text-danger d-block error">
                      {errors1.courier_id.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>
                    Select Pickup Point<span className="text-danger">*</span>
                  </Label>
                  <div className="position-relative">
                    <span className="arrowdown">
                      <FontAwesomeIcon icon={faChevronDown} />
                    </span>
                    <Badge
                      color="success"
                      pill
                      className={`badge-70-text selected-option-badge }`}
                    >
                      <span className="small-check-ctm">
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    </Badge>
                    <select
                      {...shipOrders("pickup_point_id", {
                        required: "Please Select Pickup Point",
                      })}
                      className="form-control-sm form-control choose-option"
                    >
                      {/* <option value="">Select Pickup Point</option> */}
                      {pickupLocation !== undefined &&
                      pickupLocation !== null &&
                      Object.entries(pickupLocation).length > 0
                        ? pickupLocation.map((item, index) => {
                            [
                              {
                                name: "pickup_point_id",
                                value: item.is_default === 1 && item.id,
                              },
                            ].forEach(({ name, value }) =>
                              setValue(name, value)
                            );
                            return (
                              <option data-id={index} value={item.id}>
                                {item.contact_name}
                              </option>
                            );
                          })
                        : ""}
                    </select>
                  </div>
                  {errors1.pickup_point_id && (
                    <span className="text-danger d-block error">
                      {errors1.pickup_point_id.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col sm={12} className="text-right">
                <Button className="ctm-btn btn-sm" color="primary">
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={wghtDmnModal}
        toggle={toggleWghtDmn}
        scrollable={true}
        size="lg"
      >
        <ModalHeader toggle={toggleWghtDmn}>
          Upload Weight and Dimenstion
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={WghtDmnSubmit(onWghtDmn)} className="">
            <Col sm={6} md={6} lg={6} xl={6}>
              <FormGroup>
                <MultiSelect
                  {...wghtDmn("product-name")}
                  options={productsList}
                  value={selectedProducts}
                  onChange={handleProductSelectionChange}
                  labelledBy="Select Products"
                  overrideStrings={{
                    selectSomeItems: "Select Products",
                    allItemsAreSelected: "All products are selected",
                  }}
                  className={selectedProducts.length > 0 ? "active" : ""}
                />
              </FormGroup>
            </Col>
            <Row className="mx-0 pt-1 mb-3 mb-xl-0 justify-content-start justify-content-lg-center">
              <Col sm={4}>
                <FormGroup>
                  <Label>
                    Enter Weight IN KGS.<span className="text-danger">*</span>{" "}
                    <small>eg.0.5</small>
                  </Label>
                  <input
                    {...wghtDmn("weight_in_kgs", {
                      required: "Please Enter Weight",
                      pattern: {
                        value: /^[+-]?\d+(\.\d+)?$/,
                        message: "Please Enter Valid Weight",
                      },
                    })}
                    className="form-control-sm form-control"
                  />
                  {errors2.weight_in_kgs && (
                    <span className="text-danger d-block error">
                      {errors2.weight_in_kgs.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col sm={8}>
                <FormGroup>
                  <Label>
                    Dimension<span className="text-danger">*</span>
                  </Label>
                  <div className="d-flex">
                    <div>
                      <div className="d-flex align-items-center">
                        <div className="mx-2">
                          <Label>L</Label>
                        </div>
                        <div>
                          <input
                            {...wghtDmn("length_in_cms", {
                              required: "Please Enter Length",
                              pattern: {
                                value: /^[+-]?\d+(\.\d+)?$/,
                                message: "Please Enter Valid Length",
                              },
                            })}
                            className="form-control-sm form-control"
                          />
                        </div>
                      </div>
                      {errors2.length_in_cms && (
                        <span className="text-danger d-block error">
                          {errors2.length_in_cms.message}
                        </span>
                      )}
                    </div>
                    <div>
                      <div className="d-flex align-items-center">
                        <div className="mx-2">
                          <Label>B</Label>
                        </div>
                        <div>
                          <input
                            {...wghtDmn("breadth_in_cms", {
                              required: "Please Enter Breadth ",
                              pattern: {
                                value: /^[+-]?\d+(\.\d+)?$/,
                                message: "Please Enter Valid Breadth ",
                              },
                            })}
                            className="form-control-sm form-control"
                          />
                        </div>
                      </div>
                      {errors2.breadth_in_cms && (
                        <span className="text-danger d-block error">
                          {errors2.breadth_in_cms.message}
                        </span>
                      )}
                    </div>
                    <div>
                      <div className="d-flex align-items-center">
                        <div className="mx-2">
                          <Label>H</Label>
                        </div>
                        <div>
                          <input
                            {...wghtDmn("height_in_cms", {
                              required: "Please Enter Height",
                              pattern: {
                                value: /^[+-]?\d+(\.\d+)?$/,
                                message: "Please Enter Valid Height ",
                              },
                            })}
                            className="form-control-sm form-control"
                          />
                        </div>
                      </div>
                      {errors2.height_in_cms && (
                        <span className="text-danger d-block error">
                          {errors2.height_in_cms.message}
                        </span>
                      )}
                    </div>
                  </div>
                </FormGroup>
              </Col>
              <Col sm={12} className="text-right">
                <Button className="ctm-btn btn-sm" color="primary">
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={analDmnModal}
        toggle={toggleAnalWghtDmn}
        scrollable={true}
        size="lg"
      >
        <ModalHeader toggle={toggleAnalWghtDmn}>Orders Analytics</ModalHeader>
        <ModalBody>
          <FormGroup className="mb-sm-0 col-6 float-right">
            <DateRangePicker
              value={filterDateOverviewNew}
              onChange={(date) => setFilterDateOverviewNew(date)}
              placement="bottomStart"
              showOneCalendar={props.oneCalendar}
              placeholder="Choose Date Range"
              format="dd-MM-yyyy"
            />
          </FormGroup>

          <Bar
            data={{
              labels: merchantTagsKey,
              datasets: [
                {
                  label: "merchant_tags",
                  data: merchantTagsValue,
                  backgroundColor: ["rgb(27, 90, 143)"],
                  borderColor: ["aqua"],
                  borderWidth: 0.5,
                },
              ],
            }}
            // Height of graph
            height={400}
            options={{
              maintainAspectRatio: false,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      // The y-axis value will start from zero
                      beginAtZero: true,
                    },
                  },
                ],
              },
              legend: {
                labels: {
                  fontSize: 8,
                },
              },
            }}
          />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={anotherActModal}
        toggle={toggleMoveOrder}
        scrollable={true}
      >
        <ModalHeader toggle={toggleMoveOrder}>
          Move To Another Account
        </ModalHeader>
        <ModalBody>
          {checkData(linkedMerchant) ? (
            linkedMerchant.map((item, index) => (
              <>
                <ListGroup className="another-act-list">
                  <ListGroupItem>
                    <div className="d-flex align-items-center">
                      <div className="avatar d-flex justify-content-center align-items-center position-relative">
                        <span className="rounded-circle avatar-name">
                          {getFirstLetter(item.company_name)}
                        </span>
                        <span
                          className={`avatar-status-online ${
                            item.status !== 0 ? "bg-success" : "bg-danger"
                          }`}
                        ></span>
                      </div>
                      <div
                        style={{ width: "calc(100% - 40px)" }}
                        className="list-item pl-2"
                        onClick={() => {
                          if (item.status === 1) {
                            loginAccount(
                              item.id,
                              item.company_name,
                              item.email
                            );
                          }
                        }}
                      >
                        <span>{item.company_name}</span>
                        <small> ({item.id})</small> <br />
                        <span>({item.email})</span>
                      </div>
                    </div>
                  </ListGroupItem>
                </ListGroup>
              </>
            ))
          ) : tableLoader ? (
            <div>
              <LoaderDefault />
            </div>
          ) : (
            <div>
              <NodataFound />
            </div>
          )}
        </ModalBody>
      </Modal>

      <Modal
        isOpen={overviewModal}
        toggle={toggleOverview}
        scrollable={true}
        size="lg"
      >
        <ModalHeader toggle={toggleOverview}>
          Channel Order Overview
        </ModalHeader>
        <ModalBody>
          <Form className="cardFilter">
            <Row className="justify-content-start mb-3">
              <Col sm={6} md={4} lg={3} xl={4}>
                <FormGroup className="mb-sm-0">
                  <DateRangePicker
                    value={filterDateOverview}
                    onChange={(date) => setFilterDateOverview(date)}
                    placement="bottomStart"
                    showOneCalendar={props.oneCalendar}
                    placeholder="Choose Date Range"
                    format="dd-MM-yyyy"
                  />
                </FormGroup>
              </Col>
              <Col sm={3} className="btn-container">
                <Button
                  className="btn btn-sm"
                  color="primary"
                  onClick={() => channelOrderSummary()}
                >
                  Search
                </Button>
                <Button
                  type="reset"
                  className="btn ctm-btn btn-sm mx-2"
                  color="primary"
                  outline
                  onClick={() => channelOrderSummary("reset")}
                >
                  Reset
                </Button>
              </Col>
            </Row>
          </Form>
          <div>
            <Table responsive className="text-center custom-table mb-0">
              <thead>
                <tr>
                  <th></th>
                  <th>COD</th>
                  <th>Prepaid</th>
                  <th>Total</th>
                </tr>
                <tr>
                  <th>Total</th>
                  <th className="text-nowrap">{totalCod}</th>
                  <th className="text-nowrap">{totalPrepaid}</th>
                  <th className="text-nowrap">{totalCodPrepaid} </th>
                </tr>
              </thead>
              <tbody>
                {checkData(brandorderSummary)
                  ? brandorderSummary.map((item, index) => {
                      let codCount = checkUndeNullBlank(item.cod)
                        ? arrayValueSum(item.cod)
                        : 0;
                      let prepaidCount = checkUndeNullBlank(item.prepaid)
                        ? arrayValueSum(item.prepaid)
                        : 0;
                      let total = codCount + prepaidCount;
                      return (
                        <tr>
                          <td>
                            <strong>{item.brand_name}</strong>
                            <br></br>
                            <small> {item.email}</small>
                          </td>
                          <td className="text-nowrap">{codCount}</td>
                          <td className="text-nowrap">{prepaidCount}</td>
                          <td className="text-nowrap">{total}</td>
                        </tr>
                      );
                    })
                  : ""}
              </tbody>
            </Table>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={overviewMarketModal}
        toggle={toggleMarketOverview}
        scrollable={true}
        size="lg"
      >
        <ModalHeader toggle={toggleMarketOverview}>
          Channel Order Marketing Overview
        </ModalHeader>
        <ModalBody>
          <Form className="cardFilter">
            <Row className="justify-content-start mb-3">
              <Col sm={6} md={4} lg={3} xl={4}>
                <FormGroup className="mb-sm-0">
                  <DateRangePicker
                    value={filterDateOverview}
                    onChange={(date) => setFilterDateOverview(date)}
                    placement="bottomStart"
                    showOneCalendar={props.oneCalendar}
                    placeholder="Choose Date Range"
                    format="dd-MM-yyyy"
                  />
                </FormGroup>
              </Col>

              <Col sm={6} md={3} lg={3} xl={2}>
                <FormGroup>
                  <span className="arrowdown">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </span>
                  <select
                    {...filter("column_name")}
                    onChange={(e) => UTMChange(e)}
                    className={`form-control-sm form-control ${
                      checkData(filterWatch("column_name")) ? "valid" : ""
                    }`}
                  >
                    <option value="utm_campaign">UTM Campaign</option>
                    <option value="utm_medium">UTM Medium</option>
                    <option value="utm_source">UTM Source</option>
                    <option value="utm_content">UTM Content</option>
                    <option value="utm_keyword">UTM Keywords</option>
                    <option value="utm_id">UTM ID</option>
                  </select>
                </FormGroup>
              </Col>

              <Col sm={6} md={4} lg={4} xl={4}>
                <FormGroup>
                  <MultiSelect
                    options={opt_allBrand}
                    value={allBrand}
                    onChange={(e) => setAllBrand(e)}
                    labelledBy="All Brand"
                    overrideStrings={allBrandMultiSelectSettings}
                    className={checkData(allBrand) && "active"}
                  />
                </FormGroup>
              </Col>

              <Col sm={3} className="btn-container">
                <Button
                  className="btn btn-sm"
                  color="primary"
                  onClick={() => channelOrderMarketingSummary()}
                >
                  Search
                </Button>
                <Button
                  type="reset"
                  className="btn ctm-btn btn-sm mx-2"
                  color="primary"
                  outline
                  onClick={() => channelOrderMarketingSummary("reset")}
                >
                  Reset
                </Button>
              </Col>
            </Row>
          </Form>
          <div>
            <Table responsive className="text-center custom-table mb-0">
              <thead>
                <tr>
                  <th>Value</th>
                  <th>Count</th>
                </tr>
                <tr>
                  <td>
                    <strong>Total</strong>
                  </td>
                  <td>
                    <strong> {totalMarketOrders}</strong>
                  </td>
                </tr>
              </thead>
              <tbody>
                {checkData(MarketBrandorderSummary)
                  ? MarketBrandorderSummary.map((item, index) => {
                      let column_name = checkUndeNullBlank(item.column_name)
                        ? item.column_name
                        : "NA";
                      let count = checkUndeNullBlank(item.total)
                        ? item.total
                        : 0;
                      return (
                        <tr>
                          <td>{column_name}</td>
                          <td className="text-nowrap">{count}</td>
                        </tr>
                      );
                    })
                  : ""}
              </tbody>
            </Table>
          </div>
         <div>
         <Bar
            data={{
              labels: labels,
              datasets: [
                {
                  label: "Data",
                  data:totalCounts,
                  backgroundColor: ["rgb(27, 90, 143)"],
                  borderColor: ["aqua"],
                  borderWidth: 0.5,
                },
              ],
            }}
            // Height of graph
            height={400}
            options={{
              maintainAspectRatio: false,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      // The y-axis value will start from zero
                      beginAtZero: true,
                    },
                  },
                ],
              },
              legend: {
                labels: {
                  fontSize: 8,
                },
              },
            }}
          />
         </div>
          
          
        </ModalBody>
      </Modal>

      {checkData(orderTracking) && (
        <TrackOrder
          orderTracking={orderTracking}
          toggleTrack={sideAnim ? "show" : "hide"}
          closeTracker={(e) => {
            closeTracker(e);
          }}
          position="fixed"
          lastStatus={lastOrderStatus}
          statusRecheckOrderId={statusRecheckOrderId}
          user={props.user}
        />
      )}
    </div>
  );
};
function mapDispatchToProps(state) {
  return {
    state: state.DataReducer,
  };
}
export default connect(mapDispatchToProps)(ChannelOrder);
