import React, { useEffect, useState } from 'react';
import { Card, Label, FormGroup, CardHeader, CardTitle, Button, CardBody, Row, Col, Form, Table } from 'reactstrap';
import { DateRangePicker } from 'rsuite';
import { Link, useHistory } from 'react-router-dom';
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import { checkData, checkUndeNullBlank } from '../../../../ObjectExist';
import { delivered_order_status, rto_order_status, failed_order_status, baseString,pending_order_status } from '../../../../config';
import { useForm } from "react-hook-form";
import moment from 'moment';
import { arrayValueSum, errorHandling } from '../../../../utlis';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { connect, useDispatch } from 'react-redux'
import { stateOverV } from '../../../../actions';
import NodataFound from '../../../NodataFound';
import LoaderDefault from '../../../LoaderDefault';

const StateWisePerformance = (props) => {
    const [stateOverviews, setStateOverviews] = useState([]);
    const [filterDate, setFilterDate] = useState([new Date(moment().subtract(31, 'days')), new Date()]);
    const [loadertable, setLoadertable] = useState(false);
    const [pradioVal, setPradioVal] = useState("");
    const performanceData = [];
    const dispatch = useDispatch();
    const onDateChange = (e) => {
        setFilterDate(e.text);
    };
    const performanceRadio = (e) => {
        setPradioVal(e.target.value);
    }
    let dateRangeRef = dateRange => dateRangeRef = dateRange;


    // let pending_order_status = [5, 6, 7, 12, 14, 15, 16, 17, 18, 20].filter(item => item !== 11 && ( item === 18));

    // console.log(pending_order_status, 'pending_order_status');

    const shipmentStatusData = () => {
        // setFilterDatePerf();
        setLoadertable(true);
        setFilterDate([new Date(moment().subtract(31, 'days')), new Date()]);
        let mypost = { "merchant_id": props.user[0].id, "start_date": moment().subtract(31, 'days').format('YYYY-MM-DD'), "end_date": moment().format('YYYY-MM-DD') }
        let myget = {}
        dispatch(stateOverV(mypost, myget)).then((response) => {
            setLoadertable(false);
            if (response.error === false) {
                setStateOverviews(response.data);
            }
        }).catch(function (response) {
            setLoadertable(false);
            errorHandling(response, "reload");
        });
    }
    const resetFilter = () => {
        shipmentStatusData();
    }
    const getCityDataFromState=(stateName)=>{
        props.callBackChangeCityData(stateName);

    };

        
    useEffect(() => {
        if (checkData(props.state) && checkData(props.state.viewStateOverview) && checkData(props.state.viewStateOverview.data)) {
            setStateOverviews(props.state.viewStateOverview.data);
        } else {
            shipmentStatusData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    // [5,11]
    function findPendingCount(count, pending_order_status) {
        // console.log(pending_order_status,'hii')
        const pendingDatavalue = pending_order_status.filter((item) => item === count)[0];
        return pendingDatavalue
    }
    function findRtoCount(count) {
        const pendingDatavalue = rto_order_status.filter((item) => item === count)[0];
        return pendingDatavalue
    }
    function findDeliveredCount(count) {
        const pendingDatavalue = delivered_order_status.filter((item) => item === count)[0];
        return pendingDatavalue
    }
    function findFailedCount(count) {
        const pendingDatavalue = failed_order_status.filter((item) => item === count)[0];
        return pendingDatavalue
    }
    let totalDelivered = 0
    let totalFailed = 0
    let totalPending = 0
    let totalRto = 0
    let totalBooked = 0

    let performanceStatus = checkData(stateOverviews) && Object.values(stateOverviews.reduce((osi, item) => {
        let stateId = item.StateName
        if (!osi[stateId])
            osi[stateId] = { state_id: stateId, StateName: item.StateName, "delivered": [], "failed": [], "pending": [], "rto": [], "rto_pending": [] };
        let delivered = checkUndeNullBlank(findDeliveredCount(item.order_status_id)) ? item.total : 0;
        let failed = checkUndeNullBlank(findFailedCount(item.order_status_id)) ? item.total : 0;
        // let pending = checkUndeNullBlank(findPendingCount(item.order_status_id, pending_order_status)) ? item.total : 0;
        let pending = 0;
        if (parseInt(pradioVal) === 1) {
            pending = checkUndeNullBlank(findPendingCount(item.order_status_id, pending_order_status)) ? item.total : 0;
        } else {
            if (checkUndeNullBlank(item.order_status_id) && parseInt(item.order_status_id) === 4 && item.total > 0) {
                osi[stateId].pickup_pending = item.total
            }
            pending = checkUndeNullBlank(findPendingCount(item.order_status_id, pending_order_status)) ? item.total : 0;
        }
        let rto = checkUndeNullBlank(findRtoCount(item.order_status_id)) ? item.total : 0;
        let rtoPending = checkUndeNullBlank(item.order_status_id) && parseInt(item.order_status_id) === 12 ? item.total : 0;
        osi[stateId].delivered.push(delivered);
        osi[stateId].failed.push(failed);
        osi[stateId].pending.push(pending);
        osi[stateId].rto.push(rto);
        osi[stateId].rto_pending.push(rtoPending);
        totalDelivered += delivered;
        totalFailed += failed;
        totalPending += pending;
        totalRto += rto;
        totalBooked = totalDelivered + totalPending + totalRto + totalFailed;
        
        return osi;
    }, {}));

    
    let totalDeliveredPercent = 0;
    if (parseInt(pradioVal) === 1) {
        totalDeliveredPercent = totalDelivered > 0 ? (totalDelivered / (totalBooked - totalPending)) * 100 : 0
    } else {
        totalDeliveredPercent = totalDelivered > 0 ? (totalDelivered / totalBooked) * 100 : 0
    }
    // let totalDeliveredPercent = totalDelivered > 0 ? (totalDelivered / totalBooked) * 100 : 0
    let totalFailedPercent = totalFailed > 0 ? (totalFailed / totalBooked) * 100 : 0
    let totalPendingPercent = totalPending > 0 ? (totalPending / totalBooked) * 100 : 0
    let totalRtoPercent = totalRto > 0 ? (totalRto / totalBooked) * 100 : 0

    let nameLabel = []
    let pendingData = []
    let deliveredData = []
    let rtoData = []
    let rtoPendingData = []
    let todayDeliveredbg = [];
    let todayPendingbg = [];
    let todayRtobg = [];
    let todayRtoPendingbg = [];

 
    checkData(performanceStatus) && performanceStatus.map((item, index) => {
        let n = checkUndeNullBlank(item.StateName) ? item.StateName : item.StateName
        let p = checkUndeNullBlank(item.pending) ? arrayValueSum(item.pending) : 0
        let d = checkUndeNullBlank(item.delivered) ? arrayValueSum(item.delivered) : 0
        let r = checkUndeNullBlank(item.rto) ? arrayValueSum(item.rto) : 0
        let rp = checkUndeNullBlank(item.rto_pending) ? arrayValueSum(item.rto_pending) : 0
        

        nameLabel.push(n)
        pendingData.push(p)
        deliveredData.push(d)
        rtoData.push(r)
        rtoPendingData.push(rp)
        todayDeliveredbg.push('rgb(30 157 0)');
        todayPendingbg.push('rgb(27 90 142)');
        todayRtobg.push('rgb(237,110,81)');
        todayRtoPendingbg.push('rgb(241 215 18)');

        performanceData.push({ "delivered": d, "pending": p, "rto_delivered": r });
        return true
    });
    //console.log('performanceStatus 12', performanceStatus)
    const dataBar = {
        labels: nameLabel,
        datasets: [
            {
                label: 'Shipments Delivered',
                data: deliveredData,
                backgroundColor: todayDeliveredbg,
            },
            {
                label: 'Shipments Pending',
                data: pendingData,
                backgroundColor: todayPendingbg,
            },
            {
                label: 'Shipments RTO Pending',
                data: rtoPendingData,
                backgroundColor: todayRtoPendingbg,
            },
            {
                label: 'Shipments RTO Delivered',
                data: rtoData,
                backgroundColor: todayRtobg,
            },

        ],
    };
  
    // useEffect(() => {
    //     if (Array.isArray(stateOverviews) && stateOverviews.length > 0) {
    //         const sortedData = [...stateOverviews].sort((a, b) => b.total - a.total);
    //         setStateOverviews(sortedData);
    //     }
    // },[stateOverviews]);

    // useEffect(()=>{
    //     if(stateOverviews){   
    // const sortedData = stateOverviews?.sort((a, b) => b.total - a.total)
    // setStateOverviews(sortedData)
    // }
    // },[stateOverviews])

    // console.log(sortedData);

    const { register: filter, handleSubmit: filterPerFormance } = useForm();
    // console.log(filter);
    const onFilterPerformance = (data) => {
        setStateOverviews({});
        let byData = {}
        let isAttempted = checkUndeNullBlank(pradioVal) ? pradioVal : 1
        byData = { "is_attempted": isAttempted, "delivery_type_id": checkUndeNullBlank(data.delivery_type_id) ? data.delivery_type_id : '', "start_date": checkData(filterDate) && filterDate.length > 0 ? moment(filterDate[0]).format('YYYY-MM-DD') : '', "end_date": checkData(filterDate) && filterDate.length > 0 ? moment(filterDate[1]).format('YYYY-MM-DD') : '' }
        if (props.user[0].user_type === "Super") {
            byData.admin_id = props.user[0].id
        } else {
            byData.merchant_id = props.user[0].id
        }
        // console.log(byData,'bydata')
        axios({
            method: "post",
            url: `${process.env.REACT_APP_DASHBOARD_MERCHANT_STATE_PERFORMANCE}`,
            data: byData,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            if (res.data.error === false) {
                // console.log(stateOverviews)
                setStateOverviews(res.data.data);
            }
        }).catch((err) => {
            errorHandling(err, "");
        });
    };
  

    return (
        <div className="StateOverview">
            <Card className="border-0 shadow">
                <CardHeader className="page-header">
                    <Row>
                        <Col xl={12}>
                            <CardTitle className="page-title mb-xl-0">State Wise Overview</CardTitle>
                        </Col>
                        <Col xl={12}>
                            <Form onSubmit={filterPerFormance(onFilterPerformance)} className="cardFilter">
                                <Row className="align-items-center justify-content-sm-end mt-2">
                                    <Col md={3} lg={3} className="pl-0">
                                        <div className="d-flex flex-wrap">
                                            <FormGroup className="mb-md-0">
                                                <span className='d-flex'>
                                                    <input {...filter("is_attempted")} type="radio" value='1' id="attempt" onChange={(e) => performanceRadio(e)} defaultChecked />
                                                    <Label className="mx-2 my-2" for="attempt">Attempted</Label>
                                                </span>
                                            </FormGroup>
                                            <FormGroup className="mb-md-0">
                                                <span className="d-flex">
                                                    <input {...filter("is_attempted")} type="radio" value='0' id="allover" onChange={(e) => performanceRadio(e)} />
                                                    <Label className="mx-2 my-2" for="allover">Overall</Label>
                                                </span>
                                            </FormGroup>
                                        </div>
                                    </Col>
                                    <Col xs={12} lg={4}>
                                        <Row>
                                            <Col>
                                                <FormGroup className="mb-sm-0">
                                                    <span className="d-flex">
                                                        <input {...filter("delivery_type_id")} value='0' type="radio" id="all" defaultChecked />
                                                        <Label for="all" className="mx-2 my-2">All</Label>
                                                    </span>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup className="mb-sm-0">
                                                    <span className="d-flex">
                                                        <input {...filter("delivery_type_id")} type="radio" value='1' id="cod" />
                                                        <Label className="mx-2 my-2" for="cod">COD</Label>
                                                    </span>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup className="mb-sm-0">
                                                    <span className="d-flex">
                                                        <input {...filter("delivery_type_id")} type="radio" value='3' id="prepaid" />
                                                        <Label className="mx-2 my-2" for="prepaid">Prepaid</Label>
                                                    </span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xs={12} lg={5}>
                                        <Row>
                                            <Col md={6} lg={6}>
                                                <FormGroup className="mb-0">
                                                    <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} placement="bottomEnd" placeholder="Choose Upload Date" format="dd-MM-yyyy" showOneCalendar={props.oneCalendar} ref={dateRangeRef} change={onDateChange} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <FormGroup className="mb-0 mt-2 mt-md-0">
                                                    <Button className="ctm-btn btn-sm" color="primary">Search</Button>
                                                    <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" outline onClick={resetFilter}>Reset</Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </CardHeader>
                {/* <CardBody>
                    <Row>
                        <Col xs={12} md={12}>
                            <div style={{ width: "100%", minHeight: "400px" }}>
                                <Bar data={dataBar} plugins={[ChartDataLabels]} options={options} />
                            </div>
                        </Col>
                        {checkData(performanceData) &&
                            <Col md={1} className="d-none">
                                <Table className="custom-table mb-0 text-left performaceBarTable" style={{ marginTop: "33px" }}>
                                    <tbody>
                                        {performanceData.map((item, index) => (
                                            <tr key={index}>
                                                <td className="text-nowrap">
                                                    <span className="font-weight-bold">{item.delivered + item.pending + item.rto_delivered}</span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>}
                    </Row>
                </CardBody> */}
                <CardBody className="p-0">
                    <div className="table-custom">
                        <Table responsive className="text-center custom-table mb-0">
                            <thead>
                                <tr>
                                    <th>State Name</th>
                                    <th>Shipments Booked</th>
                                    <th>Delivered</th>
                                    <th>Delivery Failed</th>
                                    <th>Pending</th>
                                    <th>Return To Origin</th>
                                </tr>
                                <tr>
                                    <th className="text-nowrap">Total</th>
                                    <th className="text-nowrap">{totalBooked}</th>
                                    <th className="text-nowrap"><span>{totalDelivered}<span className="text-success ml-2">({totalDeliveredPercent.toFixed(2)}%)</span></span></th>
                                    <th className="text-nowrap"><span>{totalFailed} <span className="text-yellow ml-2">({totalFailedPercent.toFixed(2)}%)</span></span></th>
                                    <th className="text-nowrap"><span>{totalPending}<span className="text-warning ml-2">({totalPendingPercent.toFixed(2)}%)</span></span></th>
                                    <th className="text-nowrap"><span>{totalRto}<span className="text-danger ml-2">({totalRtoPercent.toFixed(2)}%)</span></span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {checkData(performanceStatus) ? performanceStatus.sort((a, b) => {
            let subtotalBookedA = arrayValueSum(a.delivered) + arrayValueSum(a.failed) + arrayValueSum(a.pending) + arrayValueSum(a.rto);
            let subtotalBookedB = arrayValueSum(b.delivered) + arrayValueSum(b.failed) + arrayValueSum(b.pending) + arrayValueSum(b.rto);
            return subtotalBookedB - subtotalBookedA;
        }).map((item, index) => {
                                    let subtotaldelivered = arrayValueSum(item.delivered)
                                    let subtotalfailed = arrayValueSum(item.failed)
                                    let subtotalpending = arrayValueSum(item.pending)
                                    let subtotalrto = arrayValueSum(item.rto)
                                    let subtotalBooked = 0
                                    if (parseInt(pradioVal) !== 1) {
                                        let ship_pending = checkUndeNullBlank(item.pickup_pending) ? item.pickup_pending : 0
                                        subtotalBooked = subtotaldelivered + subtotalfailed + subtotalpending + subtotalrto + ship_pending;
                                    } else {
                                        subtotalBooked = subtotaldelivered + subtotalfailed  + subtotalrto+subtotalpending;
                                    }
                                    //console.log("pradioVal",pradioVal)
                                    let dPercent = subtotaldelivered > 0 ? (subtotaldelivered / subtotalBooked) * 100 : 0;
                                    let fPercent = subtotalfailed > 0 ? (subtotalfailed / subtotalBooked) * 100 : 0;
                                    let pPercent = subtotalpending > 0 ? (subtotalpending / subtotalBooked) * 100 : 0;
                                    let rPercent = subtotalrto > 0 ? (subtotalrto / subtotalBooked) * 100 : 0;


                                    return (
                                        subtotalBooked > 0 &&
                                        <tr>
                                            <td><Link to="#" className="download-icon mx-1" onClick={() => getCityDataFromState(item.StateName)}> {item.StateName} </Link> </td>
                                            <td>{subtotalBooked}</td>
                                            <td>
                                                <span className="d-flex flex-column">
                                                    <span>{subtotaldelivered}</span>
                                                    <span className="text-success"><small className="font-weight-500">{dPercent.toFixed(2)}%</small></span>
                                                </span>
                                            </td>
                                            <td>
                                                <span className="d-flex flex-column">
                                                    <span>{subtotalfailed}</span>
                                                    <span className="text-yellow"><small className="font-weight-500">{fPercent.toFixed(2)}%</small></span>
                                                </span>
                                            </td>
                                            <td>
                                                <span className="d-flex flex-column">
                                                    <span>{subtotalpending}</span>
                                                    <span className="text-warning"><small className="font-weight-500">{pPercent.toFixed(2)}%</small></span>
                                                </span>
                                            </td>
                                            <td>
                                                <span className="d-flex flex-column">
                                                    <span>{subtotalrto}</span>
                                                    <span className="text-danger"><small className="font-weight-500">{rPercent.toFixed(2)}%</small></span>
                                                </span>
                                            </td>
                                        </tr>

                                    )
                                }) :
                                    loadertable ? <tr>
                                        <td colSpan="5"><LoaderDefault /></td>
                                    </tr>
                                        : <tr><td colSpan="5" className="text-center"><NodataFound /></td></tr>
                                }
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
function mapDispatchToProps(state) {
    return {
        state: state.DataReducer
    }
}
export default connect(mapDispatchToProps)(StateWisePerformance);