import React, { useState, useRef } from 'react';
import { Row, Col, Button, InputGroup, InputGroupAddon, InputGroupText, FormGroup, Form, Modal, ModalHeader, ModalBody, Label } from 'reactstrap';
import { Eye, EyeOff } from 'react-feather';
import { errorHandling } from '../../utlis';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Swal from 'sweetalert2'
import { checkData } from '../../ObjectExist';
import { baseString } from '../../config';

const AddMerchant = (props) => {
    const [logo, setLogo] = useState("");
    const [passwordType, setPasswordType] = useState(true);
    const [passwordType2, setPasswordType2] = useState(true);
    const handleFileInput = (e) => {
        setLogo(e.target.files[0])
    }
    const { register: addMerchant, handleSubmit: MerchantSubmit, watch: watchAddpswd, formState: { errors } } = useForm();

    const password = useRef({});
    password.current = watchAddpswd("password", "");
    const onAddMerchantSubmit = (data) => {
        props.setLoader(true);
        let formData = new FormData();
        formData.append("admin_id", checkData(props.user[0]) ? props.user[0].id : '');
        formData.append("company_name", data.company_name);
        formData.append("brand_name", data.brand_name);
        formData.append("email", data.email);
        formData.append("password", data.password);
        formData.append("billing", data.billing);
        formData.append("freight_type", data.freight_type);
        formData.append("status", data.status);
        formData.append("mobile", data.mobile);
        formData.append("wallet_balance", data.wallet_balance);
        formData.append("max_liability_per_shipment", data.max_liability_per_shipment);
        formData.append("max_cod_value_per_order", data.max_cod_value_per_order);
        formData.append("remittance_tat_period", data.remittance_tat_period);
        formData.append("refer_by", data.refer_by);
        formData.append("additional_mrp_percentage", data.additional_mrp_percentage);
        
        formData.append("security_amount", data.security_amount);
        formData.append("logo", logo);

        axios({
            method: "post",
            url: process.env.REACT_APP_ADD_MERCHANT,
            data: formData,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            props.setLoader(false);
            props.merchantListing();
            if (res.data.error === false) {
                props.setAddModal(false);
                Swal.fire({ title: 'Success', text: res.data.msg, icon: 'success', })
            }
        })
            .catch(function (err) {
                props.setLoader(false);
                errorHandling(err, "");
            });
    };
    return (
        <Modal isOpen={props.addmodal} toggle={props.addmodal} scrollable={true} size="lg">
            <ModalHeader toggle={props.toggleAdd}>Add Merchants</ModalHeader>
            <ModalBody>
                <Form onSubmit={MerchantSubmit(onAddMerchantSubmit)}>
                    <Row className="mx-0 justify-content-start addModal">
                        <Col sm={4}>
                            <FormGroup>
                                <Label for="companyName_add">Enter Company Name<span className="text-danger">*</span></Label>
                                <input {...addMerchant("company_name", { required: 'Please Enter Company Name' })} className="form-control-sm form-control" placeholder="Enter Company Name*" id="companyName_add" />
                                {errors.company_name && <span className="text-danger d-block error">{errors.company_name.message}</span>}
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Label for="email_add">Enter Email<span className="text-danger">*</span></Label>
                                <input {...addMerchant("email", {
                                    required: 'Please Enter Email',
                                    pattern: { value: /\S+@\S+\.\S+/, message: 'Please Enter Valid Email' }
                                })} type="text" className="form-control-sm form-control" placeholder="Enter Email*" id="email_add" />
                                {errors.email && <span className="text-danger d-block error">{errors.email.message}</span>}
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Label for="brandName_add">Enter Brand Name<span className="text-danger">*</span></Label>
                                <input {...addMerchant("brand_name", { required: 'Please Brand Name' })} className="form-control-sm form-control" placeholder="Enter Brand Name*" id="brandName_add" />
                                {errors.brand_name && <span className="text-danger d-block error">{errors.brand_name.message}</span>}
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Label for="contact_add">Enter Mobile Number<span className="text-danger">*</span></Label>
                                <input {...addMerchant("mobile", {
                                    required: 'Please Enter Phone Number',
                                    minLength: { value: 10, message: "Please Enter 10 Digit Mobile Number" },
                                    maxLength: { value: 10, message: "Please Enter 10 Digit Mobile Number" },
                                    pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Mobile Number' }
                                })} type="text" className="form-control-sm form-control" placeholder="Enter Phone Number*" id="contact_add" />
                                {errors.mobile && <span className="text-danger d-block error">{errors.mobile.message}</span>}
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Label for="walletBalance_add">Enter Wallet Balance<span className="text-danger">*</span></Label>
                                <input {...addMerchant("wallet_balance", {
                                    required: 'Please Wallet Balance',
                                    pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Wallet Balance' }
                                })} type="text" readOnly value="0" className="form-control-sm form-control" placeholder="Enter Wallet Balance*" id="walletBalance_add" />
                                {errors.wallet_balance && <span className="text-danger d-block error">{errors.wallet_balance.message}</span>}
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Label for="maxLiabShip_add">Enter Max Liability Shipment<span className="text-danger">*</span></Label>
                                <input {...addMerchant("max_liability_per_shipment", {
                                    required: 'Please Enter Max Liability Shipment',
                                    pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Max Liability Shipment' }
                                })} type="text" className="form-control-sm form-control" placeholder="Enter Max Liability Shipment*" id="maxLiabShip_add" />
                                {errors.max_liability_per_shipment && <span className="text-danger d-block error">{errors.max_liability_per_shipment.message}</span>}
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Label for="maxLiabShip_add">Enter Max COD Value <small>(per order)</small></Label>
                                <input {...addMerchant("max_cod_value_per_order", { pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Max COD Value' } })} type="text" className="form-control-sm form-control" placeholder="Enter Max COD Value*" id="maxCodVal_add" />
                                {errors.max_cod_value_per_order && <span className="text-danger d-block error">{errors.max_cod_value_per_order.message}</span>}
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Label for="security_add">Enter Security Amount </Label>
                                <input {...addMerchant("security_amount", { pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Security Amount' }, min: { value: 500, message: "Please Enter Minimum 500" } })} type="text" className="form-control-sm form-control" placeholder="Enter Security Amount" id="security_add" />
                                {errors.security_amount && <span className="text-danger d-block error">{errors.security_amount.message}</span>}
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Label for="password_add">Enter Password<span className="text-danger">*</span></Label>
                                <InputGroup>
                                    <input {...addMerchant("password", {
                                        required: 'Please Enter Password',
                                        minLength: { value: 6, message: "at least 6 Characters" }
                                    })} type={passwordType ? "password" : "text"} className="form-control-sm form-control" placeholder="Enter Password*" id="password_add" />
                                    <InputGroupAddon addonType="append" onClick={() => setPasswordType(!passwordType)}>
                                        <InputGroupText className="py-0"> {passwordType ? <Eye /> : <EyeOff />}</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                {errors.password && <span className="text-danger d-block error">{errors.password.message}</span>}
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Label for="confirmpass_add">Enter Confirm Password<span className="text-danger">*</span></Label>
                                <InputGroup>
                                    <input {...addMerchant("confirmPassword", {
                                        required: 'Please Enter Confirm', validate: value => value === password.current || "The passwords do not match",
                                        minLength: { value: 6, message: "at least 6 Characters" }
                                    })} type={passwordType2 ? "password" : "text"} className="form-control-sm form-control" placeholder="Enter Confirm Password*" id="confirmpass_add" />
                                    <InputGroupAddon addonType="append" onClick={() => setPasswordType2(!passwordType2)}>
                                        <InputGroupText className="py-0"> {passwordType2 ? <Eye /> : <EyeOff />}</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                {errors.confirmPassword && <span className="text-danger d-block error">{errors.confirmPassword.message}</span>}
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Label for="uploadLogo_add">Upload Brand logo</Label>
                                <input {...addMerchant("brandlogo", { required: false })} type="file" onChange={handleFileInput} accept=".jpg, .jpeg, .png" className="form-control-sm form-control" id="uploadLogo_add" />
                                {errors.brandlogo && <span className="text-danger d-block error">{errors.brandlogo.message}</span>}
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Label for="remittance_tat_period_add">Remittance TAT Period<span className="text-danger">*</span></Label>
                                <input {...addMerchant("remittance_tat_period", { required: 'Please Enter Remittance TAT Period' })} className="form-control-sm form-control" placeholder="Enter Remittance TAT Period*" defaultValue="5" id="remittance_tat_period_add" />
                                {errors.remittance_tat_period && <span className="text-danger d-block error">{errors.remittance_tat_period.message}</span>}
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Label for="refer_by_add">Refer By</Label>
                                <input {...addMerchant("refer_by", { required: false })} className="form-control-sm form-control" placeholder="Enter Refer By" id="refer_by_add" />
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <Label for="additional_mrp_percentage_add">MRP Increase %</Label>
                                <input {...addMerchant("additional_mrp_percentage", { pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please a Valid Percentage' },required: false })} className="form-control-sm form-control" placeholder="Enter MRP Increase %" id="additional_mrp_percentage_add" />
                                {errors.additional_mrp_percentage && <span className="text-danger d-block error">{errors.additional_mrp_percentage.message}</span>}
    
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <FormGroup check className="pl-0">
                                    <Label>Billing<span className="text-danger">*</span></Label>
                                    <div>
                                        <span className="form-check2 mr-2">
                                            <input className="mx-2" {...addMerchant("billing", { required: true })} type="radio" value="2" id="preBill_add" defaultChecked />
                                            <Label for="preBill_add">Prepaid Billing</Label>
                                        </span>
                                        <span className="form-check2">
                                            <input className="mx-2" {...addMerchant("billing", { required: true })} type="radio" value="1" id="postBill_add" />
                                            <Label for="postBill_add">Postpaid Billing</Label>
                                        </span>
                                    </div>
                                </FormGroup>
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <FormGroup check className="pl-0">
                                    <Label>Freight Type<span className="text-danger">*</span></Label>
                                    <div>
                                        <span className="form-check2 mr-2">
                                            <input className="mx-2"  {...addMerchant("freight_type", { required: true })} type="radio" value="1" id="fixCharge_add" defaultChecked />
                                            <Label for="fixCharge_add">Fixed Charge</Label>
                                        </span>
                                        <span className="form-check2">
                                            <input className="mx-2" {...addMerchant("freight_type", { required: true })} type="radio" value="2" id="varCharge_add" />
                                            <Label for="varCharge_add">Variables Charge</Label>
                                        </span>
                                    </div>
                                </FormGroup>
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup>
                                <FormGroup check className="pl-0">
                                    <Label>Status<span className="text-danger">*</span></Label>
                                    <div>
                                        <span className="form-check2 mr-2">
                                            <input className="mx-2" {...addMerchant("status", { required: true })} type="radio" value="1" id="active_add" defaultChecked />
                                            <Label for="active_add">Active</Label>
                                        </span>
                                        <span className="form-check2">
                                            <input className="mx-2" {...addMerchant("status", { required: true })} type="radio" value="0" id="inactive_add" />
                                            <Label for="inactive_add">InActive</Label>
                                        </span>
                                    </div>
                                </FormGroup>
                            </FormGroup>
                        </Col>
                        <Col sm={12} className="text-right">
                            <Button className="ctm-btn btn-sm" color="primary">Save</Button>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default AddMerchant