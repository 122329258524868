import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWallet, faTimes, faBars, faDownload, faHistory, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Navbar, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, InputGroup, InputGroupAddon, InputGroupText, DropdownMenu, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Form, UncontrolledTooltip, Spinner, CardTitle, ListGroup, ListGroupItem } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { Search, Settings, Power } from 'react-feather';
import TopAvatar from '../assets/img/avatar.jpg';
import { useForm } from 'react-hook-form';
import { makePaymentpayu } from './payu/Payu'
import { checkData, checkUndeNullBlank } from '../ObjectExist';
import { encryptStorageLogin } from '../EncStorage';
import { connect, useDispatch } from 'react-redux';
import { resetReducer, mergeAccountList, TotalWalletBalance } from '../actions';
import { errorHandling, urlencode } from '../utlis'
import axios from 'axios';
import Swal from 'sweetalert2';
import { baseString } from '../config';

// import SockJsClient from 'react-stomp';

const AdminHeader = (props) => {
  
  let searchKeyword = (new URLSearchParams(window.location.search)).get("search_keyword");
  const history = useHistory();
  const [openSearch, setOpenSearch] = useState(true);
  const [rechargeMdl, setRechargeMdl] = useState(false);
  const [internetFee, setInternetFee] = useState(0);
  const [totalFee, setTotalFee] = useState(0);
  const [linkedMerchant, setLinkedMerchant] = useState({});
  const [loader, setLoader] = useState(false);
  const [isOpenHover, updateIsOpenHover] = useState(false);

    // const data = useEventSource(`${process.env.REACT_APP_BASE_URL}/stream-random-numbers`);
    const userdec = encryptStorageLogin.getItemFromPattern('userenc');
  const user = userdec !== undefined && userdec.userenc !== undefined && checkData(userdec) && checkUndeNullBlank(userdec.userenc.data) ? userdec.userenc.data : userdec !== undefined && userdec.userenc !== undefined && checkData(userdec) && userdec.userenc;


  // const [userBalance, setUserBalance] = useState('');
  const [userBalanceOther, setUserBalanceOther] = useState('');

  const toggleRecharge = () => setRechargeMdl(!rechargeMdl);
  // const inputfocus = useRef(null);
  const dispatch = useDispatch();

  const logOutUser = () => {
    // localStorage.removeItem("user");
    dispatch(resetReducer());
    localStorage.removeItem('userIdAdminLogin');
    encryptStorageLogin.clear();
    history.push("/login");
  }
  const handleChange = (e) => {
    // let handlingFee = (2*e.target.value)/100
    setInternetFee(0)
    if (e.target.value > 0) {
      setTotalFee(parseInt(e.target.value) + internetFee)
    } else {
      setTotalFee(0)
    }
  }
  const LinkedMerchantL = () => {
    // setLoader(true)
    let mypost = {}
    let myget = {}
    mypost.admin_id = process.env.REACT_APP_ADMIN_ID
    mypost.login_merchant_id = props.user[0].id

    dispatch(mergeAccountList(mypost, myget)).then((response) => {
      setLoader(false)
      if (response.error === false) {
        setLinkedMerchant(response.data);
      }
    }).catch(function (response) {
      setLoader(false)
      errorHandling(response, "reload");
    });

    // let data = {}
    // data.admin_id = process.env.REACT_APP_ADMIN_ID
    // data.login_merchant_id = props.user[0].id
    // axios
    //   .post(process.env.REACT_APP_LINK_MERCHANT_MERGE_ACCOUNT_LIST, data)
    //   .then((res) => {
    //     if(res.data.error === false){
    //       setLinkedMerchant(res.data.data);
    //     }
    //   })
    //   .catch((err) => {
    //     Swal.fire({icon:'error', text:"Something went wrong", title:'Error'})
    //   });
  };
  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = (data) => {

      if(props.user[0].id == 372){
          
          fetch(process.env.REACT_APP_PHONEPE_HASH, {
            method: 'POST',
            headers: {
              "Content-Type": 'application/json',
              "Accept": 'application/json',
              "token": window.btoa(baseString)
            },
            body: JSON.stringify({merchant_amt: parseFloat(totalFee).toFixed(2), 
              merchant_mobile: props.user[0].mobile,
              merchant_email: props.user[0].email, merchant_id: props.user[0].id, merchant_name: props.user[0].name})
            }).then((resp) => {
            resp.json().then((result) => {
              if (result.error === false) {
                window.location.href=result.data;
              }
            });
          });

        }else{


          let callbackURLPAYU = process.env.REACT_APP_PAYU_CALLBACK_SEND_URL
          fetch(process.env.REACT_APP_PAYU_HASH, {
            method: 'POST',
            headers: {
              "Content-Type": 'application/json',
              "Accept": 'application/json',
              "token": window.btoa(baseString)
            },
            body: JSON.stringify({ merchant_amt: parseFloat(totalFee).toFixed(2), merchant_email: props.user[0].email, merchant_id: props.user[0].id, merchant_name: props.user[0].name, })
          }).then((resp) => {
            resp.json().then((result) => {
              if (result.error === false) {
                let param = {
                  key: result.key,
                  hash: result.hash,
                  txnid: result.txnid,
                  amount: parseFloat(totalFee).toFixed(2),
                  productinfo: result.productinfo,
                  country: 'India',
                  firstname: props.user[0].name,
                  udf1: props.user[0].id,
                  phone: props.user[0].mobile,
                  surl: callbackURLPAYU,
                  furl: callbackURLPAYU,
                  email: props.user[0].email,
                  service_provider: 'payu_paisa'
                }
                makePaymentpayu(param);
              }
            });
          });

        }


  };


  const { register: register2, handleSubmit: handleSubmit2, getValues, setValue } = useForm();
  useEffect(() => {
    if (checkData(props.user) && props.user[0].user_type === "Merchant") {
      LinkedMerchantL();
      props.walletBalanceUpdate();
      // loginRefresh();
      setRechargeMdl(checkUndeNullBlank(props.userBalance) && props.userBalance < 0 && true)
    }
    if (checkData(searchKeyword)) {
      [
        { name: 'search_keyword', value: searchKeyword },
      ].forEach(({ name, value }) => setValue(name, value));
    } else {
      [
        { name: 'search_keyword', value: '' },
      ].forEach(({ name, value }) => setValue(name, value));
    }

    if (window.innerWidth < 575.98) {
      setOpenSearch(false);
    } else {
      setOpenSearch(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword, props.userBalance]);

  const onSearchNo = (data) => {
    const searchTrack = getValues("search_keyword");
    searchTrack.length > 0 && history.push(`/shipments?search_keyword=${urlencode(searchTrack)}`);
  }

  const getFirstLetter = (name) => {
    if (name !== undefined) {
      return name.substring(0, 1);
    }
  }
  const loginRefresh = () => {
    let data = { "merchant_id": props.user[0].id }
    data.admin_id = process.env.REACT_APP_ADMIN_ID
    // axios.post(process.env.REACT_APP_LINK_MERCHANT_ACCOUNT_LINKED_LOGIN, data)
    axios({
      method: "post",
      url: `${process.env.REACT_APP_LINK_MERCHANT_ACCOUNT_LINKED_LOGIN}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data.error === false) {
        dispatch(resetReducer());
        encryptStorageLogin.setItem('userenc', JSON.stringify(res.data.data));
      }
    })
      .catch((err) => {
        setLoader(false);
        Swal.fire({ icon: 'error', text: "Something went wrong", title: 'Error' })
      });
  };
  const loginAccount = (id, comp_name, email) => {
    let data = { "merchant_id": id }
    data.admin_id = process.env.REACT_APP_ADMIN_ID
    // data.login_merchant_id = merchantId
    // setLoader(true);
    switchAct(comp_name, email);
    // axios.post(process.env.REACT_APP_LINK_MERCHANT_ACCOUNT_LINKED_LOGIN, data)
    axios({
      method: "post",
      url: `${process.env.REACT_APP_LINK_MERCHANT_ACCOUNT_LINKED_LOGIN}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data.error === false) {
        dispatch(resetReducer());
        encryptStorageLogin.setItem('userenc', JSON.stringify(res.data.data));
        if (res.data.data[0].document_status === 0) {
        }
        setTimeout(function () {
          window.location.reload('/')
        }, 5000);
      }
    })
      .catch((err) => {
        setLoader(false);
        Swal.fire({ icon: 'error', text: "Something went wrong", title: 'Error' })
      });
  };

  let prevActName = props.user !== undefined && props.user !== null && Object.entries(props.user).length > 0 ? props.user[0].name : '';
  let prevActEmail = props.user !== undefined && props.user !== null && Object.entries(props.user).length > 0 ? props.user[0].email : '';
  const switchAct = (comp_name, email) => {
    Swal.fire({
      title: 'Switching Your Account',
      html:
        '<div class="row w-100 mx-0 text-left">' +
        '<div class="col-5 from-act">' +
        '<div class="detail"><span>' + prevActName + '</span></br><span>' + prevActEmail + '</span></div>' +
        '</div>' +
        '<div class="col-2 act-loader text-center">' +
        '<div class="right-aerro mb-2 mx-auto"></div>' +
        '<div class="spinner-border text-secondary" role="status">' +
        '<span class="visually-hidden">Loading...</span>' +
        '</div>' +
        '</div>' +
        '<div class="col-5 to-act">' +
        '<div class="detail"><span>' + comp_name + '</span></br><span>' + email + '</span></div>' +
        '</div>' +
        '</div>',
      showConfirmButton: false,
      confirmButtonText: 'Ok',
      customClass: {
        container: 'switch-act-con'
      },
      allowOutsideClick: false,
    })
  }

  // let mypost = {}
  //   let myget = {}

  // dispatch(TotalWalletBalance(mypost, myget)).then((response) => {
  //   setLoader(false);
  //   if (response.error === false) {
  //     // setUserBalance(response.balance);
  //     setUserBalanceOther(response.other_details);
  //     if (response.balance < 0) {
  //       history.push("/");
  //     }
  //   }
  // }).catch(function (response) {
  //   setLoader(false);
  //   errorHandling(response, "")
  // });

    const logOutUsers = () => {
      alert("Logout");
    // localStorage.removeItem("user");
    dispatch(resetReducer());
    encryptStorageLogin.clear();
    localStorage.removeItem('userIdAdminLogin');
    history.push("/login");

    
  }

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     logOutUsers();
  //   }, 1000);
  //   return () => clearInterval(intervalId);
  // }, []);

  
 


  let userBalance = checkUndeNullBlank(props.userBalance) ? props.userBalance.toFixed(2) : '0.00';
// console.log(userBalance,'USERbalance')
  let totalFreightDue = checkUndeNullBlank(props.userBalanceOther) && checkUndeNullBlank(props.userBalanceOther.total_Freight_due) ? Math.round(props.userBalanceOther.total_Freight_due) : 0;
  let totalRemittanceAST = checkUndeNullBlank(props.userBalanceOther) && checkUndeNullBlank(props.userBalanceOther.total_remittance_due_as_per_tat) ? Math.round(props.userBalanceOther.total_remittance_due_as_per_tat) : 0;
  let uploadedOrderFreight = checkUndeNullBlank(props.userBalanceOther) && checkUndeNullBlank(props.userBalanceOther.uploaded_order_freight) ? Math.round(props.userBalanceOther.uploaded_order_freight) : 0;
  let merchantWalletBlc = checkUndeNullBlank(props.userBalanceOther) && checkUndeNullBlank(props.userBalanceOther.merchant_wallet_balance) ? Math.round(props.userBalanceOther.merchant_wallet_balance) : 0;

  let walletOtherDetail = [
    { text: "Total Due Freight (-)", value: totalFreightDue, link: "#" },
    { text: "Total Unpaid COD (+)", value: totalRemittanceAST, link: "#" },
    { text: "Today's Order Freight (-)", value: uploadedOrderFreight, link: "#" },
    { text: `Wallet Balance (${merchantWalletBlc > 0 ? '+' : '-'})`, value: merchantWalletBlc, link: "#" },
  ]


  return (
    <div>
      {loader && <div className="formLoader"><Spinner /></div>}
      {/* {props.loader && <div className="formLoader"><Spinner /></div>} */}
      {/* <SockJsClient url='http://api.logistify.in/ws' topics={['/topics/all']} onMessage={(msg) => { console.log('test', msg); }} />    */}
      <Navbar color="white" light expand="md" className="header-navbar topNav">
        <Nav className="mr-auto flex-row" navbar>
          <NavItem className="d-block d-lg-none">
            <NavLink href="#" className="text-center py-0 px-2 cursor-pointer" onClick={() => props.sideBarToggle()}><FontAwesomeIcon icon={faBars} /></NavLink>
          </NavItem>
          {props.userBalance >= 0 || props.user[0].user_type === "Super" ?
            <NavItem>
              <NavLink href="#" className="py-0 px-2" onClick={e => setOpenSearch(!openSearch)}><Search /></NavLink>
              <div className={`search-input ${openSearch ? "open" : ""}`}>
                <Form onSubmit={handleSubmit2(onSearchNo)}>
                  <Button className="search-input-icon">
                    <Search />
                  </Button>
                  <div className="autocomplete-container">
                    <input type="text" {...register2('search_keyword')} className="autocomplete-search form-control" placeholder="AWB No./Order No./Mobile No." />
                  </div>
                  <div className="search-input-close" onClick={e => setOpenSearch(!openSearch)}>
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </Form>
              </div>
            </NavItem> : ''
          }
        </Nav>
        <div className="d-flex">
          <Nav className="mr-auto align-items-center position-relative flex-row" navbar>
            {checkData(props.user) && props.user[0].user_type === "Merchant" &&
              <NavItem>
                <NavLink href="#" className="text-center py-0 px-2" onClick={toggleRecharge}>Recharge</NavLink>
              </NavItem>
            }
            <NavItem>
              <Link to="/my-downloads" className="text-center py-0 px-2" title='Downloads'><FontAwesomeIcon icon={faDownload} /></Link>
            </NavItem>
            {checkData(props.user) && props.user[0].user_type === "Merchant" &&
              <>
                <NavItem>
                  <Link to="/passbook" className="py-0 px-2"><FontAwesomeIcon icon={faWallet} /></Link>
                </NavItem>

                <NavItem className="position-absolute top-0 navPrice">
                  <UncontrolledDropdown
                    onMouseOver={() => updateIsOpenHover(true)}
                    onFocus={() => updateIsOpenHover(true)}
                    onMouseLeave={() => updateIsOpenHover(false)}
                    onBlur={() => updateIsOpenHover(false)}
                    isOpen={isOpenHover}
                    className="hoverableDropDownMenu"
                  >
                    <DropdownToggle tag="span">
                      <NavLink href="#" className="text-center p-0">
                        {props.loader ? <Badge className={`badge badge-pill badge-up`} color='primary'>Loading...</Badge> :
                          <Badge className={`badge badge-pill badge-up`} color={`${userBalance > 0 ? 'primary' : 'warning'}`}>₹ {checkUndeNullBlank(props.userBalance) ? props.userBalance.toFixed(2) : '0.00'} <span className='ml-1'><FontAwesomeIcon icon={faHistory} onClick={props.walletBalanceUpdate} /></span></Badge>
                        }
                      </NavLink>
                    </DropdownToggle>
                    {!props.loader &&
                      <DropdownMenu>
                        {checkData(walletOtherDetail) && walletOtherDetail.map((item) => (
                          <div className="dropdown-item"><span>{item.text}</span><span className="font-weight-bold">: {item.value}</span></div>
                        ))}
                      </DropdownMenu>}
                  </UncontrolledDropdown>
                </NavItem>
              </>
            }
          </Nav>
          <Nav className="flex-row">
            <NavItem>
              <NavLink href="#" className="py-0 px-0">
                <UncontrolledDropdown setActiveFromChild>
                  <DropdownToggle caret className="py-0 px-2 d-flex align-items-center btn-light">
                    <div className="user-nav d-sm-flex d-none flex-column align-items-end">
                      <span className="user-name font-weight-500 text-truncate" id="user-name">
                        {props.user !== undefined && props.user !== null && Object.entries(props.user).length > 0 ? props.user[0].name : ''}
                        <UncontrolledTooltip placement="bottom" target="user-name">
                          {props.user !== undefined && props.user !== null && Object.entries(props.user).length > 0 ? props.user[0].name : ''}
                        </UncontrolledTooltip>
                      </span>

                      <span className="user-status">{checkData(props.user) && props.user[0].user_type === "Merchant" && props.user[0].document_status !== 0 ? <span className='mr-2 text-success border border-success p-tiny rounded d-none'>Active</span> : <span className='mr-2 text-danger border border-danger p-tiny rounded d-none'>InActive</span>}{checkData(props.user) ? props.user[0].brand_name : ''}</span></div>
                    <div className="avatar d-flex justify-content-center align-items-center"><span className="rounded-circle avatar-name">{props.user !== undefined && props.user !== null && Object.entries(props.user).length > 0 ? getFirstLetter(props.user[0].name) : ''}</span><img src={TopAvatar} width="40" className="rounded-circle d-none" alt="Avatar" />{checkData(props.user) && props.user[0].user_type === "Merchant" ? <span className={`avatar-status-online ${props.user[0].document_status !== 0 ? "bg-success" : "bg-danger"}`}></span> : ''} </div>
                  </DropdownToggle>
                  <DropdownMenu className="mt-2 start-auto end-0">
                    {checkData(linkedMerchant) && <p className="text-muted text-nowrap font-weight-500 title-card mb-0 px-2">Linked Account</p>}
                    {checkData(linkedMerchant) ? linkedMerchant.map((item, index) => {
                      let email = checkUndeNullBlank(item.email) ? item.email : 'NA';
                      let comapnyName = checkUndeNullBlank(item.company_name) ? item.company_name : 'NA';
                      let companyStatus = checkUndeNullBlank(item.status) ? item.status : '';
                      return (
                        <>
                          {props.user[0].id !== item.id && <>
                            <div className="d-flex align-items-center linkact-con"><div className="avatar d-flex justify-content-center align-items-center position-relative"><span className="rounded-circle avatar-name">{getFirstLetter(comapnyName)}</span><span className={`avatar-status-online ${companyStatus !== 0 ? "bg-success" : "bg-danger"}`}></span></div>
                              <NavLink href="/" style={{ width: "calc(100% - 40px)" }} className="dropdown-item px-2" onClick={() => { if (companyStatus === 1) { loginAccount(item.id, comapnyName, email); } }}><span>{comapnyName}</span><small> ({item.id})</small> <br /><span>({email})</span></NavLink></div>
                            <div className="dropdown-divider m-0"></div></>}
                        </>
                      )
                    }) : ''
                    }
                    <div className="d-flex pt-1">
                      <Link to="/settings/1" className="dropdown-item border-right"><span className="mr-2"><Settings /></span>Setting</Link>
                      <NavLink href="/logout" className="dropdown-item" onClick={logOutUser}><span className="mr-2"><Power /></span>Logout</NavLink>
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <Modal isOpen={rechargeMdl} open={toggleRecharge}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader className="wallet_summary_modal" toggle={checkUndeNullBlank(props.userBalance) && props.userBalance >= 0 && toggleRecharge}>
              <span>Wallet Summary</span>
              {checkUndeNullBlank(props.userBalance) && props.userBalance < 0 &&
                <Link to="/logout" className="ml-25 logout" onClick={logOutUser}>Logout</Link>}
            </ModalHeader>
            <ModalBody>
              {checkUndeNullBlank(props.userBalance) && props.userBalance < 0 &&
                <ListGroup className="mb-3">
                  {checkData(walletOtherDetail) && walletOtherDetail.map((item) => (
                    <ListGroupItem className="py-1">
                      <div className="d-flex justify-content-between">
                        <div xs={11} sm={11}><span>{item.text}</span></div>
                        <div xs={2} sm={2}><span className="font-weight-500">₹ {item.value}</span></div>
                      </div>
                    </ListGroupItem>
                  ))}
                </ListGroup>}
              {checkUndeNullBlank(props.userBalance) && props.userBalance < 0 &&
                <CardTitle tag="h6" className='text-center'><span class="text-danger">Please Recharge Your Wallet<br /><br /> <span> <span class="text-black">Your Available Balance is: </span> {checkUndeNullBlank(props.userBalance) && props.userBalance.toFixed(2)} </span></span></CardTitle>
              }<FormGroup>
                <div className="d-flex justify-content-between">
                  <Label>Wallet Amount<span className="text-danger">*</span></Label>
                  {/* <input {...register("amount", { required: 'Please Enter Amount',
                        pattern: {value:/^[+-]?\d+(\d+)?$/,message:'Please Enter Valid Amount'} })} placeholder="Amount" type="text" style={{width: "100px"}} className="form-control" onChange={handleChange}/> */}
                  <FormGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText className="position-relative p-0">
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <select {...register("amount")} className="form-control-sm form-control" onChange={handleChange}>
                            <option value="">Select Balance</option>
                            {checkData(props.user) && props.user[0].user_type === "Merchant" && props.user[0].id === 372 &&
                              <option value="1">1</option>
                              }
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="2500">2500</option>
                            <option value="5000">5000</option>
                            <option value="7500">7500</option>
                            <option value="10000">10000</option>
                            <option value="25000">25000</option>
                          </select>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </div>
                {errors.amount && <span className="text-danger d-block error text-right">{errors.amount.message}</span>}
              </FormGroup>

              {/* <FormGroup className="d-flex justify-content-between">
                <Label>Internet Handling Fees</Label>
                {internetFee > 0 &&
                  <div>₹ <span>{internetFee}</span></div>
                }
              </FormGroup> */}
              
              {totalFee > 0 &&
                <FormGroup className="d-flex justify-content-between">
                  <Label>Amount Payable</Label>
                  <div>₹ <span>{totalFee}</span></div>
                </FormGroup>
              }
              <div className="dropdown-divider"></div>
              <FormGroup>
                <CardTitle>
                <p className="text-center font-weight-bold mb-0">Or</p>
                <p className="text-center">
                Transfer <span className="font-weight-bold">{totalFee > 0 ? totalFee-internetFee : 'Minimum 500'}</span> to Below Bank Details
                </p>
                </CardTitle>
              </FormGroup>
              <FormGroup className="d-flex justify-content-between">
                <Label>Account Name</Label>
                  <div>Keryx Retail Solutions Private Limited</div>
              </FormGroup>
             
              <FormGroup className="d-flex justify-content-between">
                <Label>Bank Name</Label>
                  <div>Induslnd Bank</div>
              </FormGroup>
              <FormGroup className="d-flex justify-content-between">
                <Label>A/c No.</Label>
                  <div>201001656627</div>
              </FormGroup>
              <FormGroup className="d-flex justify-content-between">
                <Label>Branch</Label>
                  <div>Mohan Co-Operate</div>
              </FormGroup>
              <FormGroup className="d-flex justify-content-between">
                <Label>IFSC Code</Label>
                  <div>INDB0000735</div>
              </FormGroup>
            </ModalBody>
            {totalFee > 0 &&
              <ModalFooter>
                <Button className="ctm-btn" color="primary">Pay - <span>{totalFee}</span></Button>
              </ModalFooter>
            }
          </Form>
        </Modal>
      </Navbar>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    state: state.DataReducer
  }
}
export default connect(mapStateToProps)(AdminHeader);